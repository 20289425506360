import React from 'react'
import './popUp.scss'

import Icon from '@material-ui/core/Icon'


const PopUp = (props) => {
    return (props.trigger) ? (
        <div className='popup'>
            <div className='popup-inner'>
                <Icon className="icone-popup-fechar"  onClick={() => props.setTrigger(false)}>close</Icon>
                {props.children}

            </div>
        </div>
    ): "";

}

export default PopUp