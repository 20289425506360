import React, { useState, useEffect, useRef, useContext, useMemo } from 'react'

import './uplotChart.scss'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'

import uPlot from 'uplot'
import UplotReact from 'uplot-react'
import g1 from "./5.png";
import g2 from "./6.png";
import '/node_modules/uplot/dist/uPlot.min.css'

import { ThemeCtx } from '../../utils/context/themeCtx'
import { endOfWeek, setHours, getWeek, setWeek } from 'date-fns'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { getChartData, getChartDate } from '../../api/chartData.js'
import { procurarEmAndamento, procurarAgendadas } from '../../api/entregasData'
import { setRobo } from '../../api'

import List from '../list/List'

import PopUp from '../popUp/PopUp'
import Button from '../button/Button';
import Cores from '../cores/Cores';

const { spline, bars } = uPlot.paths
const lineInterpolations = { linear: 0, spline: 3 }
const drawStyles = { line: 0, bars: 1 }
const _bars60_100 = bars({ size: [0.6, 100] })
const _bars100Left = bars({ size: [1], align: 1 })
const _bars100Right = bars({ size: [1], align: -1 })
const _spline = spline()
var graficosAtivos =["", "", ""];

function paths(u, seriesIdx, idx0, idx1, extendGap, buildClip) {
  let s = u.series[seriesIdx]
  let style = s.drawStyle
  let interp = s.lineInterpolation

  let renderer =
    style === drawStyles.line
      ? interp === lineInterpolations.spline
        ? _spline
        : null
      : style === drawStyles.bars
      ? _bars60_100
      : style === drawStyles.barsLeft
      ? _bars100Left
      : style === drawStyles.barsRight
      ? _bars100Right
      : () => null

  return renderer(u, seriesIdx, idx0, idx1, extendGap, buildClip)
}

const UplotChart = (prop) => {
  const queryClient = useQueryClient()

  const { darkMode } = useContext(ThemeCtx)
  const dateFromRef = useRef()
  const dateToRef = useRef()
  const [displayDates1, setdisplayDates1] = useState(false)
  const [displayDates2, setdisplayDates2] = useState(false)
  const [displayDates3, setdisplayDates3] = useState(false)

  const [weekG1, setWeeksG1] = useState({
    startDate: setWeek(setHours(new Date(), 7), getWeek(new Date())),
    endDate: endOfWeek(new Date(), getWeek(new Date())),
    currWeek: getWeek(new Date()),
    isToday: true,
  })

  const [weekG2, setWeeksG2] = useState({
    startDate: setWeek(setHours(new Date(), 7), getWeek(new Date())),
    endDate: endOfWeek(new Date(), getWeek(new Date())),
    currWeek: getWeek(new Date()),
    isToday: true,
  })

  const [weekG3, setWeeksG3] = useState({
    startDate: setWeek(setHours(new Date(), 7), getWeek(new Date())),
    endDate: endOfWeek(new Date(), getWeek(new Date())),
    currWeek: getWeek(new Date()),
    isToday: true,
  })

  const [week, setWeeks] = useState({
    startDate: setWeek(setHours(new Date(), 7), getWeek(new Date())),
    endDate: endOfWeek(new Date(), getWeek(new Date())),
    currWeek: getWeek(new Date()),
    isToday: true,
  })

  const [avisoBoot, setAvisoBoot] = useState(false)

  const [series, setseries] = useState([[], [], [], [], []])
  const [seriesArapongas, setseriesArapongas] = useState([[], [], [], [], []])
  const [seriesMaringa, setseriesMaringa] = useState([[], [], [], [], []])

  const [tabelaAgendadas, setTabelaAgendadas] = useState([]);
  const [tabelaEmAndamento, setTabelaEmAndamento] = useState([]);

  const [grafico1, setGrafico1] = useState([]);
  const [grafico2, setGrafico2] = useState([]);
  const [grafico3, setGrafico3] = useState([]);

  const [liveToggle, setLiveToggle] = useState(false)
  const [liveToggleG2, setLiveToggleG2] = useState(false)
  const [liveToggleG1, setLiveToggleG1] = useState(false)
  const [liveToggleG3, setLiveToggleG3] = useState(false)

  const [queryLondrina, setQueryLondrina] = useState(true)
  const [queryArapongas, setQueryArapongas] = useState(true)
  const [queryMaringa, setQueryMaringa] = useState(true)
  const [queryOne, setQueryOne] = useState(true)

  const [cidadeG1, setcidadeG1] = useState("")
  const [cidadeG2, setcidadeG2] = useState("")
  const [cidadeG3, setcidadeG3] = useState("")

  const [queryG1, setqueryG1] = useState(false)
  const [queryG2, setqueryG2] = useState(false)
  const [queryG3, setqueryG3] = useState(false)
  
  const chartByDate = useQuery(['chartByDate', week, 'londrina' ], getChartDate, {enabled: queryLondrina} )
  const chartByDateArapongas = useQuery(['chartByDate', week, 'arapongas' ], getChartDate, {enabled: queryArapongas} )
  const chartByDateMaringa = useQuery(['chartByDate', week, 'maringa' ], getChartDate, {enabled: queryMaringa} )

  const chartByDateG1 = useQuery(['chartByDate', weekG1, cidadeG1 ], getChartDate, {enabled: queryG1} )
  const chartByDateG2 = useQuery(['chartByDate', weekG2, cidadeG2 ], getChartDate, {enabled: queryG2} )
  const chartByDateG3 = useQuery(['chartByDate', weekG3, cidadeG3 ], getChartDate, {enabled: queryG3} )

  const chartData = useQuery(['chart', 'londrina'], getChartData, {
    refetchInterval: 30000,
    refetchIntervalInBackground: true,
    enabled: queryLondrina
  })
  const chartDataArapongas = useQuery(['chart', 'arapongas'], getChartData, {
    refetchInterval: 30000,
    refetchIntervalInBackground: true,
    enabled: queryArapongas
  })
  const chartDataMaringa = useQuery(['chart', 'maringa'], getChartData, {
    refetchInterval: 30000,
    refetchIntervalInBackground: true,
    enabled: queryMaringa
  })
  const agendadas= useQuery(['tabela', 'agendadas'],procurarAgendadas, {
    refetchInterval: 10000,
    refetchIntervalInBackground: true
  })

  const entregasEmAndamento = useQuery(['tabela', 'emAndamento'],procurarEmAndamento, {
    refetchInterval: 10000,
    refetchIntervalInBackground: true
  })


  const optionsSelect = [
    { value: 'arapongas', label: 'Arapongas - PR' },
    { value: 'londrina', label: 'Londrina - PR' },
    { value: 'maringa', label: 'Maringá - PR' },
  ]

  const colunasEntregas =[{chave: "restaurante", valor: "Restaurantes"},
  {chave: "corrida", valor: "Corridas"}]
  const colunasEmAndamento =[{chave: "restaurante", valor: "Restaurantes"}, {chave: "p", valor: "P"}, {chave: "ct", valor: "CT"}]

  const hangleRobo = (status) =>{

    if(status === "ativa"){
      setRoboGrafico.mutate({"data":"ativa"})
    }else if(status === "desativa"){
      setRoboGrafico.mutate({"data":"desativada"})
    }
  }

  const setRoboGrafico =  useMutation(setRobo, {
    onSuccess: (e) => {
      if(e["status"] == '201'){
        alert(e.data.data.data)
        setAvisoBoot(false)
        return true
      }

      queryClient.invalidateQueries()
    },
    onError: (e) => {
      return true
    }
  });


  const handleSelect = (e, grafico) =>{
   
    if(grafico == "g1"){
      switch(e.target.value){
        case "arapongas":
          setQueryArapongas(true)
          setGrafico1(seriesArapongas)
          graficosAtivos[0] = "ga"
          break
        case "londrina":
          setQueryLondrina(true)
          setGrafico1(series)
          graficosAtivos[0]= "gl"
          break
        case "maringa":
          setQueryMaringa(true)
          setGrafico1(seriesMaringa)
          graficosAtivos[0] = "gm"
          break
        default:
          break
      }
      
    }
    
    if(grafico == "g2"){
      switch(e.target.value){
        case "arapongas":
          setQueryArapongas(true)
          setGrafico2(seriesArapongas)
          graficosAtivos[1] = "ga"
          break
        case "londrina":
          setQueryLondrina(true)
          setGrafico2(series)
          graficosAtivos[1] = "gl"
          break
        case "maringa": 
        setQueryMaringa(true)
          setGrafico2(seriesMaringa)
          graficosAtivos[1] = "gm"
          break
        default:
          break
      } 
    }

    if(grafico == "g3"){
      switch(e.target.value){
        case "arapongas":
          setQueryArapongas(true)
          setGrafico3(seriesArapongas)
          graficosAtivos[2] = "ga"
          break
        case "londrina":
          setQueryLondrina(true)
          setGrafico3(series)
          graficosAtivos[2] = "gl"
          break
          case "maringa": 
          setQueryMaringa(true)
          setGrafico3(seriesMaringa)
          graficosAtivos[2] = "gm"
          break
        default:
          break
      } 
    }
  }
  const teste = useMemo(() => {
    return tabelaAgendadas

  },[setTabelaAgendadas])

  const chartOptions = useMemo(() => {
    return {
      id: 'uPlotId',
      class: 'uPlot-todos',
      width: prop.valueW,
      height: prop.valueH,
      hooks: {
        setLegend: [
          (u) => {
            if (u.legend.idx == null) {
              u.latest = true
              u.setLegend({ idx: u.data[0].length - 1 }, false)
            } else {
              u.latest = false
            }
          },
        ],
        setData: [
          (u) => {
            if (u.latest) {
              u.setLegend({ idx: u.data[0].length - 1 }, false)
            }
          },
        ],
      },
      series: [
        {
          value: (self, rawValue) => {
            var newDate = new Date()
            newDate.setTime(rawValue + '000.0')
            
            let dateStr = newDate.toLocaleString()
            if (dateStr !== 'Invalid Date') {
              return dateStr
            }
          },
        },
        {
          label: 'Em Operação',
          stroke: darkMode ? 'rgb(30,30,200)' : 'blue',
          spanGaps: true,
          width: 2,
          drawStyle: drawStyles.line,
          lineInterpolation: lineInterpolations.spline,
          paths,

          value: (self, rawValue) => {
            if (rawValue) {
              let check = rawValue.toString()
              if (check.length <= 1) {
                check = '0' + check
                return check
              } else return rawValue
            } else return '0'
          },
        },
        {
          label: 'Livres',
          stroke: darkMode ? 'rgb(40,180,40)' : 'green',
          spanGaps: true,
          show: false,
          width: 2,
          drawStyle: drawStyles.line,
          lineInterpolation: lineInterpolations.spline,
          paths,
          value: (self, rawValue) => {
            if (rawValue) {
              let check = rawValue.toString()
              if (check.length <= 1) {
                check = '0' + check
                return check
              } else return rawValue
            } else return '0'
          },
        },
        {
          label: 'Em Andamento',
          stroke: darkMode ? 'rgb(200,20,20)' : 'orangeRed',
          spanGaps: true,
          width: 2,
          drawStyle: drawStyles.line,
          lineInterpolation: lineInterpolations.spline,
          paths,
          value: (self, rawValue) => {
            if (rawValue) {
              let check = rawValue.toString()
              if (check.length <= 1) {
                check = '0' + check
                return check
              } else return rawValue
            } else return '0'
          },
        },
        {
          label: 'Pendências',
          stroke: darkMode ? 'rgba(250,20,20,0.2)' : 'rgba(250,20,20,0.4)',
          spanGaps: true,
          width: 2,
          drawStyle: drawStyles.line,
          lineInterpolation: lineInterpolations.spline,
          paths,
          value: (self, rawValue) => {
            if (rawValue) {
              let check = rawValue.toString()
              if (check.length <= 1) {
                check = '0' + check
                return check
              } else return rawValue
            } else return '0'
          },
          //value: (self, rawValue) => (rawValue === null ? '--' : rawValue),
        },
      ],
      axes: [
        {
          stroke: darkMode ? '#888' : 'black',
          grid: {
            show: true,
            stroke: darkMode ? 'rgba(40,40,40,0.5)' : 'rgba(10,10,10,0.3)',
            width: 1,
          },
        },
        {
          show: true,
          side: 1,
          font: '13px Arial',
          stroke: darkMode ? '#888' : 'black',
          grid: {
            show: true,
            stroke: darkMode ? 'rgba(40,40,40,0.5)' : 'rgba(10,10,10,0.3)',
            width: 1,
          },
          ticks: {
            show: true,
            stroke: darkMode ? '#444' : 'rgba(10,10,10,0.3)',
            width: 1,
          },
        },
        {
          show: false,

          stroke: darkMode ? '#888' : 'black',
          grid: {
            show: false,
          },
          ticks: {
            show: true,
            stroke: darkMode ? '#444' : 'rgba(10,10,10,0.3)',
            width: 1,
          },
        },
        {
          scale: 'pend',

          stroke: darkMode ? '#888' : 'black',
          grid: {
            show: false,
          },
          ticks: {
            show: true,
            stroke: darkMode ? '#444' : 'rgba(10,10,10,0.3)',
            width: 1,
          },
        },
      ],
      scales: {
        x: {
          distr: 2,
        },
      },
    }
  }, [darkMode, prop.valueW, prop.valueH, grafico1, grafico2, grafico3 ])

  useEffect(() => {
    if(agendadas.isSuccess){
      setTabelaAgendadas(agendadas.data)
    }

    if(entregasEmAndamento.isSuccess){
      setTabelaEmAndamento(entregasEmAndamento.data)
    }
  }, [agendadas, entregasEmAndamento, tabelaAgendadas, tabelaEmAndamento ])

  useEffect(() => {
    //CONDIÇÕES PARA QUANDO O GRAFICO FUNCIONAR AO VIVO
    if(queryOne){
      //Armazena uma vez quando a pagina é aberta
      setQueryLondrina(false)
      setQueryArapongas(false)
      setQueryMaringa(false)
      setQueryOne(false)
    }
    
    if(graficosAtivos.length > 0){
      const nameGraficos = ["ga", "gl", "gm"]
      nameGraficos.map((item)=>{
        if(!graficosAtivos.includes(item)){
          switch(item){
            case "ga":
              setQueryArapongas(false)
              break
            case "gl":
              setQueryLondrina(false)
              break
            case "gm":
              setQueryMaringa(false)
              break
            default:
              break
          }
        }
      })
    }


    if ((chartData.isSuccess && graficosAtivos[0]=="gl" && liveToggleG1) || (chartData.isSuccess && graficosAtivos[1]=="gl" && liveToggleG2)
      || (chartData.isSuccess && graficosAtivos[2]=="gl" && liveToggleG3)) 
    {
      let motoTime = new Date(chartData?.data[0]?.createdAt)

      var dataAgora = new Date()

      const diferencaMs = dataAgora - motoTime;

      // Converta a diferença de milissegundos para dias
      const umMinutoMs = 1000 * 60;
      const diferencaMinutos = diferencaMs / umMinutoMs;

      if(diferencaMinutos > 10){
        setAvisoBoot(true)
      }else{
        setAvisoBoot(false)
      }
      
      setseries((prev) => {
        return [
          [...prev[0], Math.floor(motoTime.getTime() / 1000)],
          [...prev[1], chartData?.data[0]?.emOperaçao],
          [...prev[2], chartData?.data[0]?.livres],
          [...prev[3], chartData?.data[0]?.emAndamento],
          [...prev[4], chartData?.data[0]?.pend],
        ]
      })

      let ativo = graficosAtivos.map(function(item, index){ return item == "gl" ? index : 5})
      ativo.map((item)=>{
        switch(item){
          case 0:
            if(weekG1.isToday && liveToggleG1){
              setGrafico1(series)
            }
            break
          case 1:
            if(weekG2.isToday && liveToggleG2){
              setGrafico2(series)
            }
            break
          case 2:
            if(weekG3.isToday && liveToggleG3){
              setGrafico3(series)
            }
            break
          default:
            break
        }
      })
    }

    if (( chartDataArapongas.isSuccess && graficosAtivos[0]=="ga" && liveToggleG1) || ( chartDataArapongas.isSuccess && graficosAtivos[1]=="ga" && liveToggleG2)
      || ( chartDataArapongas.isSuccess && graficosAtivos[2]=="ga" && liveToggleG1) ) 
    {
      let motoTime = new Date(chartDataArapongas?.data[0]?.createdAt)
      var dataAgora = new Date()

      const diferencaMs = dataAgora - motoTime;

      // Converta a diferença de milissegundos para dias
      const umMinutoMs = 1000 * 60;
      const diferencaMinutos = diferencaMs / umMinutoMs;

      /*if(diferencaMinutos > 10){
        setAvisoBoot(true)
      }else{
        setAvisoBoot(false)
      }*/

      setseriesArapongas((prev) => {
        return [
          [...prev[0], Math.floor(motoTime.getTime() / 1000)],
          [...prev[1], chartDataArapongas?.data[0]?.emOperaçao],
          [...prev[2], chartDataArapongas?.data[0]?.livres],
          [...prev[3], chartDataArapongas?.data[0]?.emAndamento],
          [...prev[4], chartDataArapongas?.data[0]?.pend],
        ]
      })

      let ativo = graficosAtivos.map(function(item, index){ return item == "ga" ? index : 5})
      ativo.map((item)=>{
        switch(item){
          case 0:
            if(weekG1.isToday && liveToggleG1){
              setGrafico1(seriesArapongas)
            }
            break
          case 1:
            if(weekG2.isToday && liveToggleG2){
              setGrafico2(seriesArapongas)
            }
            break
          case 2:
            if(weekG3.isToday && liveToggleG3){
              setGrafico3(seriesArapongas)
            }
            break
          default:
            break
        }
      })
    }

    if ((chartDataMaringa.isSuccess && graficosAtivos[0]=="gm" && liveToggleG1) || (chartDataMaringa.isSuccess && graficosAtivos[1]=="gm" && liveToggleG2)
      || (chartDataMaringa.isSuccess && graficosAtivos[2]=="gm" && liveToggleG3) ) 
    {

      let motoTime = new Date(chartDataMaringa?.data[0]?.createdAt)
      var dataAgora = new Date()

      const diferencaMs = dataAgora - motoTime;

      // Converta a diferença de milissegundos para dias
      const umMinutoMs = 1000 * 60;
      const diferencaMinutos = diferencaMs / umMinutoMs;

      /*if(diferencaMinutos > 10){
        setAvisoBoot(true)
      }else{
        setAvisoBoot(false)
      }*/

      setseriesMaringa((prev) => {
        return [
          [...prev[0], Math.floor(motoTime.getTime() / 1000)],
          [...prev[1], chartDataMaringa?.data[0]?.emOperaçao],
          [...prev[2], chartDataMaringa?.data[0]?.livres],
          [...prev[3], chartDataMaringa?.data[0]?.emAndamento],
          [...prev[4], chartDataMaringa?.data[0]?.pend],
        ]
      })

      let ativo = graficosAtivos.map(function(item, index){ return item == "gm" ? index : 5})
      ativo.map((item)=>{
        switch(item){
          case 0:
            if(weekG1.isToday && liveToggleG1){
              setGrafico1(seriesMaringa)
            }
            break
          case 1:
            if(weekG2.isToday && liveToggleG2){
              setGrafico2(seriesMaringa)
            }
            break
          case 2:
            if(weekG3.isToday && liveToggleG3){
              setGrafico3(seriesMaringa)
            }
            break
          default:
            break
        }
      })
    }
  }, [chartData.isSuccess, chartData.data, chartDataArapongas.isSuccess, chartDataArapongas.data, chartDataMaringa.isSuccess, chartDataMaringa.data, liveToggle, liveToggleG2, liveToggleG3, liveToggleG1])

  useEffect(() => {
    //Condições para funcionar estatico e quando nenhum grafico estiver selecionado 

    //Para funcionar estatico, com um intervalo de data selecionado
    if(chartByDateG1.isSuccess){
      setGrafico1(chartByDateG1?.data[0])

      if (!weekG1.isToday) {
        setLiveToggleG1(false)
      } else {
        setLiveToggleG1(true)
      }
    }

    if(chartByDateG2.isSuccess){
      setGrafico2(chartByDateG2?.data[0])

      if (!weekG2.isToday) {
        setLiveToggleG2(false)
      } else {
        setLiveToggleG2(true)
      }
    }

    if(chartByDateG3.isSuccess){
      setGrafico3(chartByDateG3?.data[0])

      if (!weekG3.isToday) {
        setLiveToggleG3(false)
      } else {
        setLiveToggleG3(true)
      }
    }

    //Para funcionar em graficos selecionados

    if (chartByDate.isSuccess || chartByDate.isFetchedAfterMount){
      setseries(chartByDate?.data[0])

      if (!week.isToday) {
        setLiveToggleG1(false)
        setLiveToggleG2(false)
        setLiveToggleG3(false)
      } else {
        setLiveToggleG1(true)
        setLiveToggleG2(true)
        setLiveToggleG3(true)
      }

      let ativo = graficosAtivos.map(function(item, index){ return item == "gl" ? index : 5})
      ativo.map((item)=>{
        switch(item){
          case 0:
            if(weekG1.isToday){
              setGrafico1(series)
            }
            break
          case 1:
            if(weekG2.isToday){
              setGrafico2(series)
            }
            break
          case 2:
            if(weekG3.isToday){
              setGrafico3(series)
            }
            break
          default:
            break
        }
      })
    }

    if (chartByDateArapongas.isSuccess || chartByDateArapongas.isFetchedAfterMount) {
      setseriesArapongas(chartByDateArapongas?.data[0])

      if (!week.isToday) {
        setLiveToggleG1(false)
        setLiveToggleG2(false)
        setLiveToggleG3(false)
      } else {
        setLiveToggleG1(true)
        setLiveToggleG2(true)
        setLiveToggleG3(true)
      }

      let ativo = graficosAtivos.map(function(item, index){ return item == "ga" ? index : 5})
      ativo.map((item)=>{
        switch(item){
          case 0:
            if(weekG1.isToday){
              setGrafico1(seriesArapongas)
            }
            break
          case 1:
            if(weekG2.isToday){
              setGrafico2(seriesArapongas)
            }
            break
          case 2:
            if(weekG3.isToday){
              setGrafico3(seriesArapongas)
            }
            break
          default:
            break
        }
      })
    }

    if (chartByDateMaringa.isSuccess || chartByDateMaringa.isFetchedAfterMount) {

      setseriesMaringa(chartByDateMaringa?.data[0])

      if (!week.isToday) {
        setLiveToggleG1(false)
        setLiveToggleG2(false)
        setLiveToggleG3(false)
      } else {
        setLiveToggleG1(true)
        setLiveToggleG2(true)
        setLiveToggleG3(true)
      }

      let ativo = graficosAtivos.map(function(item, index){ return item == "gm" ? index : 5})
      ativo.map((item)=>{
        switch(item){
          case 0:
            if(weekG1.isToday){
              setGrafico1(seriesMaringa)
            }
            break
          case 1:
            if(weekG2.isToday){
              setGrafico2(seriesMaringa)
            }
            break
          case 2:
            if(weekG3.isToday){
              setGrafico3(seriesMaringa)
            }
            break
          default:
            break
        }
      })
    }
  }, [
    chartByDateG1.isSuccess,
    chartByDateG2.isSuccess,
    chartByDateG3.isSuccess,
    chartByDate.isSuccess,
    chartByDateArapongas.isSuccess,
    chartByDateMaringa.isSuccess,
    week.isToday,
    weekG1.isToday,
    weekG2.isToday,
    weekG3.isToday,
    chartByDate.isFetchedAfterMount,
    chartByDateArapongas.isFetchedAfterMount,
    chartByDateMaringa.isFetchedAfterMount,
    chartByDate.data,
    chartByDateArapongas.data,
    chartByDateMaringa.data,
  ])
  return (
    <>
      <div className="chart">
        <div
          id="charts"
          className="chartsView"
        >
          <div className='chartDiv'>
            <div className='nameChart'>
              <div className='menu-chart'>
                <div className='titleChart'>    
                  <select defaultValue={"default"} onChange={event => handleSelect(event, "g1")} style={{border:"none",  background: "transparent",  color: "rgb(78, 78, 78)", fontSize: "18px", width:"100%", margin:"2px", cursor:"pointer" }} className="selectSize">
                    <option disabled hidden value="default"> Gráficos Cidades </option>
                    {
                      optionsSelect.map((obj) => (
                        <option key={obj.value} value={obj.value}>{obj.label}</option>
                      ))
                    }
                  </select>
                </div>
                <div className='menu-date'>
                  <div className='menu-icons-center'>
                    <div className="datePicker">
                      <div
                        className={displayDates1 ? 'pickers' : 'none'}
                        onMouseLeave={() => setdisplayDates1((prev) => !prev)}
                      >
                        <div className="newDates">
                          <div className="dates clickable">
                            <div className="label">
                              <p>De: </p>
                            </div>
                            <DatePicker
                              ref={dateFromRef}
                              selected={weekG1.startDate}
                              onChange={(date) =>{
                                switch(graficosAtivos[0]){
                                  case "gl":
                                    setcidadeG1("londrina")
                                    setqueryG1(true)
                                    break
                                  case "ga":
                                    setcidadeG1("arapongas")
                                    setqueryG1(true)
                                    break
                                  case "gm":
                                    setcidadeG1("maringa")
                                    setqueryG1(true)
                                    break
                                  default:
                                    break
                                }
                                setWeeksG1((prev) => {
                                  return {
                                    ...prev,
                                    startDate: date,
                                    isToday: false,
                                  }
                                })
                              }}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              timeCaption="time"
                              dateFormat="MMMM d, yyyy h:mm aa"
                            />
                          </div>
                          <div className="dates clickable">
                            <div className="label">
                              <p>Até: </p>
                            </div>
                            <DatePicker
                              ref={dateToRef}
                              selected={weekG1.endDate}
                              onChange={(date) =>{
                                switch(graficosAtivos[0]){
                                  case "gl":
                                    setcidadeG1("londrina")
                                    setqueryG1(true)
                                    break
                                  case "ga":
                                    setcidadeG1("arapongas")
                                    setqueryG1(true)
                                    break
                                  case "gm":
                                    setcidadeG1("maringa")
                                    setqueryG1(true)
                                    break
                                  default:
                                    break
                                }
                                setWeeksG1((prev) => {
                                  return {
                                    ...prev,
                                    endDate: date,
                                    isToday: false,
                                  }
                                })}
                              }
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              timeCaption="time"
                              dateFormat="MMMM d, yyyy h:mm aa"
                            />
                          </div>
                        </div>
                        <i
                          className="icon material-icons-outlined clickable"
                          onClick={() => {
                            setLiveToggleG1(false)
                            chartByDateG1.refetch()
                          }}
                        >
                          date_range
                        </i>
                      </div>
                      <div className="buttons">
                        <i
                          className={`icon material-icons-outlined ${
                            chartByDateG1.isLoading && 'loading'
                          }`}
                          onClick={() => setdisplayDates1((prev) => !prev)}
                        >
                          {chartByDateG1.isLoading ? 'sync' : 'calendar_month'}
                        </i>
                        {!weekG1.isToday && !chartByDateG1.isLoading && (
                          <p
                            className="hoje"
                            onClick={() =>
                              setWeeksG1((prev) => {
                                return {
                                  ...prev,
                                  currWeek: getWeek(new Date()),
                                  isToday: true,
                                }
                              })
                            }
                          >
                            Voltar p/ hoje
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right">
                  <div className="buttons">
                    {liveToggleG1 ? (
                      <div
                        className="live"
                        onClick={() => setLiveToggleG1((prev) => !prev)}
                      >
                        <div className="buttons">
                          <i className="icon ativo material-icons-outlined">update</i>
                          <p className="ativo">Auto</p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="live"
                        onClick={() => setLiveToggleG1((prev) => !prev)}
                      >
                        <div className="buttons">
                          <i className="icon inativo material-icons-outlined">
                            update_disabled
                          </i>
                          <p className="inativo">Auto</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div>
                {grafico1.length == 0 &&
                  <div className="imagens"> 
                    <img style={{border: "2px solid #efefef", borderRadius: "7px", margin: "5px 15px 5px 15px"}} src={g2} alt="testsss" width={500} height={250} />
                    <img style={{border: "2px solid #efefef", borderRadius: "7px",  margin: "2px 15px 5px 15px"}} src={g1} alt="testsss" width={500} height={250} />
                  </div> 
                }
              </div>
              <div>
                {grafico1.length != 0 &&
                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                    <UplotReact  options={chartOptions} data={grafico1} />
                    { graficosAtivos[0] != "" && tabelaAgendadas.length != 0 && tabelaEmAndamento.length != 0 &&
                      <div className='listas'>
                        <List largura={prop.valueW} altura={prop.valueH} colunas={colunasEntregas} itens={tabelaAgendadas[graficosAtivos[0]]} titulo={"Agendadas"}  limite={20}/>
                        <List largura={prop.valueW} altura={prop.valueH} colunas={colunasEmAndamento} itens={tabelaEmAndamento[graficosAtivos[0]]} titulo={"Em Andamento"} limite={10}/>
                      </div>   
                    }                 
                  </div>
                }
              </div>
            </div>
            
            <div className='nameChart'>
              <div className='menu-chart'>
                <div className='titleChart'>   
                  <select defaultValue={"default"} onChange={event => handleSelect(event, "g2")} style={{border:"none",  background: "transparent",  color: "rgb(78, 78, 78)", fontSize: "18px", width:"100%",  margin:"2px" }} className="selectSize">
                    <option value="default" disabled hidden> Gráficos Cidades </option>
                    {
                      optionsSelect.map((obj) => (
                        <option key={obj.value} value={obj.value}>{obj.label}</option>
                      ))
                    }
                  </select>
                </div>
                <div className='menu-date'>
                  <div className='menu-icons-center'> 
                    <div className="datePicker">
                      <div
                        className={displayDates2 ? 'pickers' : 'none'}
                        onMouseLeave={() => setdisplayDates2((prev) => !prev)}
                      >
                        <div className="newDates">
                          <div className="dates clickable">
                            <div className="label">
                              <p>De: </p>
                            </div>
                            <DatePicker
                              ref={dateFromRef}
                              selected={weekG2.startDate}
                              onChange={(date) =>{
                                switch(graficosAtivos[1]){
                                  case "gl":
                                    setcidadeG2("londrina")
                                    setqueryG2(true)
                                    break
                                  case "ga":
                                    setcidadeG2("arapongas")
                                    setqueryG2(true)
                                    break
                                  case "gm":
                                    setcidadeG2("maringa")
                                    setqueryG2(true)
                                    break
                                  default:
                                    break
                                }
                                setWeeksG2((prev) => {
                                  return {
                                    ...prev,
                                    startDate: date,
                                    isToday: false,
                                  }
                                })
                              }}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              timeCaption="time"
                              dateFormat="MMMM d, yyyy h:mm aa"
                            />
                          </div>
                          <div className="dates clickable">
                            <div className="label">
                              <p>Até: </p>
                            </div>
                            <DatePicker
                              ref={dateToRef}
                              selected={weekG2.endDate}
                              onChange={(date) =>{
                                switch(graficosAtivos[1]){
                                  case "gl":
                                    setcidadeG2("londrina")
                                    setqueryG2(true)
                                    break
                                  case "ga":
                                    setcidadeG2("arapongas")
                                    setqueryG2(true)
                                    break
                                  case "gm":
                                    setcidadeG2("maringa")
                                    setqueryG2(true)
                                    break
                                  default:
                                    break
                                }
                                setWeeksG2((prev) => {
                                  return {
                                    ...prev,
                                    endDate: date,
                                    isToday: false,
                                  }
                                })}
                              }
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              timeCaption="time"
                              dateFormat="MMMM d, yyyy h:mm aa"
                            />
                          </div>
                        </div>
                        <i
                          className="icon material-icons-outlined clickable"
                          onClick={() => {
                            setLiveToggleG2(false)
                            chartByDateG2.refetch()
                          }}
                        >
                          date_range
                        </i>
                      </div>
                      <div className="buttons">
                        <i
                          className={`icon material-icons-outlined ${
                            chartByDateG2.isLoading && 'loading'
                          }`}
                          onClick={() => setdisplayDates2((prev) => !prev)}
                        >
                          {chartByDateG2.isLoading ? 'sync' : 'calendar_month'}
                        </i>
                        {!weekG2.isToday && !chartByDateG2.isLoading && (
                          <p
                            className="hoje"
                            onClick={() =>
                              setWeeksG2((prev) => {
                                return {
                                  ...prev,
                                  currWeek: getWeek(new Date()),
                                  isToday: true,
                                }
                              })
                            }
                          >
                            Voltar p/ hoje
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right">
                  <div className="buttons">
                    {liveToggleG2 ? (
                      <div
                        className="live"
                        onClick={() => setLiveToggleG2((prev) => !prev)}
                      >
                        <div className="buttons">
                          <i className="icon ativo material-icons-outlined">update</i>
                          <p className="ativo">Auto</p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="live"
                        onClick={() => setLiveToggleG2((prev) => !prev)}
                      >
                        <div className="buttons">
                          <i className="icon inativo material-icons-outlined">
                            update_disabled
                          </i>
                          <p className="inativo">Auto</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            <div>
                {grafico2.length == 0 &&
                  <div className="imagens"> 
                    <img style={{border: "2px solid #efefef", borderRadius: "7px", margin: "5px 15px 5px 15px"}} src={g2} alt="testsss" width={500} height={250} />
                    <img style={{border: "2px solid #efefef", borderRadius: "7px",  margin: "2px 15px 5px 15px"}} src={g1} alt="testsss" width={500} height={250} />
                  </div> 
                }
              </div>
              <div>
                {grafico2.length != 0 &&
                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                    <UplotReact  options={chartOptions} data={grafico2} />   
                    { graficosAtivos[1] != "" && tabelaAgendadas.length != 0 && tabelaEmAndamento.length != 0 &&
                      <div className='listas'>
                        <List colunas={colunasEntregas} itens={tabelaAgendadas[graficosAtivos[1]]} titulo={"Agendadas"} limite={20}/>
                        <List colunas={colunasEmAndamento} itens={tabelaEmAndamento[graficosAtivos[1]]} titulo={"Em Andamento"} limite={10}/>
                      </div>   
                    }        
                  </div>     
                }
              </div>
            </div>
            <div className='nameChart'>
              <div className='menu-chart'>
                <div className='titleChart'>   
                  <select defaultValue={"default"} onChange={event => handleSelect(event, "g3")} style={{border:"none",  background: "transparent",  color: "rgb(78, 78, 78)", fontSize: "18px", width:"100%",  margin:"2px" }} className="selectSize">
                    <option value="default" disabled hidden> Gráficos Cidades </option>
                    {
                      optionsSelect.map((obj) => (
                        <option key={obj.value} value={obj.value}>{obj.label}</option>
                      ))
                    }
                  </select>
                </div>
                <div className='menu-date'>
                  <div className='menu-icons-center'>
                    <div className="datePicker">
                      <div
                        className={displayDates3 ? 'pickers' : 'none'}
                        onMouseLeave={() => setdisplayDates3((prev) => !prev)}
                      >
                        <div className="newDates">
                          <div className="dates clickable">
                            <div className="label">
                              <p>De: </p>
                            </div>
                            <DatePicker
                              ref={dateFromRef}
                              selected={weekG3.startDate}
                              onChange={(date) =>{
                                switch(graficosAtivos[2]){
                                  case "gl":
                                    setcidadeG3("londrina")
                                    setqueryG3(true)
                                    break
                                  case "ga":
                                    setcidadeG3("arapongas")
                                    setqueryG3(true)
                                    break
                                  case "gm":
                                    setcidadeG3("maringa")
                                    setqueryG3(true)
                                    break
                                  default:
                                    break
                                }
                                setWeeksG3((prev) => {
                                  return {
                                    ...prev,
                                    startDate: date,
                                    isToday: false,
                                  }
                                })
                              }}
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              timeCaption="time"
                              dateFormat="MMMM d, yyyy h:mm aa"
                            />
                          </div>
                          <div className="dates clickable">
                            <div className="label">
                              <p>Até: </p>
                            </div>
                            <DatePicker
                              ref={dateToRef}
                              selected={weekG3.endDate}
                              onChange={(date) =>{
                                switch(graficosAtivos[2]){
                                  case "gl":
                                    setcidadeG3("londrina")
                                    setqueryG3(true)
                                    break
                                  case "ga":
                                    setcidadeG3("arapongas")
                                    setqueryG3(true)
                                    break
                                  case "gm":
                                    setcidadeG3("maringa")
                                    setqueryG3(true)
                                    break
                                  default:
                                    break
                                }
                                setWeeksG3((prev) => {
                                  return {
                                    ...prev,
                                    endDate: date,
                                    isToday: false,
                                  }
                                })}
                              }
                              showTimeSelect
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              timeCaption="time"
                              dateFormat="MMMM d, yyyy h:mm aa"
                            />
                          </div>
                        </div>
                        <i
                          className="icon material-icons-outlined clickable"
                          onClick={() => {
                            setLiveToggleG3(false)
                            chartByDateG3.refetch()
                          }}
                        >
                          date_range
                        </i>
                      </div>
                      <div className="buttons">
                        <i
                          className={`icon material-icons-outlined ${
                            chartByDateG3.isLoading && 'loading'
                          }`}
                          onClick={() => setdisplayDates3((prev) => !prev)}
                        >
                          {chartByDateG3.isLoading ? 'sync' : 'calendar_month'}
                        </i>
                        {!weekG3.isToday && !chartByDateG3.isLoading && (
                          <p
                            className="hoje"
                            onClick={() =>
                              setWeeksG3((prev) => {
                                return {
                                  ...prev,
                                  currWeek: getWeek(new Date()),
                                  isToday: true,
                                }
                              })
                            }
                          >
                            Voltar p/ hoje
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right">
                  <div className="buttons">
                    {liveToggleG3 ? (
                      <div
                        className="live"
                        onClick={() => setLiveToggleG3((prev) => !prev)}
                      >
                        <div className="buttons">
                          <i className="icon ativo material-icons-outlined">update</i>
                          <p className="ativo">Auto</p>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="live"
                        onClick={() => setLiveToggleG3((prev) => !prev)}
                      >
                        <div className="buttons">
                          <i className="icon inativo material-icons-outlined">
                            update_disabled
                          </i>
                          <p className="inativo">Auto</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div>
                {grafico3.length == 0 &&
                  <div className="imagens"> 
                    <img style={{border: "2px solid #efefef", borderRadius: "7px", margin: "5px 15px 5px 15px"}} src={g2} alt="testsss" width={500} height={250} />
                    <img style={{border: "2px solid #efefef", borderRadius: "7px",  margin: "2px 15px 5px 15px"}} src={g1} alt="testsss" width={500} height={250} />
                  </div> 
                }
              </div>
              <div>
                {grafico3.length != 0 &&
                  <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                    <UplotReact  options={chartOptions} data={grafico3} />
                    { graficosAtivos[2] != "" && tabelaAgendadas.length != 0 && tabelaEmAndamento.length != 0 &&
                      <div className='listas'>
                        <List colunas={colunasEntregas} itens={tabelaAgendadas[graficosAtivos[2]]} titulo={"Agendadas"}limite={20} />
                        <List colunas={colunasEmAndamento} itens={tabelaEmAndamento[graficosAtivos[2]]} titulo={"Em Andamento"} limite={10}/>
                      </div>     
                    }        
                  </div>   
                }
              </div>
            </div>
          </div>
          
        </div>
        
        <PopUp trigger={avisoBoot} setTrigger={setAvisoBoot}>
              <div style={{ margin:'15px', display:'flex', flexDirection:'column', alignItems:'center'}}>
                <div style={{color: '#c84333', marginBottom:'10px'}}>
                  ATENÇÃO, GRÁFICO NÃO ESTÁ FUNCIONANDO
                </div>
                <Button onClick={() => {hangleRobo("ativa")}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Ativar Boot Reserva"} />
                </div>
        </PopUp>
      </div>
    </>
  )
}

export default UplotChart
