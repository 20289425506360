import axios from 'axios'

/* SUMÁRIO
  criaBoletoData: gera boletos
  createLevyData: gerar cobranças e enviar mensagens  

  salvaListaPDFCobrancas: salva arquivos pdf no banco de dado

  atualizaTodosStatus: Atualizar todos os Status do banco
  atualizaManualmenteStatus: Atualização manual do status
  atualizaStatusAtivoMachine: Atualiza status para ativo na machine

  procuraFiltragemEspecial: Procurar dados por filtragem especial
  procuraFiltragemSimples: Procurar dados por filtragem simples
  getFechamentoData: procura fechamento armazenado no banco de dados
  procuraTodosDadosId: procuraTodosDadosId

  reenviaMsgInadimplente: Reenvia mensagens de cobranca para inadimplente
  reenviaMensagens: Reenvia mensagens de cobranca
  msgInadimplentes: Envia mensagens para inadimplentes
  reenviaCobrancaTodos: Reenvia mensagens de cobranca para todos os selecionados
*/

const baseURL = '/financeiraMotoristas'

const financeiraApi = axios.create({
  baseURL: baseURL,
})

export const procuraDadosRecibos = async () => {
  const res = await financeiraApi.get('/procuraDadosRecibos')
  return res;
}

export const cancelaRepasses = async (data) => {
  const res = await financeiraApi.post('/cancelaRepasses', {params: {data}})
  return res;
}

export const atualizaRepassePorID = async (data) =>{
  const res = await financeiraApi.post('/atualizaRepassePorID', {params: {data}})
  return res;
}

export const criaNovoRepasse = async (data) =>{
  let res;
  try{
    res = await financeiraApi.post('/criaNovoRepasse', {params: {data}})
  }catch(error){
    return error;
  }
  return res;
}

export const atualizaAtivoMachine = async (data) => {
  //Atualiza status para ativo na machine
  
  var value = data

  const res = await financeiraApi.post('/atualizaStatusAtivoMachine', value)
  return res
}

export const procuraFiltragemEspecial = async (data) => {
  //Procurar dados por filtragem especial
  
  var value = data

  const res = await financeiraApi.get('/procuraFiltragemEspecial', {params: {value:value}})

  return res
}

export const procuraFiltragemSimples = async (value) => {
  //Procurar dados no banco

  const res = await financeiraApi.get('/procuraFiltragemSimples', {params: {value:value}})

  return res.data
}

export const getFechamentoData = async (value) => {
  //Procura fechamento armazenado no banco de dados
  
  const res = await financeiraApi.get('/fechamentoData', {params: {value:value}})

  return res.data
}

export const procuraTodosDadosId = async (data) => {
  //Procura dados por Id
  
  var value = data

  const res = await financeiraApi.get('/procuraTodosDadosId', {params: {value:value}})
  return res
}

export const reenviaMsgInadimplente = async (data) => {
  //Reenvia mensagens de cobranca para inadimplente
  
  var value = data

  const ids = await financeiraApi.get('/procuraMultiplosDadosId', {params: {value:value}})

  var res
  var corte = 10;
  for (var i = 0; i < ids.data.length; i = i + corte) {
    var novoArray = []
    novoArray.push(ids.data.slice(i, i + corte));

    res = await financeiraApi.post('/enviaMsgIndimplentes', novoArray[0])

    if(res["status"] == 500 ){
      //se houve algum erro ou nenhum inadimplente encontrado
      
      return res
    }
    await delay(3000);
  }
  return res
}


export const reenviaCobrancaTodos = async (data) => {
  //Reenvia mensagens de cobranca para todos os selecionados
  
  var value = data;
  const ids = await financeiraApi.get('/procuraMultiplosDadosId', {params: {value:value}});

  var res;
  var corte = 10;
  for (var i = 0; i < ids.data.length; i = i + corte) {
    var novoArray = []
    novoArray.push(ids.data.slice(i, i + corte));

    if(data.tipo == "todos"){
      res = await financeiraApi.post('/reenviaMsg', novoArray[0]);

      if(res["status"] == 500 ){
        //se houve algum erro ou nenhum inadimplente encontrado
        
        return res;
      }
    }

    if(data.tipo == "whats"){
      res = await financeiraApi.post('/reenviaMsgWhats', novoArray[0]);

      if(res["status"] == 500 ){
        //se houve algum erro ou nenhum inadimplente encontrado
        
        return res;
      }
    }

    if(data.tipo == "email"){
      res = await financeiraApi.post('/reenviaMsgEmail', novoArray[0])

      if(res["status"] == 500 ){
        //se houve algum erro ou nenhum inadimplente encontrado
        
        return res;
      }
    }

    await delay(60000);
  }
  return res;
}

//delay para requests
const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
)