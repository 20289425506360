const ThemeReducer = (state, action) => {
  switch (action.type) {
    case 'light':
      return { darkMode: false }

    case 'dark':
      return { darkMode: true }

    case 'toggle':
      return { darkMode: !state.darkmode }

    // case 'setmediaAlarme':
    //   return { mediaAlarme: action.payload }

    default:
      return state
  }
}

export default ThemeReducer
