import React from 'react'

import { Link, Navigate, useOutlet, Outlet } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'

export const ProtectedLayout = (prop) => {
  const { user, logout } = useAuth()
  
  if (!user) {
    return <Navigate to="/" />
  }
  
  try {
    var adm = user.permissao.adm
    var suporte = user.permissao.suporte
    var entregas = user.permissao.entregas 

    if(prop.autorizacoes == "geral"){

      if( adm == true){
        return  <Outlet context={"adm"} /> 
      }

      return entregas == true ? <Outlet context={"entregas"} /> : <Outlet context={"suporte"} />
    }

    if (adm || suporte && prop.autorizacoes == "suporte") {
      return adm == true ? <Outlet context={"adm"} /> : <Outlet context={"suporte"} />
    }else if (adm && prop.autorizacoes == "adm") {
      return <Outlet context={"adm"} />
    }else if(adm || user.permissao.adm && entregas == "entregas"){
      return adm == true ? <Outlet context={"adm"} /> : <Outlet context={"entregas"} />
    }
  } catch (error) {
    logout()
    return  <Navigate to="/" />
    
  }
  return  <Outlet context={"adm"} /> 
}
