import React, {useEffect, useState, useMemo} from 'react'
import { useOutletContext } from "react-router-dom";
import Icon from '@material-ui/core/Icon'
import {useQuery, useMutation, useQueryClient} from 'react-query'
import { Oval } from  'react-loader-spinner'

import './grandesEncomendas.scss'

import axios from 'axios'

import * as XLSX from 'xlsx'

import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import Button from '../../components/button/Button';
import Tabela from '../../components/table/tabela/tabela'
import Cores from '../../components/cores/Cores';
import PopUp from '../../components/popUp/PopUp';
import DateModel from '../../components/date/Date'
import Select from '../../components/select/Select'
import FormFieldList from '../../components/formField/formFieldList/FormFieldList';
import Lista from '../../components/list/listaItens/Lista';

import {procuraFiltroData, procuraPorFiltro } from '../../api/grandesEntregasData'

const GrandesEncomendas = () => {

  const baseURL = '/grandesEntregas'
  const queryClient = useQueryClient()
  const grandesEntregasApi = axios.create({
    baseURL: baseURL,
  })

  let PageSize = 10;

  const cabecaTabela = [
    { value: 'createdAt', label: 'HORA', tipo: {"data":"dia/mes/ano"}},
    { value: 'status', label: 'STATUS', tipo: {"destaque": {"Aguardando": "vermelho", "Finalizada":"verde", "Coletado":"laranja" }} },
    { value: '_id', label: 'ID', tipo: {"id":"id"}},
    { value: '[cliente_remetente.nome_cliente_remetente]', label: 'SOLICITANTE', tipo: {"padrao":"padrao"}},
    { value: '[cliente_destinatario.endereco_parada.cidade_parada]', label: 'ENDEREÇO', tipo: {"padrao":"padrao"}},
    { value: 'id_entregador', label: 'ENTREGADOR', tipo: {"padrao":"padrao"}},
  ]

  const cabecaLista = [
    { value: '_id', label: 'ID', tipo: {"id":"id"}},
    { value: 'status', label: 'STATUS', tipo: {"destaque": {"Aguardando": "vermelho", "Finalizada":"verde", "Coletado":"laranja" }} },
    { value: 'createdAt', label: 'Horário solicitação',  tipo: {"data":"dia/mes/ano"}},
    { value: '[cliente_remetente.nome_cliente_remetente]', label: 'Origem', tipo: {"padrao":"padrao"}},
    { value: '[cliente_destinatario.endereco_parada.cidade_parada]', label: 'Destino', tipo: {"padrao":"padrao"}},
    { value: 'volume', label: 'Volume',  tipo: {"padrao":"padrao"}},
    { value: 'imagem_coleta', label: 'Imagem coleta', tipo: {"icone": "camera_alt"} },
    { value: 'imagem_entrega', label: 'Imagem entrega', tipo: {"icone":  "camera_alt"} },
    { value: '[dados_recebedor.nome]', label: 'Nome recebedor', tipo: {"padrao":"padrao"} },
    { value: '[dados_recebedor.cpf]', label: 'CPF recebedor', tipo: {"padrao":"padrao"} },
    { value: 'assinatura_destinatario', label: 'Assinatura', tipo: {"icone":  "draw"} },
    { value: 'obs_reenvio', label: 'Observações', tipo: {"icone": "description"} },
  ]

  const opcoesSeletor = [
    { value: 'Finalizada', label: 'Finalizada' },
    { value: 'Coletado', label: 'Coletado' },
    { value: 'Aguardando', label: 'Aguardando' },
  ]

  const [inputFields, setInputField] = useState([
    { label: 'ID:', name: '_id', placeholder: 'Digite aqui o id do pedido', value: '',  tipo: 'texto' },
    { label: 'Nome da empresa:', name: 'cliente_remetente.nome_cliente_remetente', placeholder: 'Digite aqui o nome da empresa', value: '',  tipo: 'texto'},
    { label: 'Rua destino:', name: 'cliente_destinatario.endereco_parada', placeholder: 'Digite aqui a rua e/ou numero de destino', value: '',  tipo: 'texto'},
    { label: 'Cidade destino:', name: 'cliente_destinatario.cidade_parada', placeholder: 'Digite aqui a cidade de destino', value: '',  tipo: 'texto'},

  ]);

  const opcoesLocalizacao = {
    timeZone: 'America/Sao_Paulo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  };

  const permissao = useOutletContext();

  const [atualiza, setAtualiza] = useState(false);
  const [popUpFiltro, setPopUpFiltro] = useState(false);
  const [popUpLinha, setPopUpLinha] = useState(false);
  const [loader, setLoader] = useState(false);
  const [checkData, setcheckData] = useState(false);

  const [statusTogger, setStatusTogger] = useState("toggle_on");
  const [selectFilter, setSelectFilter] = useState('');
  const [linhaTabela, setLinhaTabela] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);

  const [dataTabela, setDataTabela] = useState("");

  const [week, setWeeks] = useState({})

  const dataAgora = useMemo(() => new Date(), []);

  const dataHoje = useMemo(() => {
    const date = new Date(dataAgora);
    date.setDate(dataAgora.getDate() + 2);
    return date;
  }, [dataAgora]);

  const date30DaysAgo = useMemo(() => {
    const date = new Date(dataHoje);
    date.setDate(dataHoje.getDate() - 3);
    return date;
  }, [dataHoje]);

  const defaultQueryKey = useMemo(() => [{ updatedAt: { $gte: date30DaysAgo, $lt: dataHoje } }], [date30DaysAgo, dataHoje]);
  const data30Dias = useQuery(defaultQueryKey, procuraFiltroData, {refetchInterval: false});
  
  const lidarTogger = async () => {
    const status = statusTogger === "toggle_on" ? "toggle_off" : "toggle_on"
    setStatusTogger(status)
  }

  const lidarBaixar = async () => {

    const data = dataTabela.map(encomenda => {
      return ({
      ID: encomenda._id.substring(encomenda._id.length - 4),
      "Data/Hora": new Date(encomenda.createdAt.split("Z")[0]).toLocaleString('pt-BR', opcoesLocalizacao),
      Motorista: encomenda.id_entregador,
      "Endereço Destino": `${encomenda.cliente_destinatario.endereco_parada}, ${encomenda.cliente_destinatario.cidade_parada}`
    }) });

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Encomendas');
  
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Relatorio.xlsx';
    document.body.appendChild(a);
    a.click();
  }

  const lidarFiltro = async () => { 
    var filtroDatas = {}
    setLoader(true)

    inputFields.map((obj) =>{
      if(obj.value !== ''){
        filtroDatas[obj.name] = { $regex: obj.value, $options: 'i' }
      }
    })

    if(selectFilter !== ''){
      filtroDatas["status"] = selectFilter
    }

    if(checkData){
      filtroDatas["createdAt"] =  { $gte: week.startDate, $lt: week.endDate }
    }

    //console.log(filtroDatas)
    setStatusTogger("toggle_off")
    procuraPorFiltroData.mutate({queryKey:[filtroDatas]})

  }

  const procuraPorFiltroData =  useMutation(procuraPorFiltro, {
    onSuccess: (e) => {
      if(e["status"] == '201'){
        //console.log(e.data)

        setInputField(prevFields =>
          prevFields.map(field => ({ ...field, value: "" }))
        );
        setSelectFilter("")
        setLoader(false)
        setDataTabela(e.data.data)
        setPopUpFiltro(false)
        queryClient.invalidateQueries(); 
        
      }
    },
    onError: (e) => {

      setInputField(prevFields =>
        prevFields.map(field => ({ ...field, value: "" }))
      );
      setSelectFilter("")
      setLoader(false)
      alert(e.response.data)
      setPopUpFiltro(false)
    }
  });


  useEffect(() => {

    if(atualiza === true){
      
      setAtualiza(false)
    }
  },[setAtualiza, atualiza])

  useEffect(() => {

    if (data30Dias.isSuccess && statusTogger === "toggle_on") {
      setDataTabela(data30Dias.data.data.data)
    }
  },[ data30Dias.data ])

  useEffect(() => {
    let interval;
    if (statusTogger === "toggle_on") {

      interval = setInterval(() => {
        data30Dias.refetch();
      }, 30000);
    }
    return () => clearInterval(interval);
  }, [statusTogger, data30Dias]);

  useEffect(() => {
    if(linhaTabela.length !== 0){
      setPopUpLinha(true)
      //console.log(linhaTabela)
    }

  }, [linhaTabela]);

  return (
    <div className="paginaGrandesEncomendas">
      <div className="contGrandesEncomendas">
        <Navbar  acesso={permissao} invisivel={false} select={"default"} selectList={"default"} selectOP={"default"}/>
        <Sidebar />
        <div className="content">
          
          <div className="tituloGrandesEncomendas">
            <Icon className="iconTitulo">arrow_forward</Icon>
            <div>Consultar Entregas</div>
          </div>

          <div className='botoes-filtro'>
            <Button onClick={()=>{setPopUpFiltro(true)}} width={"100px"} color={"#ffffff"} background={Cores.roxo} border={Cores.roxo} text={"Filtro"} />
            <div className='botao-atualiza'>
              Atualização automática
              <Icon className={statusTogger} onClick={()=>{lidarTogger()}}> {statusTogger}</Icon>
            </div>
          </div>

          <div className='botoes-filtro'>
            <div className='botao-baixar'>
              <Icon onClick={()=>{lidarBaixar()}}> download</Icon>
            </div>
          </div>

          <div className='tabela-cargo'>
            {<Tabela onClickLinha={setLinhaTabela} name={"table"} value= {"value"} data={dataTabela} head={cabecaTabela} onPageChange={page => setCurrentPage(page)} totalCount={dataTabela.length} currentPage={currentPage} pageSize={PageSize} />}
          </div>
        </div>
      </div>

      <PopUp trigger={popUpFiltro} setTrigger={setPopUpFiltro}>
        <div className='popUp-filtro-cargo'>
          { loader == true &&
            <div className='loader'>
              <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
            </div>
          }

          <div className='title-filter'>Filtro</div>

            <div className='itens-filtro'>
              <div className='formulario-filtro'>
                <FormFieldList inputFields={inputFields} setInputField={setInputField} />
              </div>

              <div className='select-cargo'>
                <Select name={"selectFilter"} value={selectFilter} onChange={setSelectFilter} options={opcoesSeletor} titulo={"Status"}/>
              </div>

              <div className='filtro-data'>  
                <input
                  className='box' 
                  type="checkbox"
                  onChange={(e) =>  setcheckData(e.target.checked)}
                  style={{ marginRight: "2px" }}
                />
                <label>
                  Data Pedido:
                    {checkData&&
                      <DateModel
                      onChangeDate={setWeeks}
                      />
                    }
                </label>
              </div>

              <div className='botao-filtro-cargo'>
                <Button onClick={()=>{lidarFiltro()}} width={"100px"} color={"#ffffff"} background={Cores.verde} border={Cores.verde} text={"Aplicar"} />
              </div>
          </div>
        </div>
     </PopUp>

     <PopUp trigger={popUpLinha} setTrigger={setPopUpLinha}>
        <div className='popUp-infos-linha'>
          { linhaTabela.length !== 0 &&
            <div>
              <div className='titulo-entrega'> Entrega</div>
              <Lista chave={cabecaLista} data={linhaTabela} />
            </div>
          }
        </div>
     </PopUp>
    </div>
  )
}

export default GrandesEncomendas
