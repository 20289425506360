import React from 'react'

import { createContext, useReducer } from 'react'
import ThemeReducer from './themes'
const INITIAL_STATE = {
  darkMode: false,
  mediaAlarme: 2.7,
}

export const ThemeCtx = createContext(INITIAL_STATE)

export const ThemeCtxProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ThemeReducer, INITIAL_STATE)
  return (
    <ThemeCtx.Provider
      value={{
        darkMode: state.darkMode,
        mediaAlarme: state.mediaAlarme,
        dispatch,
      }}
    >
      {children}
    </ThemeCtx.Provider>
  )
}
