import React, {useEffect, useState} from 'react'

import './radioButton.scss'

const RadioButton = (props) => {

    const [selectedOption, setSelectedOption] = useState(null);


    const handleClick = (event) => {
        setSelectedOption(event.target.value);
        props.setSelect(event.target.value)
    }

    useEffect(() => {
    
    }, [props.boxes])


    return (
        <div className='grupo-box'>
            {props.boxes.map((obj, indice)=>{
                return(
                    <div className='box-tela-comado' key={obj.nome}>
                        <input
                        className='box' 
                        type="radio"
                        id={obj.nome}
                        name={obj.nome}
                        value={obj.value}
                        checked={selectedOption === obj.value}
                        onChange={(e) => handleClick(e)}
                        style={{ marginRight: "2px" }}
                        />
                        <label>
                            {obj.label}
                        </label>
                    </div>
                )
            })}
        </div>
    );

}

export default RadioButton