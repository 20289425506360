import React, {useState, useEffect} from 'react'
import {useMutation, useQueryClient } from 'react-query'
import ProgressBar from "@ramonak/react-progress-bar";
import Icon from '@material-ui/core/Icon'
import { Oval } from  'react-loader-spinner'
import axios from 'axios'

import PopUp from '../../popUpAlerta/PopUpAlerta'
import PopUpVazio from '../../popUp/PopUp'
import PopUpVazioFluxo from '../../popUp/PopUp'
import Button from '../../button/Button'
import Cores from '../../cores/Cores'
import Input from '../../input/Input'

import {postAtualizaLimite, getDesabilitaLimite, postAtualizaBotao, postAtualizaFluxo} from '../../../api/telaComandoData'

import './tabelaLimite.scss'

const TabelaLimite = (props) => {

    const queryClient = useQueryClient()
    const baseURL = '/telaComando'
    const telaComandoApi = axios.create({
      baseURL: baseURL,
    })

    const [loader, setLoader] = useState(false);
    const [corBotao, setCorBotao] = useState("icon-status-ativo");
    const [barraProgresso, setBarraProgresso] = useState(false);
    const [popUp, setPopUp] = useState(false);
    const [conteudoPopUp, setConteudoPopUp] = useState(false);
    const [novoFluxo, setNovoFluxo] = useState();
    const [dataNumber, setDataNumber] = useState([]);
    const [popUpInfinito, setPopUpInfinito] = useState(false);
    const [popUpFluxo, setPopUpFluxo] = useState(false);
    const [botaoSelecionado, setBotaoSelecionado] = useState("infinito");


    const lidarMudaMode = async (botao) =>{

        if(botao === "infinito"){
            setPopUpInfinito(true)
            
        }else if (botao === "bloqueio"){
            if(botaoSelecionado !== "bloqueio"){
                setLoader(true)
                var cidade = props.cabecalho[1].id
                alteraBotao.mutate({cidade: cidade, tipo: "bloqueio"})
            }
        }
    }

    const lidarMudaFluxo = async (fluxo) =>{
        setNovoFluxo(fluxo);
        setPopUpFluxo(true);
    }

    const lidarDEsabilitarLimite = async () =>{
        setPopUpInfinito(false)
        //setLoader(true)

        var cidade = props.cabecalho[1].id
        //console.log(props.filaRequest)
        var addFila = props.filaRequest
        addFila.push({cidade: cidade, tipo: "infinito"})
        props.setFilaRequest(addFila)
        props.atualiza(true)

        //desabilitaLimite.mutate({cidade: cidade})
    }

    const lidarAtualizaFluxo = async (fluxo) =>{
        setPopUpFluxo(false)
        setLoader(true)

        var cidade = props.cabecalho[1].id
        props.atualiza(true)

        alteraFluxo.mutate({cidade: cidade, tipo: fluxo})
    }

    const lidarNumeros = async (tipo, valor) =>{
        setDataNumber(prevState => ({
            ...prevState,
            [tipo]: valor
        }));
    }

    const lidarAtualizaLimite = async (tipo, valor,linha) =>{
        var arrayKey = Object.keys(linha)

        var addFila = props.filaRequest
        addFila.push({ tipo: "bloqueioUnitario", numero:valor, categoria:tipo, estrelas: arrayKey[0]})
        props.setFilaRequest(addFila)
        props.atualiza(true)
    }

    const lidarAtualizacao = async () =>{
        if( props.statusBotao === "bloqueio"){
            //setLoader(true)

            var addFila = props.filaRequest
            addFila.push({ tipo: "bloqueio", numero:dataNumber})
            props.setFilaRequest(addFila)
            props.atualiza(true)
           // atualizaLimite.mutate(dataNumber)
        }else{
            alert("Não é possível atualizar enquanto o botão de bloqueio não estiver ativado")
        }
    }

    const alteraBotao =  useMutation(postAtualizaBotao, {
        onSuccess: (e) => {
          queryClient.invalidateQueries()
          setLoader(false)
          props.atualiza(true)
        },
        onError: (e) => {
          setLoader(false)
          setConteudoPopUp(e.response.data)
          setPopUp(true)
        }
    });

    const alteraFluxo =  useMutation(postAtualizaFluxo, {
        onSuccess: (e) => {
          queryClient.invalidateQueries()
          setLoader(false)
          props.atualiza(true)
        },
        onError: (e) => {
          setLoader(false)
          setConteudoPopUp(e.response.data)
          setPopUp(true)
        }
    });

    const atualizaLimite =  useMutation(postAtualizaLimite, {
        onSuccess: (e) => {
          queryClient.invalidateQueries()
          setConteudoPopUp(e.data)
          setPopUp(true)
          setLoader(false)
          props.atualiza(true)
          props.setTimer(1)
        },
        onError: (e) => {
          setLoader(false)
          setConteudoPopUp(e.response.data)
          setPopUp(true)
        }
        
    });

    const desabilitaLimite =  useMutation(getDesabilitaLimite, {
        onSuccess: (e) => {
          queryClient.invalidateQueries()
          setConteudoPopUp(e.data)
          setPopUp(true)
          setLoader(false)
          props.atualiza(true)
          props.setTimer(1)
        },
        onError: (e) => {
          setLoader(false)
          setConteudoPopUp(e.response.data)
          console.log(e.response.data)
          setPopUp(true)
        }
        
    });

    useEffect(() => {
        if(props.data.length > 0){

            var dadosNumero = {}

            var keys0 = Object.keys(props.data[1])[1]
            var keys1 = Object.keys(props.data[2])[1]
            var keys2 = Object.keys(props.data[3])[1]
            var keys3 = Object.keys(props.data[4])[1]

            dadosNumero[keys0] = props.data[1][keys0].limite_solicitacao_simultanea
            dadosNumero[keys1] = props.data[2][keys1].limite_solicitacao_simultanea
            dadosNumero[keys2] = props.data[3][keys2].limite_solicitacao_simultanea
            dadosNumero[keys3] = props.data[4][keys3].limite_solicitacao_simultanea

            setDataNumber(dadosNumero)
        }

    }, [props.data])

    useEffect(() => {

    }, [props.timer])

    return (
        <div className='div-tabela-comando-limite'>
            {loader === true &&
              <div className='loader-comando'>
                  <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
              </div>
            }
            { barraProgresso != false &&
                <div className='loader'>
                <ProgressBar width={'200px'} completed={barraProgresso} />                
                </div>
            }
            <table className="table-tela-comando">
                <thead className="cabecalho-comando">         
                    <tr className="tr" style={{marginBottom: '15px'}}>
                        {props.cabecalho.length > 0 &&
                            props.cabecalho.map((linha, indice_linha)=>(
                            Object.values(linha).map(function(valor, indice, array){
                                if(indice === 0){ 
                                    return 
                                }else{
                                    return(    
                                        <th  key={indice} className="th">
                                            {valor}
                                        </th>  
                                    )   
                                }
                            })
                        ))}
                    </tr>
                </thead>
                <tbody className='tbody-tela-comando'>
                    {props.data.length > 0 && dataNumber.length !== 0 &&
                        props.data.map((linha, indice_linha)=>(
                            <tr  key={indice_linha} className="tr">
                                {
                                    Object.keys(linha).map(function(valor, indice, array){

                                        if(valor === "C" || valor === "P" || valor === "PP" || valor === "PPP"){
                                            switch(valor){
                                                case "C":
                                                    return(    
                                                        <th  key={indice} className="th">
                                                            <Icon className='icone-star'>star </Icon>
                                                        </th>  
                                                    )
                                                case "P":
                                                    return(    
                                                        <th  key={indice} className="th">
                                                            <Icon className='icone-star' >star </Icon>
                                                            <Icon className='icone-star'>star </Icon>
                                                        </th>  
                                                    )
                                                case "PP":
                                                    return(    
                                                        <th  key={indice} className="th">
                                                            <Icon className='icone-star'>star </Icon>
                                                            <Icon className='icone-star'>star </Icon>
                                                            <Icon className='icone-star'>star </Icon>
                                                        </th>  
                                                    )
                                                case "PPP":
                                                    return(    
                                                        <th  key={indice} className="th">
                                                            <Icon className='icone-star'>star </Icon>
                                                            <Icon className='icone-star'>star </Icon>
                                                            <Icon className='icone-star'>star </Icon>
                                                            <Icon className='icone-star'>star </Icon>
                                                        </th>  
                                                    )
                                            }
                                        }else if(valor === "botoes" ){
                                            return(    
                                                <th  key={indice} className="th">
                                                    <div className='botoes-mode'>
                                                        { props.timer !== 1 &&
                                                            <div>
                                                                <Icon className={props.statusBotao === "infinito"? "icon-status-ativo" :"icon-status-inativo"} onClick={()=>{lidarMudaMode("infinito")}}>all_inclusive </Icon>
                                                                <Icon className={props.statusBotao !== "infinito"? "icon-status-ativo" :"icon-status-inativo"} onClick={()=>{lidarMudaMode("bloqueio")}}>block</Icon>
                                                            </div>
                                                        }
                                                        { props.timer === 1 &&
                                                            <div>
                                                                <Icon className="icon-carregando" onClick={()=>{}}>all_inclusive </Icon>
                                                                <Icon className="icon-carregando" onClick={()=>{}}>block</Icon>
                                                            </div>
                                                        }
                                                        

                                                    </div>
                                                </th>  
                                            )   
                                        }
                                        else if(valor === "fluxo" ){
                                            return(    
                                                <th  key={indice} className="th">
                                                    <div className='botoes-mode'>
                                                        { props.timer !== 1 &&
                                                            <div>
                                                                <Icon className={props.statusFluxo === "normal"? "icon-status-ativo" :"icon-status-inativo"} onClick={()=>{lidarMudaFluxo("normal")}}>gpp_good</Icon>
                                                                <Icon className={props.statusFluxo === "atencao"? "icon-status-ativo" :"icon-status-inativo"} onClick={()=>{lidarMudaFluxo("atencao")}}>warning_amber </Icon>
                                                                <Icon className={props.statusFluxo === "critico"? "icon-status-ativo" :"icon-status-inativo"} onClick={()=>{lidarMudaFluxo("critico")}}>error</Icon>
                                                            </div>
                                                        }
                                                        { props.timer === 1 &&
                                                            <div>
                                                                <Icon className="icon-carregando" onClick={()=>{}}>gpp_good</Icon>
                                                                <Icon className="icon-carregando" onClick={()=>{}}>warning_amber</Icon>
                                                                <Icon className="icon-carregando" onClick={()=>{}}>error_outined</Icon>
                                                            </div>
                                                        }
                                                    </div>
                                                </th>  
                                            )   

                                        }
                                        else if(valor === "b" ){
                                            return(    
                                                <th  key={indice} className="th" style={{color: Cores.azulStars}}>
                                                    Limite
                                                </th>  
                                            )   

                                        }
                                        else if(valor === "f" ){
                                            return(    
                                                <th  key={indice} className="th" style={{color: Cores.azulStars}}>
                                                    Fluxo
                                                </th>  
                                            )   

                                        }
                                        else{
                                            return(    
                                                <th  key={indice} className="th">
                                                    <div className='botaoNUmero' style={{display:'flex', alignItems:'center'}}>
                                                        <Input tipo={"texto"} width={"50px"} name={indice} value={dataNumber[valor] } onChange={(e)=>{lidarNumeros(valor, e)}} placeholder="" />
                                                        <Icon style={{border:'1px solid rgb(107, 67, 128)', borderRadius:'8px'}} className="icon-carregando" onClick={()=>{lidarAtualizaLimite(valor,dataNumber[valor], linha)}}>autorenew </Icon>
                                                    </div>
                                                </th>  
                                            )  
                                        }
                                    })
                                }
                            </tr> 
                        ))
                    }
                </tbody>
            </table>
            <div className='botao-atualizar' style={{marginBottom: '15px'}}>
                    { props.timer !== 1 &&
                        <Button onClick={() => {lidarAtualizacao()}} width={"80px"} color={"white"} background={Cores.verde} border={'1px solid '+Cores.verde} text={"Atualizar"}/>
                    }
                    {props.timer === 1 &&
                        <Button onClick={() => {}} width={"80px"} color={"white"} background={Cores.laranja} border={'1px solid '+Cores.laranja} text={"Atualizar"}/>
                    }
            </div>

            <PopUp trigger={popUp} setTrigger={setPopUp}>
                {conteudoPopUp}
            </PopUp>
            <PopUpVazio trigger={popUpInfinito} setTrigger={setPopUpInfinito}>
                <div style={{padding:"20px"}}>
                    Deseja desabilitar o limite de solicitação?
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <Button onClick={() => {lidarDEsabilitarLimite()}} width={"80px"} color={"white"} background={Cores.verde} border={'1px solid '+Cores.verde} text={"Sim"}/>
                        <Button onClick={() => {setPopUpInfinito(false)}} width={"80px"} color={"white"} background={Cores.vermelho} border={'1px solid '+Cores.vermelho} text={"Não"}/>
                    </div>
                </div>
            </PopUpVazio>
            <PopUpVazioFluxo trigger={popUpFluxo} setTrigger={setPopUpFluxo}>
                <div style={{padding:"20px"}}>
                    Deseja alterar o fluxo desta cidade para {novoFluxo}?
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <Button onClick={() => {lidarAtualizaFluxo(novoFluxo)}} width={"80px"} color={"white"} background={Cores.verde} border={'1px solid '+Cores.verde} text={"Sim"}/>
                        <Button onClick={() => {setPopUpFluxo(false)}} width={"80px"} color={"white"} background={Cores.vermelho} border={'1px solid '+Cores.vermelho} text={"Não"}/>
                    </div>
                </div>
            </PopUpVazioFluxo>
        </div>
    );
};

export default TabelaLimite
