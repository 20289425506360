import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link, Route, Routes, useNavigate } from "react-router-dom";

import './navbar.scss'

import Icon from '@material-ui/core/Icon'
import { useAuth } from '../../hooks/useAuth'
import { ThemeCtx } from '../../utils/context/themeCtx'
import PopUp from '../popUp/PopUp'
import TelaComando from '../telaComando/TelaComando';

import logo from "./logo.png";

const NavBar = (prop) => {

  const optionsSelectGraficos = [
    { value: 'ga', label: 'Gráfico Arapongas'},
    { value: 'gl', label: 'Gráfico Londrina'},
    { value: 'gm', label: 'Gráfico Maringá'},
    { value: 'todos', label: 'Todos os Gráficos'}
  ]
  const [popUpTune, setPopUpTune] = useState(false);

  const navigate = useNavigate();

  const handleSelect = (e) =>{

    switch(e.target.value){
      case "ga":
       navigate('/grafico/arapongas/');
        break
      case "gl":
        navigate('/grafico/londrina/');
        break
      case "gm":
        navigate('/grafico/maringa/');
        break
      case "todos":
        navigate('/grafico/todos/');
        break
      case "escala":
        navigate('/escala');
        break
      case "conhecimento":
        navigate('/conhecimento');
        break
      case "valoresAdc":
        navigate('/valoresAdc');
        break
      case "regOcorrencia":
        navigate('/regOcorrencia');
        break
      case "settings":
        navigate('/settings');
        break
      case "gestaoDiaria":
        navigate('/gestaoDiaria');
        break
      case "logout":
        break
      default:
        break
    } 
  }
  const { darkMode, dispatch } = useContext(ThemeCtx)

  const { logout, user, acessoFinanceira } = useAuth()
  
  const [valueZoom, setValueZoom] = useState("100%")
  const [porcent, setPorcent] = useState(100);
  const prevPorcentRef = useRef();
  const prevPorcent = prevPorcentRef.current;

  useEffect(() => {
    prevPorcentRef.current = porcent;
    if(prevPorcent < porcent){
      prop.ChangeW(prop.valueW + 80)
      prop.ChangeH(prop.valueH + 40)
      prop.ChangeHW(prop.valueHW + 20)
      setValueZoom(porcent + "%")
      

    }

    if(prevPorcent > porcent){
      prop.ChangeW(prop.valueW - 80)
      prop.ChangeH(prop.valueH - 40)
      prop.ChangeHW(prop.valueHW - 20)
      setValueZoom(porcent + "%")

    }

  }, [porcent]);


  const addZoom = () =>{
    setPorcent(porcent + 10)
    
  }

  const removeZoom = () =>{
    setPorcent(porcent - 10)
  }
  return (
    <div className="navbar" style={{width: prop.width}}>
      <div className="wrapper">
        <div className="logo">
          <Link to="/home" style={{ textDecoration: "none" }}>
            <img src={logo} alt="testsss" width={150} height={60} />
          </Link>
        </div>
          <div className="center">
            {(prop.acesso == "adm" || prop.acesso == "suporte" ) &&
              <div className="graficosSide">
                <i className="material-icons">dashboard</i>
                <select className="selectSize" defaultValue={prop.select} onChange={handleSelect}>
                  <option value="default" disabled hidden> Gráficos Cidades </option>
                  {
                    optionsSelectGraficos.map((obj) => (
                      <option key={obj.value} value={obj.value}>{obj.label}</option>
                    ))
                  }
                </select>
              </div>
            }

            { (prop.acesso == "adm" || prop.acesso == "suporte" ) &&
              <div className="graficosSide">
                <i className="material-icons">assessment</i>
                <Link to="/ocorrencias" style={{ textDecoration: "none" }}>
                  <div className='text-links'>Ocorrências</div>
                </Link>
              </div>
            }

            { (prop.acesso == "adm" || prop.acesso == "suporte" ) &&
              <div className="graficosSide">
                <i className="material-icons">auto_stories</i>
                <Link to="/conhecimento" style={{ textDecoration: "none" }}>
                  <div className='text-links'>Conhecimento</div>
                </Link>
              </div>
            }

            { (prop.acesso == "adm" || prop.acesso == "suporte" ) &&
              <div className="graficosSide">
                <i className="material-icons">request_quote</i>
                <Link to="/valoresAdc" style={{ textDecoration: "none" }}>
                  <div className='text-links'>Valores Adicionais</div>
                </Link>
              </div>
            }
            
            { (prop.acesso == "adm" || prop.acesso == "suporte" ) &&
              <div className="graficosSide">
                <i className="material-icons">description</i>
                <Link to="/regOcorrencia" style={{ textDecoration: "none" }}>
                  <div className='text-links'>Registros</div>
                </Link>
              </div>
            }
            { (prop.acesso == "adm" || prop.acesso == "suporte" ) &&
              <div className="graficosSide">
                <i className="material-icons">list_alt</i>
                <Link to="/gestaoDiaria" style={{ textDecoration: "none" }}>
                  <div className='text-links'>Gestão Diária</div>
                </Link>
              </div>
            }

            { (prop.acesso == "adm" || prop.acesso == "suporte" ) &&
              <div className="graficosSide">
              <i className="material-icons">swap_horiz</i>
                <Link to="/retornos" style={{ textDecoration: "none" }}>
                  <div className='text-links'>Retornos</div>
                </Link>
              </div>
            }

            { (prop.acesso == "adm" || prop.acesso == "suporte" ) &&
              <div className="graficosSide">
              <i className="material-icons">moped</i>
                <Link to="/financeiraMotoristas" style={{ textDecoration: "none" }}>
                  <div className='text-links'>Convênio</div>
                </Link>
              </div>
            }

            { (prop.acesso == "adm" || prop.acesso == "suporte" ) &&
              <div className="graficosSide">
              <i className="material-icons">local_shipping</i>
                <Link to="/grandesEncomendas" style={{ textDecoration: "none" }}>
                  <div className='text-links'>Taon Cargo</div>
                </Link>
              </div>
            }
          </div>
          <div className="items">
            {(prop.acesso == "adm" || prop.acesso == "suporte" ) &&
              <div className="item" onClick={()=>{setPopUpTune(true)}}>
                <Icon className="icon">tune</Icon>
              </div>
            }
            <div className="item">
              { prop.invisivel == true &&
                <div className='icones-zoom'>
                  <Icon className="icon-add" onClick={() => {addZoom()}} > add_circle </Icon>
                    <input className="icon-input" disabled={true} value={valueZoom}></input>
                  <Icon className="icon-remove" onClick={() => {removeZoom()}}> remove_circle</Icon>
                </div>
              }
            </div>
          <div className="item">
            <img className="avatar" src={user?.picture} alt="avatar" />
          </div>
          <div className="item" onClick={()=>{navigate('/settings')}}>
            <Icon className="icon">settings</Icon>
          </div>
          <div className="item" onClick={logout}>
            <Icon className="icon">logout</Icon>
          </div>
        </div>



        <PopUp trigger={popUpTune} setTrigger={setPopUpTune}>
          <TelaComando />
        </PopUp>
      </div>
    </div>
  )
}

export default NavBar
