import React from 'react'

import { createContext, useContext, useMemo } from 'react'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from './useLocalStorage'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null)
  //const [userSettings, setuserSettings] = useLocalStorage('userSettings', null)
  //const [userData, setUserData] = useLocalStorage("userData", null);

  const navigate = useNavigate()

  const login = async (data) => {
    const accounts = process.env.REACT_APP_USUARIOS_SUPORTE;
    const accountsAdm= process.env.REACT_APP_USUARIOS_ADM;
    const accountsGrandesEncomendas = process.env.REACT_APP_USUARIOS_GRANDES_ENCOMENDAS;

    let userData = jwt_decode(data.credential);
    var permissoes = {"suporte": accounts.includes(userData.email), "adm": accountsAdm.includes(userData.email), "entregas": accountsGrandesEncomendas.includes(userData.email)}
    
    userData["permissao"] = permissoes
    var verifica = Object.values(permissoes)

    //Verifica permissão para suporte
    if (verifica.includes(true)) {
      setUser(userData);
      navigate("/home", { replace: true });
    }else {
      alert("Não autorizado");
    }
  }

  const logout = () => {
    setUser(null)
    navigate('/', { replace: true })
  }

  const value = useMemo(
    () => ({
      user,
      //userData,
      login,
      logout
    }),
    [user],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
