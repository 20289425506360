const estabsList = [
  "Al Taglio Delivery",
  "Aranda Moto Pecas",
  "Auto Posto Shangrila",
  "Açaí Truck",
  "Bartô Pizza Co",
  "Bora Churrasquear",
  "Casa das Motos",
  "Casas Ajita - Centro",
  "Clinica das Motos",
  "D'ESfihas",
  "Dona Francisca Rest",
  "DrogaMais Comtour",
  "Farmacia do Roberto",
  "Gordo Moto Peças",
  "Green Acai",
  "Hai Chiken",
  "Iced Box",
  "Imperio Moto Pecas e Oficina",
  "Insano Burger",
  "Lai Chi",
  "MGV Cell",
  "Marcelo's Dog",
  "Mega Life",
  "Minas de Ouro Maringa",
  "Minas de Ouro Paes Leme",
  "Minas de Ouro Quintino",
  "Niko Burger",
  "Pastel do Fernando",
  "Plein Burger",
  "Pneusmais",
  "Poderoso Chefão",
  "Posto Petroaras LTDA",
  "Puro Sabor",
  "Rango do Brow",
  "Rei do Torresmo",
  "Restaurante Choppão",
  "Restaurante Minato",
  "Sabores da Vovó",
  "Seu Japa",
  "Ta Pronto Vegan",
  "Taon Delivery",
  "Todo Biscoito",
  "Wings Burger",
  "Zaatar",
];

const motocasList = [
  "Abimael Jose De Oliveira",
  "Adalto Freire",
  "Adriano Brandao da Silva",
  "Adriano Cordeiro Costa",
  "Adriano Dos Santos Pontes",
  "Adriano Silva Oliveira",
  "Adriano pedroso",
  "Agata Parente Rocha",
  "Agnaldo Cruz Da Silva De Quadros",
  "Ailton Igor Da Silva Almeida",
  "Alan Felipe da Silva",
  "Alan Junior Amorim Simoni",
  "Alessandro Dos Santos Souza",
  "Alex Junior Rodrigues",
  "Alex Pereira Luz",
  "Alex Sander Gabriel Cavalcante",
  "Alex Sandro De Oliveira",
  "Alex Sandro Mendes Goncalves",
  "Alexandre Maciel Varotto",
  "Alexandro Soares Dos Santos",
  "Alisson Alan Ferminiano",
  "Alisson Luiz Mularis",
  "Allan Luiz Baptista",
  "Altino Guilherme Pereira",
  "Amarildo Jose Ribeiro",
  "Anderson Camargo De Jesus",
  "Anderson Fernandes",
  "Anderson Sede Ferreira",
  "Andre Cordeiro Martins",
  "Andre Felipe Gomes Da Silva",
  "Andre Henrique Silva Nunes",
  "Antonio Carlos da Silva 535",
  "Antonio jozivane sena silva",
  "Aparecida Sabino De Souza",
  "Ariadny da Silva Venancio",
  "Arlen Carlos Dos Santos",
  "Arnaldo De Oliveira Carlota",
  "Axel Willian Romanholi",
  "Brian Alexander Rojas Ballestero",
  "Bruno Cesar Ferreira Rosa",
  "Bruno Henrique Souza",
  "Bruno Mota Laguna",
  "Bruno Pereira",
  "Caio Cesar Landin Da Silva",
  "Carlos Eduardo Marques Dias",
  "Carlos Eduardo de Oliveira",
  "Carlos Vinicius Gimenes",
  "Cicero Cristiano da Silva brito",
  "Claudemir Dias Junior",
  "Claudio Cavalcante",
  "Clesio Cunha da Silva",
  "Clovis Marques Da Silva",
  "Cosmo Eduardo Francisco",
  "Daniel Bernardino",
  "Daniel Coelho Dos Santos",
  "Daniel Jose Da Silva",
  "Daniele Miranda Guizi Mathias",
  "Danilo Ferreira De Paulo",
  "Danilo Rodrigues",
  "David Junior Mendes Azevedo",
  "Demetrio Martinez Correa",
  "Diego Leonardo Claudino Botelho",
  "Diego Piornedo",
  "Diego Ribeiro",
  "Diony Ivan Melo",
  "Douglas Martins Da Silva",
  "Dyego Aparecido De Almeida",
  "Edinilson Mariano",
  "Edison Aguiar Paixao",
  "Edson Henrique Alves Do Carmo",
  "Edson Shinji Tacao",
  "Eduardo Felipe Augusto",
  "Eduardo Nunes",
  "Eduardo Yoshio Aoki",
  "Elexandre Gabriel Dos Santos",
  "Elias Justino de Oliveira Junior",
  "Elio Pascual Lobaina Cobas",
  "Elmar Finck",
  "Emerson Quintilhano Rodrigues",
  "Enderson Enrique Bermudez Gutierrez",
  "Enes Jose Barbosa Lemes",
  "Escott William Martins",
  "Everson Vinicius Martins Monteiro",
  "Everton Cardoso de Andrade",
  "Everton Verissimo Dos Santos",
  "Fabiano Miranda",
  "Fabio Da Silva Fulgencio",
  "Fabio Junior Rodrigues Dutra",
  "Fabio Marcelo Sousa Da Silva",
  "Felipe Da Silva Brito",
  "Felipe Dos Santos Lima",
  "Felipe Emanuel Alves",
  "Fernando Eduardo Pinheiro",
  "Flavio Robson Barreto Vitalino",
  "Frank Roger Da Silva",
  "Gabriel Augusto Oliveira de Jesus",
  "Gabriel Florencio Pereira",
  "Gabriel Marcos Vieira",
  "Gabriel Matheus Guandeline",
  "Gabriel Silva Saraiva",
  "Gabriel Yudi Era",
  "George Lucas Ferreira",
  "Giancarlo Magalhaes Ariello",
  "Greiciele Moreira Da Silva Carneiro",
  "Guilherme Henrique Natal",
  "Guilherme Modesto Costa",
  "Gustavo Henrique Soares De Oliveira",
  "Gustavo Rodrigues Dallo",
  "Hallan Gabriel Zanatta Cerniquiari",
  "Helio Vinicius Alpha De Carvalho E Silva",
  "Henrique Dias Fernandes",
  "Henrique Soares Da Silva",
  "Henrique Vieira Mereth",
  "Hermes Rodrigo Santos",
  "Hiago Gabriel Rosa",
  "Hudson Barboza Munhoz",
  "Isaac Ferreira",
  "Israel da Silva Lameu",
  "Ivan Dos Santos Vieira",
  "Jackson Luiz Merchol",
  "Jair Ticiani Da Rocha Junior",
  "Jarlan Nascimento Andrade",
  "Jean Costa Ribeiro",
  "Jesse Pinheiro",
  "Jhon Eliton Carneiro de Paula",
  "Jhon Steban Giraldo Pedraza",
  "Joao Eduardo De Azevedo",
  "Joao Felipe Barroso",
  "Joao Guilherme Alves De Sousa",
  "Joao Pedro Carvalho Brancaliao",
  "Joao Pedro Moreira De Jesus",
  "Joao Victor Dos Santos Batista",
  "Joao Vitor Da Luz",
  "Joao Vitor Soares Da Silva",
  "Joel Akira Reis Ujissato",
  "Jonas Marques Pereira",
  "Jonathas Alexandre Pereira",
  "Juliandro Aparecido Vilela De Arruda",
  "Juliano Antunes Teixeira",
  "Julio Cesar de Souza Biehl",
  "Julliano Cesar Leao",
  "Kaio Marcelo Dos Santos Dutra",
  "Kalel Da Silva Santana",
  "Kauan Eduardo Da Silva",
  "Kevin Nicolas Soares da Silva",
  "Leandro Ferraz",
  "Leandro Renato De Souza",
  "Leandro Rezende Ferreira Da Cruz (Não ativar)",
  "Leonardo Dos Santos Gontijo",
  "Leonardo Rogerio Pereira De Matos",
  "Leonardo moreira da Silva",
  "Liliane Benedita De Souza",
  "Lincoln Lopes",
  "Lua Marinho Ferreira",
  "Lucas Correia Barbosa",
  "Lucas De Avila Soares",
  "Lucas De Castro Lauria",
  "Lucas Henrique Santos Ferreira",
  "Lucas Henrique Vieira",
  "Lucas Luan Angelo",
  "Lucas Willian De Souza",
  "Luciano Alves De Moraes",
  "Luian Carlos",
  "Luis Fernando Diaz",
  "Luis Fernando Dos Santos 934",
  "Luis Fernando Zanatta De Matos",
  "Luis Gustavo De Gois Filho",
  "Luis henrique da silva santos",
  "Luiz Antonio Apolinario",
  "Luiz Eduardo Domingues Gazoli",
  "Luiz Eduardo Silva Neves",
  "Luiz Fernando Da Silva",
  "Luiz Henrique Da Silva",
  "Luiz Henrique Da Silva Sena",
  "Luiz Otavio Medeiros",
  "Maicon Fontis Dos Santos",
  "Maikon De Araujo",
  "Marcelo Augusto Mendes",
  "Marcelo Bonifacio De Oliveira",
  "Marcelo Henrique De Oliveira",
  "Marcelo Kiyoshi Tsuruda Takehana",
  "Marcelo Steinel",
  "Marcio Rogerio da Silva",
  "Marcos Rogerio da Cruz",
  "Mario Czigler",
  "Marlon Felipe Porto De Souza",
  "Marlus Marvel Nascimento",
  "Mateus Rodrigues Do Vale",
  "Matheus Henrique Costa Garcia",
  "Matheus Henrique Pereira Dos Santos",
  "Matheus Lucas Barbosa",
  "Maura Eliza Tashima Costa",
  "Mauricio Eduardo Severino",
  "Mauricio Junior Pereira Lopes",
  "Michel Da Silva",
  "Muller Dos Santos Carneiro",
  "Murilo Nascimento Dos Santos",
  "Nerilto Aparecido Claro",
  "Nilson Henrique Tavares Dias",
  "Nilton Martins Junior",
  "Otilio Calixto de Oliveira",
  "Otoniel Silva Moura",
  "Patrick Giovanny Santos Carvalho",
  "Paulo Eduardo Correa",
  "Paulo Henrique Stante",
  "Paulo Jose Dos Santos",
  "Paulo Renato Moreira Da Silva",
  "Pedro Henrique Goncalves Da Silva",
  "Pedro Paulo Rolim",
  "Rafael Fernandes de Faria",
  "Rafael Luiz Mathias",
  "Rafael Rodrigues",
  "Rafael Victor Muniz Da Silva",
  "Raphael Augusto Souza",
  "Reginaldo Luis Neri",
  "Reinaldo de Souza Santos",
  "Renan Abra Pereira",
  "Renan Da Silva Lopes",
  "Rildo Garcia",
  "Roberto Barbosa Da Silva",
  "Robertson Carlos Da Silva",
  "Robson Luciano Dos Santos",
  "Robson Yuji Utiamada",
  "Rodolpho Henrique Assumpcao Silva",
  "Rodrigo De Souza Santos",
  "Rodrigo Pereira Da Silva",
  "Rogerio Aparecido Shinaide",
  "Romulo Serafim Ribeiro Aguilar",
  "Ruan Carlos Augustinho Soares",
  "Ruan Rodrigues Pinheiro",
  "Ryan Ferreira Dos Santos",
  "Samuel Custodio Marques",
  "Sidney Henrique Lima Novais",
  "Thalisson Felipe Lima Ferreira",
  "Thiago Soares",
  "Tiago Ferreira",
  "Tiago Olivero Puga",
  "Timoteo Jose Dos Reis",
  "Vagner Campitelli",
  "Valdecir Cavalcante",
  "Valdir Andre De Souza",
  "Valmir Caetano",
  "Victor Hugo Do Vale Duarte",
  "Victor Lima De Magalhaes",
  "Vinicius Betran Camargo de Souza",
  "Vinicius De Almeida Santos",
  "Vinicius Gabriel Fabri Beraldo",
  "Vinicius Gabriel Feijo",
  "Vinicius Marinho",
  "Vinicius Roberto Barros",
  "Vitor Gabriel Ribeiro Da Costa",
  "Vitor Hugo Gonsalves Alves",
  "Vitor Hugo Rodrigues",
  "Vitor Monteiro Neri",
  "Vitoria Alessandra Brandao Do Nascimento",
  "Vivian Liz Pelegrinelli",
  "Welingthon Fernandes Marques",
  "Wellington Bruno Pereira Ramos",
  "Wellington Da Silva Tobias",
  "Wellinton Luis De Araujo",
  "Welton Jorge Goncalves",
  "Willian Aparecido Verri Fermino",
  "Willian Garcia Gomes",
  "Wilson Henrick Aguiar",
  "Wisder Rodrigues Faria",
  "Youssef Sarkis Younan",
];

export { estabsList, motocasList };
