import './list.scss'
import React, { useEffect,useState, useMemo} from 'react'

const List = (props) =>{ 
    useEffect(() => {

    }, [props.itens]);

    const data = useMemo(() => {
        return props.itens
    }, [props.itens]);

    return ( 
        <div className='tabela-entregas'> 
            <div className='titulo-entregas'>{props.titulo}</div>
            <table className="corpo-tabela">
                <thead className="cabeca">
                    <tr className="tr">
                        {
                            props.colunas.map((item, numero) => { 
                                if(numero == 0){
                                    return (<th style={{width:props.largura/3}} className="th" key={item.chave}>{item.valor}</th>)
                                }else{
                                    return (<th className="th" key={item.chave}>{item.valor}</th>)
                                }
                            })
                        }
                    </tr>
                </thead>
                <tbody className='itens' style={{maxHeight:props.altura}}> 
                {data.length > 0 &&
                    data.map((linha, indice) => (
                        <tr className="tr"  key={indice}> 
                            {Object.values(linha).map((item, indexLinha) => {
                                    if(indexLinha == 0){
                                        if( linha[linha.length-1] >= props.limite){
                                            return( <td style={{width:props.largura/3,color: "#fff", backgroundColor:"orangered"}} className="td" key={indexLinha}>{item} </td>)
                                        }else{
                                            return( <td style={{width:props.largura/3}} className="td" key={indexLinha}>{item} </td>)
                                        }
                                    }else{
                                        if(linha[linha.length-1] >= props.limite){
                                            return( <td style={{ color: "#fff",backgroundColor:"orangered"}}className="td" key={indexLinha}>{item} </td>)
                                        }else{
                                            return( <td className="td" key={indexLinha}>{item} </td>)
                                        }
                                    }
                                }
                            )}
                        </tr>
                    ))
                }        
                </tbody>
            </table>
        </div>
    );
}

export default List