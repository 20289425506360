import React from 'react';
import { Box, Table, TableBody, TableCell ,TableHead, TableContainer, TableFooter, TableRow, TablePagination, Paper } from '@material-ui/core';

function TablePanel(props) {
  const { children, value, index, rowCount, rowsPerPage, page, onPageChange, onRowsPerPageChange, agrupaPorMotorista = true, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {agrupaPorMotorista?
            <div style={{ display: 'flex', height: 'auto' }}>
              <Box p={3} padding={0} marginTop={3} style={{ flex: 1 }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TituloTabela />

                    <TableBody>
                      {children.filter((_, i) => i % 2 === 0)}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>

              <Box p={3} padding={0} marginTop={3} marginLeft={3} style={{ flex: 1 }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TituloTabela />

                    <TableBody>
                      {children.filter((_, i) => i % 2 !== 0)}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </div>

            :
            <div style={{ display: 'flex', height: 'auto' }}>
              <Box p={3} style={{ flex: 1 }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TituloTabelaRetornados />

                    <TableBody>
                      {children}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </div>
            
          }

          <Table style={{ display: 'flex' }}>
            <TableFooter style={{ margin: 'auto', display: 'flex', flexWrap: 'wrap' }}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 40, { label: 'All', value: -1 }]}
                  count={rowCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                />
              </TableRow>
            </TableFooter>
          </Table>


        </>
      )}
    </div>
  );
}

const TituloTabela = ()=>{
    return <TableHead>
      <TableRow className='table-title'>
        <TableCell><b>Motorista</b></TableCell>
        <TableCell />
        <TableCell><b>Finalizou em</b></TableCell>
        <TableCell><b>Qnt. Empresas</b></TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
}

const TituloTabelaRetornados = ()=>{
  return <>
    <TableHead>
      <TableRow className='table-title'>
        <TableCell><b>Nº OS</b></TableCell>
        <TableCell><b>Nº Pedido</b></TableCell>
        <TableCell><b>Nº Controle</b></TableCell>
        <TableCell><b>Parada</b></TableCell>
        <TableCell><b>Motorista</b></TableCell>
        <TableCell size='small'/>
        <TableCell align='center'><b>Retornado em</b></TableCell>
        <TableCell align='center'><b>Por</b></TableCell>
        <TableCell />
        <TableCell />
      </TableRow>
    </TableHead>
  </>
}

export default TablePanel;
