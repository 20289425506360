import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@mui/material';
import { TableCell, TableRow, Checkbox } from '@mui/material';
import { Button, PhoneMessageButton, CopyButton } from '../../components/button/Button'
import TabPanel from '../../components/tabPanel/TabPanel'

import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import ReplyAllOutlinedIcon from '@mui/icons-material/ReplyAllOutlined';
import CachedIcon from '@mui/icons-material/Cached';

import { useOutletContext } from "react-router-dom";
import PopUpConfirmacao from '../../components/popUpVazio/PopUpVazio';
import PopUpAlerta from '../../components/popUp/PopUp'
import PopUpRetornosMotorista from '../../components/popUpVazio/PopUpRetornosMotorista'
import { getRetornos } from '../../api/retornos';
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'

import { atualizaRetornoMachine, atualizaStatusRetorno, enviaMsgMotoristas } from '../../api/retornos';

import "./retornos.scss"
import SportsMotorsportsOutlined from '@mui/icons-material/SportsMotorsportsOutlined';

const Retornos = () => {
  const [mostrarHoje, setMostrarHoje] = useState(false);
  const [atrasados, setAtrasados] = useState(true)
  const permissao = useOutletContext();
  
  const [value, setValue] = useState(0);
  const [retornosAbertos, setRetornosAbertos] = useState([]);
  const [retornosRetornados, setRetornosRetornados] = useState([]);

  const [conteudoPopUp, setConteudoPopUp] = useState("");
  const [popUpAlerta, setPopUpAlerta] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageRetornados, setPageRetornados] = React.useState(0);

  const [popUpMotorista, setPopUpMotorista] = useState(false);
  const [conteudoPopUpMotorista, setConteudoPopUpMotorista] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePageRetornados = (event, newPage) =>{
    setPageRetornados(newPage)
  }

  const handleChangeRowsPerPage = (
    event
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
  useEffect(() => {
    async function fetchData() {
      try {
        atualizaRetornos(mostrarHoje, atrasados, setRetornosAbertos, setRetornosRetornados)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  
    fetchData();
  }, [atrasados, mostrarHoje]);
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const atualizaRetornosFromMachine = async () => {
    try{
      const novosRetornosAbertos = await Promise.all(
        Object.values(retornosAbertos).map((retornosMotorista, index)=>{
          retornosMotorista.filter(retorno => 
            !retorno.retorno.finalizado || 
            !retorno.retorno.motorista || 
            !retorno.retorno.telefone_motorista || 
            !retorno.retorno.nomeEmpresa
          )
          .map(async (retorno) => {
            const novoRetorno = await atualizaRetornoMachine(retorno.retorno._id, retorno.retorno.os);
          })
        }
      ));
    }catch (error) {
      console.error(`Erro ao atualizar retornos em aberto: ${error} `);
    }

    try{
      const novosRetornosRetornados = await Promise.all(
        retornosRetornados.filter(retorno => 
            !retorno.finalizado || 
            !retorno.motorista || 
            !retorno.telefone_motorista || 
            !retorno.nomeEmpresa
          )
          .map(async (retorno) => {
            const novoRetorno = await atualizaRetornoMachine(retorno._id, retorno.os);
          })
      );
    }catch (error) {
      console.error(`Erro ao atualizar retornos em retornados: ${error} `);
    }

    try{
      atualizaRetornos(mostrarHoje, atrasados, setRetornosAbertos, setRetornosRetornados);
    } catch(error){
      console.error(`Erro ao atualizar retornos nas variáveis `, error)
    }
  };

  return (
    <div className="main">
      <div className="retornosCont">
        <Navbar select={"default"} selectList={"default"} selectOP={"default"} acesso={permissao}/>
        <Sidebar />
        <div className="content">
          <div className="tela-principal-grandes-encomendas">
            <div className="titulo-grandes-entregas">
              <AssignmentReturnIcon style={{ width: '35px', height: 'auto' }} />
              <div className="texto-titulo-Grandes-encomendas">
                Controle de Retornos
              </div>

              <div className="tabs-container">
                <Tabs value={value} onChange={handleChange} aria-label="Retornos">
                  <Tab label="Abertos" />
                  <Tab label="Retornados" />
                </Tabs>
              </div>
            </div>

            <div className='abraca-conteudo-interno'>
              <div style={{ display: 'flex' }}>
                {value==0?
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                    <Checkbox 
                      type="checkbox" 
                      name="atrasado" 
                      checked={atrasados} 
                      onChange={() => setAtrasados(!atrasados)} 
                    />
                    <h3 style={{ margin: '0 0 0 8px' }}>Atrasados</h3> 
                  </div>
                  :
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                    <Checkbox 
                      type="checkbox" 
                      name="mostrarHoje" 
                      checked={mostrarHoje} 
                      onChange={() => setMostrarHoje(!mostrarHoje)} 
                    />
                    <h3 style={{ margin: '0 0 0 8px' }}>Mostrar somente hoje</h3>
                  </div>
                }

                <div style={{marginLeft: 'auto'}}>
                  <Button
                    onClick={ atualizaRetornosFromMachine }
                    >
                    <CachedIcon />
                  </Button>
                </div>
              </div>
            </div>

            <TabPanel 
              value={value} 
              index={0} 
              rowCount={Object.keys(retornosAbertos).length}
              rowsPerPage={rowsPerPage} 
              page={page} 
              onPageChange={handleChangePage} 
              onRowsPerPageChange={handleChangeRowsPerPage}
            >

              {Object.values(retornosAbertos).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((retornoMotorista, index) => ( 
                <LinhaTabela 
                    key={index}
                    retorno={retornoMotorista} 
                    setPopUpMotorista={setPopUpMotorista}
                    setConteudoPopUpMotorista={setConteudoPopUpMotorista}
                />
              ))}
            </TabPanel>

            
            <div className='abraca-conteudo-interno'>
              <TabPanel 
                value={value} 
                index={1} 
                rowCount={Object.keys(retornosRetornados).length}
                rowsPerPage={rowsPerPage} 
                page={pageRetornados} 
                onPageChange={handleChangePageRetornados} 
                onRowsPerPageChange={handleChangeRowsPerPage}
                agrupaPorMotorista={false}
              >
                {retornosRetornados?.slice(pageRetornados * rowsPerPage, pageRetornados * rowsPerPage + rowsPerPage).map((retorno, index) => ( 
                  <LinhaTabela  
                    key={index} 
                    retorno={retorno} 
                    setRetornosAbertos={setRetornosAbertos} 
                    setRetornosRetornados={setRetornosRetornados} 
                    agrupaPorMotorista={false} 
                    atualizaRetornos={()=>atualizaRetornos(mostrarHoje, atrasados, setRetornosAbertos, setRetornosRetornados)}
                  />
                ))}
              </TabPanel>
            </div>
            

            <PopUpAlerta trigger={popUpAlerta} setTrigger={setPopUpAlerta}>
              {
                <div>
                  {conteudoPopUp}
                </div>
              }
            </PopUpAlerta>

            <PopUpRetornosMotorista 
              trigger={popUpMotorista}
              setTrigger={setPopUpMotorista}
              retornos={conteudoPopUpMotorista}
              atualizaRetornos={()=>atualizaRetornos(mostrarHoje, atrasados, setRetornosAbertos, setRetornosRetornados)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const LinhaTabela = ({ retorno, setPopUpMotorista, setConteudoPopUpMotorista, agrupaPorMotorista=true, atualizaRetornos=() => {} }) => {
  const [popUpConfirmacao, setPopUpConfirmacao] = useState(false);

  function handleVisualizaMotorista(){
    setPopUpMotorista(true)
    setConteudoPopUpMotorista(retorno);
  }

  function contaEmpresas(){
    const uniqueIdEmpresas = retorno.reduce((acc, item) => {
        acc.add(item.retorno.idEmpresa);
        return acc;
    }, new Set());
    return uniqueIdEmpresas.size;
  }

  function encontraDataMaisAntiga(){   
    let oldestDate = new Date('9999-12-31T23:59:59.999Z');
    const specificDate = new Date('2020-01-01T00:00:00.000Z');

    
    retorno.forEach(retornoObj => {
      const finalizadoDate = new Date(retornoObj.retorno.finalizado);
    
      if (finalizadoDate < oldestDate && finalizadoDate>specificDate) {
        oldestDate = finalizadoDate;
      }
      
    });
    
    if(!oldestDate || oldestDate === new Date('9999-12-31T23:59:59.999Z')){
      return "-";
    }

    const date = new Date(oldestDate).toLocaleDateString('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'}) + " " +
          new Date(oldestDate).toLocaleTimeString('pt-BR')

    return date;
  }

  function getAtualizadoPorIcon(atualizadoPor){
    {
      switch(atualizadoPor) {
        case 'suporte':
          return <SupportAgentOutlinedIcon />;
        case 'motorista':
          return <SportsMotorsportsOutlined />;
        case 'restaurante':
          return <StorefrontOutlinedIcon />;
        default:
          return '-';
      }
    }
  }
  
  async function onSetAguardando(id, setPopUpConfirmacao){
    await atualizaStatusRetorno(id, "restaurante", "Aguardando");
    atualizaRetornos();
    setPopUpConfirmacao(false);
  }

  const ReabrirRetornoButton=({ id })=>{
    return(
      <>
        <Button onClick={()=>setPopUpConfirmacao(true)} border= '0px' background= 'none'>
            <ReplyAllOutlinedIcon />
        </Button>
        <PopUpConfirmacao trigger={popUpConfirmacao} setTrigger={setPopUpConfirmacao} light={true}>
            {
              <div style={{ padding: "15px" }}>
                <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                  {"Você deseja reabrir este retorno?"}
                </div>
                <div>
                  < Button
                    text='Sim'
                    width='120px'
                    borderRadius='8px'
                    border='black'
                    background='rgb(66, 175, 60)'
                    color='white'
                    onClick={() => { onSetAguardando(id, setPopUpConfirmacao) }}
                  />
                  < Button
                    text='Não'
                    width='120px'
                    borderRadius='8px'
                    border='black'
                    background='#c84333'
                    color='white'
                    onClick={() => { setPopUpConfirmacao(false) }}
                  />
                </div>

              </div>
            }
          </PopUpConfirmacao>
      </>
    ) 
  }

  return(<>
    {agrupaPorMotorista?
    <TableRow key={retorno[0].retorno.motorista}>
      <TableCell>{retorno[0].retorno.motorista}</TableCell>
      <TableCell> 
        <PhoneMessageButton link={retorno[0].retorno.telefone_motorista}/>
      </TableCell>
      <TableCell>{encontraDataMaisAntiga()}</TableCell>
      <TableCell>{contaEmpresas()}</TableCell>
      <TableCell>
        < Button
          text='Visualizar'
          width='120px' 
          borderRadius='0px' 
          border='black' 
          background='green' 
          color='white'
          onClick={handleVisualizaMotorista}
        />
      </TableCell>
    </TableRow>
    :
    <TableRow className='table-title'>
        <TableCell>{retorno.os} <CopyButton link={retorno.os} /></TableCell>
        <TableCell className='marieta'>{retorno.numero_pedido} </TableCell>
        <TableCell className='marieta'>{retorno.numeroControle} </TableCell>
        <TableCell className='marieta'>{retorno.parada}</TableCell>
        <TableCell className='marieta'>{retorno.motorista}</TableCell>
        <TableCell className='marieta'> <PhoneMessageButton link={retorno.telefone_motorista}/></TableCell>
        <TableCell className='marieta' align='center'>
          {retorno.finalizado ? 
            <>
              {
              new Date(retorno.finalizado).toLocaleDateString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })} {new Date(retorno.finalizado).toLocaleTimeString('pt-BR')}
            </>
            : "-"
          }
        </TableCell>
        <TableCell style={{ textTransform: 'capitalize' }}>
          {getAtualizadoPorIcon(retorno.atualizadoPor)} 
        </TableCell>
        <TableCell>
          {retorno.retorno.dinheiro ?
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LocalAtmOutlinedIcon style={{ marginRight: '5px' }} /> R${retorno.retorno.valorDinheiro}
            </div>
            : null}
          {retorno.retorno.maquininha ?
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CreditCardOutlinedIcon style={{ marginRight: '5px' }} /> R${retorno.retorno.valorMaquininha} ID: {retorno.retorno.idMaquininha}
            </div>
            : null}
          {retorno.retorno.comandaAssinada ?
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ReceiptLongOutlinedIcon style={{ marginRight: '5px' }} />
            </div>
            : null}
        </TableCell>

        <TableCell className='marieta'>
            <ReabrirRetornoButton id={retorno._id} />
        </TableCell>
    </TableRow>
    }
  </>); 
}

const atualizaRetornos = async (mostrarHoje, atrasados, setRetornosAbertos, setRetornosRetornados) => {
  try {
    const retornos = await getRetornos();
    
    let retornosAbertosFiltrados = retornos?.filter(retorno => retorno.status === 'Aguardando');
    let retornosRetornadosFiltrados = retornos?.filter(retorno => retorno.status === 'Retornou');

    if (atrasados) {
      let today = new Date();
      today.setHours(today.getHours() - 1);
      today.setMinutes(today.getMinutes() - 30);

      retornosAbertosFiltrados = retornosAbertosFiltrados?.filter(retorno =>
        new Date(retorno?.finalizado).getTime() < today.getTime()
      );
    }    

    if (mostrarHoje) {
      let today = new Date();
      let todayString = today.toISOString().split('T')[0];
      retornosRetornadosFiltrados = retornosRetornadosFiltrados.filter(retorno => 
        retorno?.finalizado?.startsWith(todayString)
      );
    }
    
    agrupaPorMotorista(retornosAbertosFiltrados, setRetornosAbertos, atrasados);
    setRetornosRetornados(retornosRetornadosFiltrados);

  } catch (error) {
    console.error("Erro ao atualizar retornos:", error);
  }
}

const agrupaPorMotorista = (list, setList, atrasados = false)=>{
  const groupedByMotorista = list.reduce((acc, retorno) => {
    if (!acc[retorno.motorista]) {
      acc[retorno.motorista] = [];
    }
    acc[retorno.motorista].push({
      retorno
    });
    return acc;
  }, {});
  
  setList(groupedByMotorista);

  if (atrasados){
    enviaMsgMotoristas(groupedByMotorista);
  }
}

export default Retornos;
