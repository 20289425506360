(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("uplot"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "uplot"], factory);
	else if(typeof exports === 'object')
		exports["UplotReact"] = factory(require("react"), require("uplot"));
	else
		root["UplotReact"] = factory(root["React"], root["uPlot"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_uplot__) {
return 