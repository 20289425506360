import axios from 'axios'

/* SUMÁRIO
1- procurarEmAndamento: lista as entregas em Andamento
2- procurarAgendadas: lista as entregas agendadas
*/

const baseURL = '/entregas'

const entregasApi = axios.create({
baseURL: baseURL,
})

export const procurarEmAndamento = async ({ queryKey }) => {
    //lista as entregas em Andamento

    const res = await entregasApi.get('/procurarEmAndamento')
    return res.data.data
}

export const procurarAgendadas = async ({ queryKey }) => {
    //lista as entregas agendadas

    const res = await entregasApi.get('/procurarAgendadas')
    if(res["status"] === 203){
        var data = {"ga": [] , "gl": [], "gm": []}
        return data
    }
    
    var data = {"ga": res.data.agendadas[0].arapongas , "gl": res.data.agendadas[0].londrina, "gm": res.data.agendadas[0].maringa}
    return data
}
