import React, { useState, useEffect, useRef, useContext, useMemo } from 'react'

import './uplotChart.scss'
//import axios from 'axios'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import uPlot from 'uplot'
import UplotReact from 'uplot-react'
import '/node_modules/uplot/dist/uPlot.min.css'
import { ThemeCtx } from '../../utils/context/themeCtx'
import { endOfWeek, setHours, getWeek, setWeek } from 'date-fns'
import UsersList from '../lists/UsersList'

import PopUp from '../popUp/PopUp'
import Button from '../button/Button';
import Cores from '../cores/Cores';


import ringer from './ringer.wav'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { getSettingsData, setRobo } from '../../api'
import { getMotos } from '../../api/motoData.js'
import { getChartData, getChartDate } from '../../api/chartData.js'

const { spline, bars } = uPlot.paths
const lineInterpolations = { linear: 0, spline: 3 }
const drawStyles = { line: 0, bars: 1 }
const _bars60_100 = bars({ size: [0.6, 100] })
const _bars100Left = bars({ size: [1], align: 1 })
const _bars100Right = bars({ size: [1], align: -1 })
const _spline = spline()

function paths(u, seriesIdx, idx0, idx1, extendGap, buildClip) {
  let s = u.series[seriesIdx]
  let style = s.drawStyle
  let interp = s.lineInterpolation

  let renderer =
    style === drawStyles.line
      ? interp === lineInterpolations.spline
        ? _spline
        : null
      : style === drawStyles.bars
      ? _bars60_100
      : style === drawStyles.barsLeft
      ? _bars100Left
      : style === drawStyles.barsRight
      ? _bars100Right
      : () => null

  return renderer(u, seriesIdx, idx0, idx1, extendGap, buildClip)
}

const UplotChart = (prop) => {
  const queryClient = useQueryClient()

  const { darkMode } = useContext(ThemeCtx)
  const [week, setWeeks] = useState({
    startDate: setWeek(setHours(new Date(), 7), getWeek(new Date())),
    endDate: endOfWeek(new Date(), getWeek(new Date())),
    currWeek: getWeek(new Date()),
    isToday: true,
  })
  const [series, setseries] = useState([[], [], [], [], []])
  const [weatherSeries, setweatherSeries] = useState([[], [], []])
  const [isWarning, setisWarning] = useState(false)
  const [avisoBoot, setAvisoBoot] = useState(false)

  const settingsData = useQuery('media', getSettingsData)
  const motoData = useQuery(['motos', "londrina"], getMotos, {
    refetchInterval: 30000,
    refetchIntervalInBackground: true,
  })
  const chartData = useQuery(['chart', "londrina"], getChartData, {
    refetchInterval: 30000,
    refetchIntervalInBackground: true,
  })

  const chartByDate = useQuery(['chartByDate', week, "londrina" ], getChartDate)

  const audio = useMemo(() => new Audio(ringer), [])

  const dateFromRef = useRef()
  const dateToRef = useRef()

  const [liveToggle, setLiveToggle] = useState(false)

  const [displayDates, setdisplayDates] = useState(false)

  const [media, setmedia] = useState(0)

  const [showList, setShowList] = useState(false)

  const hangleRobo = (status) =>{

    if(status === "ativa"){
      setRoboGrafico.mutate({"data":"ativa"})
    }else if(status === "desativa"){
      setRoboGrafico.mutate({"data":"desativada"})
    }
  }
  const setRoboGrafico =  useMutation(setRobo, {
    onSuccess: (e) => {
      if(e["status"] == '201'){
        alert(e.data.data.data)
        setAvisoBoot(false)
        return true
      }

      queryClient.invalidateQueries()
    },
    onError: (e) => {
      return true
    }
  });
  
  const chartOptions = useMemo(() => {
    return {
      id: 'uPlotId',
      class: 'uPlot-chart-londrina',
      width: prop.valueW,
      height: prop.valueH,
      hooks: {
        setLegend: [
          (u) => {
            if (u.legend.idx == null) {
              u.latest = true
              u.setLegend({ idx: u.data[0].length - 1 }, false)
            } else {
              u.latest = false
            }
          },
        ],
        setData: [
          (u) => {
            if (u.latest) {
              u.setLegend({ idx: u.data[0].length - 1 }, false)
            }
          },
        ],
      },
      series: [
        {
          value: (self, rawValue) => {
            var newDate = new Date()
            newDate.setTime(rawValue + '000.0')
            
            let dateStr = newDate.toLocaleString()
            if (dateStr !== 'Invalid Date') {
              return dateStr
            }
          },
        },
        {
          label: 'Em Operação',
          stroke: darkMode ? 'rgb(30,30,200)' : 'blue',
          spanGaps: true,
          width: 2,
          drawStyle: drawStyles.line,
          lineInterpolation: lineInterpolations.spline,
          paths,

          value: (self, rawValue) => {
            if (rawValue) {
              let check = rawValue.toString()
              if (check.length <= 1) {
                check = '0' + check
                return check
              } else return rawValue
            } else return '0'
          },
        },
        {
          label: 'Livres',
          stroke: darkMode ? 'rgb(40,180,40)' : 'green',
          spanGaps: true,
          show: false,
          width: 2,
          drawStyle: drawStyles.line,
          lineInterpolation: lineInterpolations.spline,
          paths,
          value: (self, rawValue) => {
            if (rawValue) {
              let check = rawValue.toString()
              if (check.length <= 1) {
                check = '0' + check
                return check
              } else return rawValue
            } else return '0'
          },
        },
        {
          label: 'Em Andamento',
          stroke: darkMode ? 'rgb(200,20,20)' : 'orangeRed',
          spanGaps: true,
          width: 2,
          drawStyle: drawStyles.line,
          lineInterpolation: lineInterpolations.spline,
          paths,
          value: (self, rawValue) => {
            if (rawValue) {
              let check = rawValue.toString()
              if (check.length <= 1) {
                check = '0' + check
                return check
              } else return rawValue
            } else return '0'
          },
        },
        {
          label: 'Pendências',
          stroke: darkMode ? 'rgba(250,20,20,0.2)' : 'rgba(250,20,20,0.4)',
          spanGaps: true,
          width: 2,
          drawStyle: drawStyles.line,
          lineInterpolation: lineInterpolations.spline,
          paths,
          value: (self, rawValue) => {
            if (rawValue) {
              let check = rawValue.toString()
              if (check.length <= 1) {
                check = '0' + check
                return check
              } else return rawValue
            } else return '0'
          },
        },
      ],
      axes: [
        {
          stroke: darkMode ? '#888' : 'black',
          grid: {
            show: true,
            stroke: darkMode ? 'rgba(40,40,40,0.5)' : 'rgba(10,10,10,0.3)',
            width: 1,
          },
        },
        {
          show: true,
          side: 1,
          font: '13px Arial',
          stroke: darkMode ? '#888' : 'black',
          grid: {
            show: true,
            stroke: darkMode ? 'rgba(40,40,40,0.5)' : 'rgba(10,10,10,0.3)',
            width: 1,
          },
          ticks: {
            show: true,
            stroke: darkMode ? '#444' : 'rgba(10,10,10,0.3)',
            width: 1,
          },
        },
        {
          show: false,

          stroke: darkMode ? '#888' : 'black',
          grid: {
            show: false,
          },
          ticks: {
            show: true,
            stroke: darkMode ? '#444' : 'rgba(10,10,10,0.3)',
            width: 1,
          },
        },
        {
          scale: 'pend',

          stroke: darkMode ? '#888' : 'black',
          grid: {
            show: false,
          },
          ticks: {
            show: true,
            stroke: darkMode ? '#444' : 'rgba(10,10,10,0.3)',
            width: 1,
          },
        },
      ],
      scales: {
        x: {
          distr: 2,
        },
      },
    }
  }, [darkMode,series, prop.valueW, prop.valueH ])

  const weatherOptions = useMemo(() => {
    return {
      class: 'weatherPlot',      
      width: prop.valueW,
      height: prop.valueHW,
      hooks: {
        setLegend: [
          (u) => {
            if (u.legend.idx == null) {
              u.latest = true
              u.setLegend({ idx: u.data[0].length - 1 }, false)
            } else {
              u.latest = false
            }
          },
        ],
        setData: [
          (u) => {
            if (u.latest) {
              u.setLegend({ idx: u.data[0].length - 1 }, false)
            }
          },
        ],
      },
      series: [
        {
          value: (self, rawValue) => {
            var newDate = new Date()
            newDate.setTime(rawValue + '000.0')
            let dateStr = newDate.toLocaleString()
            if (dateStr !== 'Invalid Date') {
              return dateStr
            }
          },
        },
        {
          label: 'Temp',
          stroke: darkMode ? 'rgb(200,20,20)' : 'red',
          scale: 'temp',
          spanGaps: true,
          width: 2,
          drawStyle: drawStyles.line,
          lineInterpolation: lineInterpolations.spline,
          paths,
          value: (self, rawValue) =>
            rawValue === null ? '--' : rawValue + 'ºC',
        },
        {
          label: 'Precipitação',
          stroke: darkMode ? 'rgb(30,30,200)' : 'blue',
          scale: 'chuva',
          width: 2,
          drawStyle: drawStyles.line,
          lineInterpolation: lineInterpolations.spline,
          paths,
          value: (self, rawValue) =>
            rawValue === null ? '--' : rawValue + 'mm',
        },
      ],
      axes: [
        {
          stroke: darkMode ? '#888' : 'black',
          grid: {
            show: true,
            stroke: darkMode ? 'rgba(40,40,40,0.5)' : 'rgba(10,10,10,0.3)',
            width: 1,
          },
        },
        {
          scale: 'temp',
          show: true,
          side: 1,
          stroke: darkMode ? '#888' : 'black',
          values: (self, ticks) => ticks.map((rawValue) => rawValue + 'ºC'),
          grid: {
            show: true,
            stroke: darkMode ? 'rgba(40,40,40,0.5)' : 'rgba(10,10,10,0.3)',
            width: 1,
          },
          ticks: {
            show: true,
            stroke: darkMode ? '#444' : 'rgba(10,10,10,0.3)',
            width: 1,
          },
        },
        {
          scale: 'chuva',
          font: '10px Arial',
          stroke: darkMode ? '#888' : 'black',
          grid: {
            show: false,
          },
          values: (self, ticks) => ticks.map((rawValue) => rawValue + 'mm'),
        },
      ],
      scales: {
        x: {
          distr: 2,
        },
      },
    }
  }, [darkMode, prop.valueW, prop.valueHW])

  useEffect(() => {
    if (chartData.isSuccess && liveToggle) {
      let motoTime = new Date(chartData?.data[0]?.createdAt)

      
      var dataAgora = new Date()

      const diferencaMs = dataAgora - motoTime;

      // Converta a diferença de milissegundos para dias
      const umMinutoMs = 1000 * 60;
      const diferencaMinutos = diferencaMs / umMinutoMs;

      if(diferencaMinutos > 10){
        setAvisoBoot(true)
      }else{
        setAvisoBoot(false)
      }

      setseries((prev) => {
        return [
          [...prev[0], Math.floor(motoTime.getTime() / 1000)],
          [...prev[1], chartData?.data[0]?.emOperaçao],
          [...prev[2], chartData?.data[0]?.livres],
          [...prev[3], chartData?.data[0]?.emAndamento],
          [...prev[4], chartData?.data[0]?.pend],
        ]
      })
      
      let weatherTime = new Date(chartData?.data[1]?.createdAt)
      setweatherSeries((prev) => {
        return [
          [...prev[0], Math.floor(weatherTime.getTime() / 1000)],
          [...prev[1], parseFloat(chartData?.data[1]?.temp)],
          [...prev[2], parseFloat(chartData?.data[1]?.precipita)],
        ]
      })
    }
  }, [chartData.isSuccess, chartData.data, liveToggle])
  useEffect(() => {
    if (motoData.isSuccess && chartData.isSuccess) {
      let emOpe =
        motoData.data?.ocupadoAc +
        motoData.data?.ocupadoAp +
        motoData.data?.ocupadoE +
        motoData.data?.livresss

      let emAnda = emOpe - motoData.data?.livresss + chartData.data[0]?.pend

      let newMedia = emOpe / emAnda
      setmedia(newMedia.toFixed(2))

      if (
        newMedia <= settingsData?.data?.alarmeMedia ||
        chartData?.data[0]?.pend >= settingsData?.data?.alarmePend
      ) {
        setisWarning(true)
      } else {
        setisWarning(false)
      }
    }
  }, [
    chartData.isSuccess,
    motoData.isSuccess,
    motoData.data,
    chartData.data,
    settingsData.data,
  ])

  useEffect(() => {
    if (isWarning) {
      audio.play()
      audio.loop = false
    }
  }, [isWarning, audio])

  const test = (direction) => {
    setLiveToggle(false)
    setWeeks((prev) => {
      let check = prev.currWeek
      return {
        ...prev,
        startDate: setWeek(prev.startDate, direction ? check - 1 : check + 1),
        endDate: endOfWeek(
          setWeek(prev.startDate, direction ? check - 1 : check + 1),
        ),
        currWeek: direction ? check - 1 : check + 1,
        isToday: false,
      }
    })
  }
  useEffect(() => {
    if (chartByDate.isSuccess || chartByDate.isFetchedAfterMount) {
      setseries(chartByDate?.data[0])
      setweatherSeries(chartByDate?.data[1])

      if (!week.isToday) {
        setLiveToggle(false)
      } else {
        setLiveToggle(true)
      }
    }
  }, [
    chartByDate.isSuccess,
    week.isToday,
    chartByDate.isFetchedAfterMount,
    chartByDate.data,
  ])

  return (
    <>
     <div className="conte-charts">
        <div className="conte-chart">
          <div className="menu-chart">
            <div className="conte-menu">
              <div className="left">
                <div className="arrow leftArrow">
                  <i
                    className="icon material-icons-outlined"
                    onClick={() => {
                      test(true)
                    }}
                  >
                    first_page
                  </i>
                </div>
                <div className="datePicker">
                  <div
                    className={displayDates ? 'pickers' : 'none'}
                    onMouseLeave={() => setdisplayDates((prev) => !prev)}
                  >
                    <div className="newDates">
                      <div className="dates clickable">
                        <div className="label">
                          <p>De: </p>
                        </div>
                        <DatePicker
                          ref={dateFromRef}
                          selected={week.startDate}
                          onChange={(date) =>
                            setWeeks((prev) => {
                              return {
                                ...prev,
                                startDate: date,
                                isToday: false,
                              }
                            })
                          }
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="MMMM d, yyyy h:mm aa"
                        />
                      </div>
                      <div className="dates clickable">
                        <div className="label">
                          <p>Até: </p>
                        </div>
                        <DatePicker
                          ref={dateToRef}
                          selected={week.endDate}
                          onChange={(date) =>
                            setWeeks((prev) => {
                              return {
                                ...prev,
                                endDate: date,
                                isToday: false,
                              }
                            })
                          }
                          showTimeSelect
                          timeFormat="HH:mm"
                          timeIntervals={15}
                          timeCaption="time"
                          dateFormat="MMMM d, yyyy h:mm aa"
                        />
                      </div>
                    </div>
                    <i
                      className="icon material-icons-outlined clickable"
                      onClick={() => {
                        setLiveToggle(false)

                        chartByDate.refetch()
                      }}
                    >
                      date_range
                    </i>
                  </div>
                  <div className="buttons">
                    <i
                      className={`icon material-icons-outlined ${
                        chartByDate.isLoading && 'loading'
                      }`}
                      onClick={() => setdisplayDates((prev) => !prev)}
                    >
                      {chartByDate.isLoading ? 'sync' : 'calendar_month'}
                    </i>
                    {!week.isToday && !chartByDate.isLoading && (
                      <p
                        className="hoje"
                        onClick={() =>
                          setWeeks((prev) => {
                            return {
                              ...prev,
                              currWeek: getWeek(new Date()),
                              isToday: true,
                            }
                          })
                        }
                      >
                        Voltar p/ hoje
                      </p>
                    )}
                  </div>
                </div>
                <div className="arrow rightArrow">
                  <i
                    className="icon material-icons-outlined"
                    onClick={() => {
                      test(false)
                    }}
                  >
                    last_page
                  </i>
                </div>
              </div>
              <div className="middle">
                <div
                  className="media"
                  onClick={() => {
                    audio.play()
                    audio.loop = false
                  }}
                >
                  <div className="cont">
                    <div
                      id="warning"
                      className={isWarning ? 'warning' : 'none'}
                    ></div>
                    {`Média: ${media}`}
                  </div>
                </div>
                <div className='title-chart'>
                  Status Motoristas
                </div>
                <div
                  className="equipes"
                  onClick={(e) => setShowList((prev) => !prev)}
                >
                  <p id="mp">{`MP: ${
                    motoData.isSuccess ? motoData?.data?.MP : '--'
                  }`}</p>
                  <p id="ma">{`MA: ${
                    motoData.isSuccess ? motoData?.data?.MA : '--'
                  }`}</p>
                  <p id="mf">{`MF: ${
                    motoData.isSuccess ? motoData?.data?.MF : '--'
                  }`}</p>
                </div>
              </div>
              <div className="right">
                <div className="buttons">
                  {liveToggle ? (
                    <div
                      className="live"
                      onClick={() => setLiveToggle((prev) => !prev)}
                    >
                      <div className="buttons">
                        <i className="icon ativo material-icons-outlined">update</i>
                        <p className="ativo">Auto</p>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="live"
                      onClick={() => setLiveToggle((prev) => !prev)}
                    >
                      <div className="buttons">
                        <i className="icon inativo material-icons-outlined">
                          update_disabled
                        </i>
                        <p className="inativo">Auto</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            id="charts"
            className="chartsView"
          >
            <UplotReact options={chartOptions} data={series} />
          </div>
          <div
            id="charts"
            className="chartsView"
          >
            <div className='menu-weather'>

            </div>
            <UplotReact options={weatherOptions} data={weatherSeries} />
          </div>
        </div>

        <PopUp trigger={avisoBoot} setTrigger={setAvisoBoot}>
          <div style={{ margin:'15px', display:'flex', flexDirection:'column', alignItems:'center'}}>
            <div style={{color: '#c84333', marginBottom:'10px'}}>
              ATENÇÃO, GRÁFICO NÃO ESTÁ FUNCIONANDO
            </div>
            <Button onClick={() => {hangleRobo("ativa")}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Ativar Boot Reserva"} />
          </div>
        </PopUp>
      </div>
      {showList && (
        <UsersList bikerData={motoData.isSuccess && motoData.data.motos} />
      )}
    </>
  )
}

export default UplotChart
