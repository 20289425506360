import axios from "axios";

const baseURL = "/list";

const listApi = axios.create({
  baseURL: baseURL,
});

export const getListData = async ({ queryKey }) => {
  const [_, listURL] = queryKey;
  const res = await listApi.get(`/${listURL}`);
  return res.data;
};

export const postNewItem = async ({ listURL, value }) => {
  const res = await listApi.post(`/${listURL}`, value);
  return res.data;
};

export const patchNewItem = async ({ listURL, value }) => {
  const res = await listApi.patch(`/${listURL}`, value);
  return res.data;
};
export const deleteItem = async ({ listURL, value }) => {
  console.log(value);
  const res = await listApi.delete(`/${listURL}/${value}`);
  return res.data;
};
