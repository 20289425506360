import './selectCheckBox.scss'
import SelectReact, { components } from "react-select";
import React, { useState } from 'react'

const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);
  
    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";
  
    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex ",
    };
  
    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style
    };
  
    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input style={{color: "#6B4380"}} type="checkbox" checked={isSelected} />
        {children}
      </components.Option>
    );
  };

const Select = (props) => {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const isMulti = props.isMulti !== undefined ? props.isMulti : true;

    return (
        <div className='select-check'>
            <SelectReact
              defaultValue={props.defaultValue||[]}
              isMulti={isMulti}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              onChange={ props.onChange ?  props.onChange  :  (options) => {
                  if (Array.isArray(options)) {
                  setSelectedOptions(options.map((opt) => opt.value));
                  props.onChangeOpition(options.map((opt) => opt.value))
                  }
              }}
              options={props.options}
              components={{
                  Option: InputOption
              }}
            /> 
        </div>
    )
}

export default Select

