import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import './testing.scss'


const Testing = () => {


  return (
    <div className="home">
      <Sidebar />
      <div className="homeCont">
        <Navbar />
        <div className="">

        
      </div>
      </div>
    </div>
  )
}

export default Testing
