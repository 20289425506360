import React from 'react'
import { useQuery} from 'react-query'
import {  getListData } from '../../api/kbData'
import { useOutletContext } from "react-router-dom";

import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import ListMenu from '../../components/kbs copy/ListMenu'

import './conhecimento.scss'

const Conhecimento = () => {
  const permissao = useOutletContext();

  const kbsData = useQuery(['list', "kbs"], getListData)

  return (
    <div className="conhecimento">
      <div className="conhecimentoCont">
        <Navbar acesso={permissao} select={"default"} selectList={"conhecimento"} selectOP={"default"}/>
        <Sidebar />
        <div className="">
        <ListMenu type={'kbs'} listData={kbsData}>

        </ListMenu >
        </div>
      </div>
    </div>
  )
}

export default Conhecimento
