import axios from 'axios'

const baseURL = '/board'

const boardApi = axios.create({
  baseURL: baseURL,
})

export const getBoardData = async ({queryKey }) => {
  const [_, boardName] = queryKey
  const res = await boardApi.post('/find', {boardName:boardName})
  const { _id, ...newData } = res.data
  return newData
}

export const setBoardData = async ({boardName, value}) => {
  const res = await boardApi.post('/update', {value: value, boardName:boardName})
  return res.data
}
