import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'

import './users.scss'

const Users = () => {
  return (
    <div className="users">
      <div className="usersCont">
      <Navbar select={"default"} selectList={"default"} selectOP={"default"}/>
        <Sidebar />
        <div className=""></div>
      </div>
    </div>
  )
}

export default Users
