import React, { useState, useEffect } from "react";
import PopUp from "../../../components/popUp/PopUp";
import SelectReact from "../../../components/selectCheckBox/SelectCheckBox";
import { Checkbox } from "@material-ui/core";
import Input from "../../../components/input/Input";
import Button from "../../../components/button/Button";
import { criaNovoRepasse } from "../../../api/financeiraMotoristasData"; 


const NewEntryPopup = (props) => {
  const [buttonPopUpEditar, setButtonPopUpEditar] = useState(false);
  const [formData, setFormData] = useState({
    idMotorista: '',
    idEmpresa: '',
    status_check: 'Efetivado',
    operador: 'Suporte',
    metodo: '',
    valor: '',
    incluiTaxa: false,
  });

  const seletorCheckBoxMetodo = [
    { value: "Agendado", label: "Repasse Agendado" },
    { value: "Atual", label: "Repasse Atual" },
  ];

  const seletorCheckBoxOperador = [
    { value: "Suporte", label: "Suporte" },
    { value: "Restaurante", label: "Restaurante" },
  ];

  const handleChange = (e, name) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: e,
    }));
    
  };

  const handleSave = async () => {
    const response = await criaNovoRepasse(formData);

    if(response?.status !== 200){
      props?.setConteudoPopUpAlerta(response.response.data.error)
      props?.setTriggerPopUpAlerta(true);
    }
    else{
      props.refreshPage();
      setFormData({
        idMotorista: '',
        idEmpresa: '',
        status_check: '',
        operador: '',
        metodo: '',
        valor: '',
      });
    }
    
    try {
      
    } catch (error) {
      console.error('Error creating entries:', error);
    }

    setButtonPopUpEditar(false);
  };
  
  return (
    <div>
      <Button
        onClick={() => { setButtonPopUpEditar(true)}}
        width={"auto"} 
        color={"#6B4380"}
        padding={'5px'}
        background={'#fff'}
        border={'1px solid #6B4380'}
        text={"Novo Registro"}
      />

      <PopUp trigger={buttonPopUpEditar} setTrigger={setButtonPopUpEditar}>
        <div className='title-filter-mais'>Novo Registro</div>
        <div className='filter-mais'>
              <label className='icones-form'>
                ID Motorista:
                <Input tipo={"numero"} name={"idMotorista"} value={formData.idMotorista} onChange={(e) => handleChange(e, 'idMotorista')} placeholder="Digite o idMotorista..." />
              </label>
              <label className='icones-form'>
                ID Empresa:
                <Input tipo={"numero"} name={"idEmpresa"} value={formData.idEmpresa} onChange={(e) => handleChange(e, 'idEmpresa')} placeholder="Digite a idEmpresa..." />
              </label>
              <label className='icones-form'>
                Operador:
                <SelectReact
                  isMulti={false}
                  defaultValue={formData.operador ? { value: formData.operador, label: formData.operador } : null}
                  onChange={(selectedOption) => {
                    const value = selectedOption ? selectedOption.value : '';
                    handleChange(value, 'operador');
                  }}
                  options={seletorCheckBoxOperador}
                />
              </label>
              <label className='icones-form'>
                Método:
                <SelectReact
                  isMulti={false}
                  defaultValue={formData.metodo ? { value: formData.metodo, label: formData.metodo } : null}
                  onChange={(selectedOption) => {
                    const value = selectedOption ? selectedOption.value : '';
                    handleChange(value, 'metodo');
                  }}
                  options={seletorCheckBoxMetodo}
                />
              </label>
              <label className='icones-form'>
                Valor:
                <Input tipo={"dinheiro"} name={"valor"} value={formData.valor} onChange={(e) => handleChange(e, 'valor')} placeholder="Digite o valor..." />
              </label>
              {formData.metodo==="Atual"?
                <div>
                  <p><strong>
                  Incluir taxa de transferência? (R$2,50)
                  </strong></p>
                  <Checkbox
                    onChange={(e) => handleChange(e.target.checked, 'incluiTaxa')}
                  /> Sim
                </div>
              :null
              }
            
          <button onClick={handleSave} className='botao-aplicar'>Aplicar</button>
        </div>
      </PopUp>
    </div>
  );
};

export default NewEntryPopup;