//Usado no modelo RepasseMotoca
export enum StatusRepasse {
  Banco = "azul",
  Finalizada = "verde",
  Agendada = "laranja",
  Cancelada = "vermelho"
}

//Usado no modelo ReciboMotorista
export const StatusCheck = [
  "Aguardando",  //Qnd for criado e ainda não foi validado pelo motorista
  "Cancelada",   //Qnd por qlqr motivo não foi validado
  "Efetivado",   //Qnd o motorista validou  (status permanece por 1 semana)
  "A pagar",     //Na semana após a efetivação, ainda não pagamos para o restaurante
  "Finalizado"         //Qnd pagamos para o restaurante
]

export const Metodo = [
  "Atual",    //Dinheiro é debitado do repasse atual (Machine)
  "Agendado"  //Dinheiro é debitado do repasse agendado (banco de dados)
]

export const Operador = [
  "Suporte",
  "Empresa"
]