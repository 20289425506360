import './select.scss'

const Select = (props) => {
    return (
        <div className="select">
            <select defaultValue={"default"}  onChange={e => props.onChange(e.target.value)} className="place">
                <option value="default" disabled hidden> {props.titulo} </option>
                {
                    props.options.map((obj) => (
                        <option key={obj.value} value={obj.value}>{obj.label}</option>
                    ))
                }
            </select>
        </div>
    )
}

export default Select

