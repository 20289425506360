import React from 'react'
import './sidebar.scss'

const Sidebar = (props) => {

  return (
    <div className="sidebar">
      <div className="top">
      </div>
    </div>
  );
};

export default Sidebar
