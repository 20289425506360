import React, { useState} from 'react'
import Papa from 'papaparse';
import Icon from '@material-ui/core/Icon'

import Button from '../button/Button';
import TablePopUp from '../tableFinanceira/Table'

import './inputFile.scss'

const InputFile = (props) => {

    const [selectedName, setSelectedName] = useState("");
    const [currentPagePopUp, setCurrentPagePopUp] = useState(1);
    const [dataFile, setDataFile] = useState([])

    const handlePdfFile = (e) => {
        const file = e.target.files[0];

        var extPermitidas = ['csv'];
        var extArquivo = e.target.files[0].name.split('.').pop()
        if(typeof extPermitidas.find(function(ext){ return extArquivo == ext; }) == 'undefined') 
        {
          alert('A extensão do arquivo "' + extArquivo + '" não é permitida!');
        } else {
    
          setSelectedName(e.target.files[0].name);
          props.setNameDataFile(e.target.files[0].name);
          const arquivo = e.target.files[0];
        
          Papa.parse(arquivo, {
            header: true,
            dynamicTyping: true,
            encoding: 'UTF-8',
            complete: (result) => {
              const processedData = result.data.map(item => {

                const idsViatura = {
                  "99": "01",
                  "9": "01",
                  "88": "21",
                  "8": "21",
                  "77":"31",
                  "7": "31",
                  "1": "99",
                  "1": "9",
                  "21": "88",
                  "21": "8",
                  "31":"77",
                  "31": "7"
                }
            
                if(item["Id"] !== null){
                  var values = Object.values(item)

                  var vtr = item["Vtr"].toString()
                  var obj = {"id":  item["Id"], "email": item["E-mail"], "telefone": item["Telefone"], "cpf": item["CPF"], "numero_viatura": idsViatura[vtr], "observacao_interna_1": values[75]? values[75]: '', "observacao_interna_2": item["Chave PIX"]?item["Chave PIX"] : '' }
                  
                  return obj
                }
                

              });
              const newArray = processedData.filter(item => item !== undefined);
              setDataFile(JSON.stringify(newArray))
            },
            error: (error) => {
              alert('Erro ao analisar o CSV:', error.message);
            },
          });
        }
    }

    return (
      <div className="conteudo-file-Upload">
        <div className="upload">
          <div className="placeholder">
            <p>{selectedName || "Procurar arquivo..."}</p>
          </div>
          <div className="file-selection"> 
            <p>{"Selecione"}</p>
            <input className='file-selection-input' type="file" onChange={(e)=>{handlePdfFile(e)}} />
          </div>
        </div>
        {dataFile.length !== 0 &&
          <div>
            <label className='legenda-tabela'>
              <Icon className="icon-update">arrow_forward</Icon>
              Resultado Upload
            </label>
            <div className='tabela-upload'>
              <TablePopUp data={JSON.parse(dataFile)} head={JSON.parse(dataFile)} onPageChange={page => setCurrentPagePopUp(page)} totalCount={JSON.parse(dataFile).length} currentPage={currentPagePopUp} pageSize={3}/>
            </div>
          </div>
        }
        {dataFile.length !== 0 &&
          <div>
            <Button onClick={() => {props.setDataFile(JSON.parse(dataFile), props.setTrigger(false))}} width={"60px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Salvar"} />
          </div>
        }
      </div>
    )
}

export default InputFile

