import React from "react";

import { estabsList, motocasList } from "./estabsList";

const NewItem = ({ state, setState }) => {
  switch (state.type) {
    case "VA":
      return (
        <>
          <div className="newKb">
            <div className="makeNew">
              <div className="inputs">
                {state.isEditing && (
                  <>
                    <label htmlFor="status">Status:</label>
                    <select
                      name="status"
                      id="status"
                      defaultValue={
                        state.selectedItem?.status?.includes("parcial")
                          ? "parcial"
                          : state.selectedItem?.status
                      }
                      onChange={(e) =>
                        setState((prev) => {
                          return {
                            ...prev,
                            newItemData: {
                              ...prev.newItemData,
                              status: e.target.value,
                            },
                          };
                        })
                      }
                    >
                      <option value="aberto">Aberto</option>
                      <option value="parcial">Parcial</option>
                      <option value="fechado">Fechado</option>
                    </select>
                    {state.newItemData?.status?.includes(`parcial`) && (
                      <>
                        <InputLabel
                          state={state}
                          type="text"
                          label="Status Parcial:"
                          value="status"
                          setState={setState}
                        />
                      </>
                    )}
                  </>
                )}

                <label htmlFor="kbTitulo">Tipo:</label>

                <InputLabel
                  state={state}
                  type="radio"
                  label="Motorista"
                  value="moto"
                  setState={setState}
                />
                <InputLabel
                  state={state}
                  type="radio"
                  label="Estabelecimento"
                  value="estab"
                  setState={setState}
                />
                <InputLabel
                  state={state}
                  type="radio"
                  label="Descontar do Estabelecimento e passar para o Motorista"
                  value="estabMoto"
                  setState={setState}
                />
                <InputLabel
                  state={state}
                  type="radio"
                  label="Descontar do Motorista e passar para o Estabelecimento"
                  value="motoEstab"
                  setState={setState}
                />
                <InputLabel
                  value="motorista"
                  state={state}
                  type="text"
                  label="Motorista:"
                  setState={setState}
                />
                <InputLabel
                  value="estabelecimento"
                  state={state}
                  type="text"
                  label="Estabelecimento:"
                  setState={setState}
                />
                <InputLabel
                  value="valor"
                  state={state}
                  type="number"
                  label="Valor:"
                  setState={setState}
                />
                <InputLabel
                  value="refOs"
                  state={state}
                  type="number"
                  label="OS:"
                  setState={setState}
                />
                <InputLabel
                  state={state}
                  type="textarea"
                  label="Informações:"
                  value="infoComp"
                  setState={setState}
                />
              </div>
            </div>
          </div>
        </>
      );
    case "kbs":
      return (
        <>
          <div className="newKb">
            <div className="makeNew">
              <div className="inputs">
                <InputLabel
                  value="title"
                  state={state}
                  type="text"
                  label="Título:"
                  setState={setState}
                />
                <InputLabel
                  value="descrip"
                  state={state}
                  type="text"
                  label="Descrição:"
                  setState={setState}
                />
                <InputLabel
                  value="content"
                  state={state}
                  type="textarea"
                  label="Conteúdo:"
                  setState={setState}
                />
              </div>{" "}
            </div>{" "}
          </div>
        </>
      );
    case "regOcor":
      return (
        <div className="newKb">
          <div className="makeNew">
            <div className="inputs">
              <InputLabel
                state={state}
                type="number"
                label="Nº OS:"
                value="os"
                setState={setState}
              />
              <InputLabel
                state={state}
                type="text"
                label="Motorista:"
                value="motorista"
                setState={setState}
              />
              <InputLabel
                state={state}
                type="text"
                label="Estabelecimento:"
                value="estabelecimento"
                setState={setState}
              />
              <InputLabel
                state={state}
                type="textarea"
                label="Ocorrência:"
                value="ocorrencia"
                setState={setState}
              />
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div className="newKb">
          <div className="makeNew">
            <div className="inputs">
              <h1>Seleciona um role ae</h1>
            </div>
          </div>
        </div>
      );
  }
};

const Autocomplete = ({ onSelect, value, label, defaultValue }) => {
  const [suggestionsList, setSuggestionsList] = React.useState([]);
  const [inputValue, setInputValue] = React.useState(defaultValue);
  const [isSelected, setisSelected] = React.useState(false);

  React.useEffect(() => {
    if (inputValue === "") {
      setSuggestionsList([]);
      return;
    }

    const regex = new RegExp(`^${inputValue}`, "i");
    switch (value) {
      case "estabelecimento":
        setSuggestionsList(
          estabsList.filter((suggestion) => regex.test(suggestion))
        );
        break;
      case "motorista":
        setSuggestionsList(
          motocasList.filter((suggestion) => regex.test(suggestion))
        );
        break;

      default:
        setSuggestionsList([]);
        break;
    }
  }, [inputValue, value]);

  return (
    <>
      <label>{label}</label>

      <input
        type="text"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
          onSelect(value, e.target.value);
          setisSelected(false);
        }}
      />
      {!isSelected && (
        <div className="autocomplete">
          <ul className="autocomplete-list">
            {suggestionsList.map((suggestion, index) => (
              <li
                key={index}
                className="autocomplete-item"
                onClick={() => {
                  onSelect(value, suggestion);
                  setInputValue(suggestion);
                  setisSelected(true);
                }}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
const InputLabel = ({ value, label, type, setState, state }) => {
  const handleStateChange = (prop, value) => {
    setState((prev) => {
      return {
        ...prev,
        newItemData: {
          ...prev.newItemData,
          [prop]: value,
        },
      };
    });
  };
  switch (type) {
    case "radio":
      return (
        <>
          <div className="typeOpts">
            <input
              id={value}
              type={type}
              name="typeOpts"
              value={value}
              onChange={(e) => {
                handleStateChange("typeOpt", e.target.value);
              }}
              checked={state?.newItemData?.typeOpt === value && true}
            />

            <label htmlFor={value}>{label}</label>
          </div>
        </>
      );
    case "text":
    case "number":
      if (value === "estabelecimento" || value === "motorista") {
        return (
          <>
            <Autocomplete
              onSelect={handleStateChange}
              value={value}
              label={label}
              defaultValue={state.isEditing ? state?.newItemData[value] : ""}
            />
          </>
        );
      }
      return (
        <>
          <label>{label}</label>
          <input
            type={type}
            defaultValue={state?.newItemData[value]}
            onChange={(e) => {
              handleStateChange(value, e.target.value);
            }}
          />
        </>
      );
    case "textarea":
      return (
        <>
          <label>{label}</label>
          <textarea
            type={type}
            defaultValue={state?.newItemData[value]}
            onChange={(e) => {
              handleStateChange(value, e.target.value);
            }}
          />
        </>
      );
    default:
      <h1>nothinggg</h1>;
      break;
  }
};
export default NewItem;
