const List = ({ data, type, setState }) => {

  return (
    <div className="kbList">
      {data
        ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .map((el) => {
          switch (type) {
            case "VA":
              return (
                <div
                  key={el?._id}
                  className="listItem"
                  onClick={() =>
                    setState((prev) => {
                      return { ...prev, selectedItem: el };
                    })
                  }
                >
                  <div className="icons">
                    <i className="material-icons-outlined">menu_open</i>
                  </div>
                  <div className="details">
                    <div className="title">
                      <h2>{el?.refOs}</h2>
                      <p id="date">
                        {new Date(el?.createdAt).toLocaleString()}
                      </p>
                      <p id="itemStatus">{el.status}</p>
                    </div>
                    <div className="suv">
                      <h3>{el?.motorista}</h3>
                      <p>{el?.estabelecimento}</p>
                    </div>
                  </div>
                </div>
              );
            case "kbs":
              return (
                <div
                  key={el?._id}
                  className="kbListItem"
                  onClick={() =>
                    setState((prev) => {
                      return { ...prev, selectedItem: el };
                    })
                  }
                >
                  <div className="icons">
                    <i className="material-icons-outlined">menu_open</i>
                  </div>
                  <div className="title">
                    <h2>{el?.title}</h2>
                    <p>{el?.descrip}</p>
                  </div>
                </div>
              );
            case "regOcor":
              return (
                <div
                  key={el?._id}
                  className="listItem"
                  onClick={() =>
                    setState((prev) => {
                      return { ...prev, selectedItem: el };
                    })
                  }
                >
                  <div className="icons">
                    <i className="material-icons-outlined">menu_open</i>
                  </div>
                  <div className="details">
                    <div className="title">
                      <h2>{el?.os}</h2>
                      <p id="date">
                        {new Date(el?.createdAt).toLocaleString()}
                      </p>
                    </div>
                    <div className="suv">
                      <h3>{el?.motorista}</h3>
                      <p>{el?.estabelecimento}</p>
                    </div>
                  </div>
                </div>
              );
            default:
              return (
                <>
                  <div className="kbListItem">
                    <h1>nothinggg</h1>
                  </div>
                </>
              );
          }
        })}
    </div>
  );
};

export default List;
