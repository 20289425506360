import './input.scss'

const Input = (props) => {

    const formatarCPF = (cpf) => {
        // Remove todos os caracteres não numéricos
        cpf = cpf.replace(/\D/g, '');
      
        // Aplica a máscara
        cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
        cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
        cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
      
        return cpf;
    };

    const formatarDinheiro = (valor) => {
        // Remove todos os caracteres não numéricos
        valor = valor.replace(/\D/g, '');
      
        // Aplica a máscara
        valor = valor.replace(/(\d{2})(\d{1,2})$/, '$1.$2');
      
        return valor;
    };

    const handleChange = (event) => {
        const value = event
        return formatarCPF(value);
    };

    return (
        <div className="input" style={{width: props.width, height: props.height, tipo: props}}>
            { props.tipo === "texto" &&
                <input type="text"
                name={props.name}
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                placeholder={props.placeholder} 
                className="placeholder" 
                />
            }
            { props.tipo === "textoArea" &&
                <textarea 
                name={props.name}
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                placeholder={props.placeholder} 
                className="placeholder" 
                />
            }
            {props.tipo === "cpf" &&
                <input type="text"
                name={props.name}
                value={props.value}
                onChange={e => props.onChange(handleChange(e.target.value))}
                placeholder={props.placeholder} 
                className="placeholder" 
                maxLength="14"
                />
            }
            {( props.tipo === "number" ||props.tipo === "numero") &&
                <input type="number"
                name={props.name}
                value={props.value}
                onChange={e => props.onChange(e.target.value)}
                placeholder={props.placeholder} 
                className="placeholder" 
                readOnly={props?.readOnly ?? false}
                />
            }
            {props.tipo === "dinheiro" &&
                <input 
                    type="text"
                    name={props.name}
                    value={props.value}
                    onChange={e => props.onChange(formatarDinheiro(e.target.value))}
                    placeholder={props.placeholder} 
                    className="placeholder" 
                    pattern="^\d+\.\d{2}$" 
                    title="Please enter a valid amount (up to 2 decimal places)"
                />
            }
        </div>
    )
}

export default Input