import React, {useState, useEffect} from 'react'
import {useMutation, useQueryClient, useQuery } from 'react-query'
import Icon from '@material-ui/core/Icon'
import { Oval } from  'react-loader-spinner'
import ProgressBar from "@ramonak/react-progress-bar";
import axios from 'axios'

import TabelaComando from '../tabelaTelaComando/TabelaComando';
import TabeleLimite from '../tabelaTelaComando/Tabela/TabelaLimite';
import Button from '../button/Button';
import RadioButton from '../radioButton/RadioButton';
import InputFile from '../inputFile/InputFile';
import Input from '../input/Input'
import PopUp from '../popUp/PopUp';
import Cores from '../cores/Cores'

import {postAtualizaTaxistas, postAtualizaTaxistaUnitario } from '../../api/telaComandoData'
import {setRobo, getStatusBoot} from '../../api'


import settings from "./imagens/settings2.png"
import './telaComando.scss'

const TelaComando = (props) => {

    const baseURL = '/telaComando'
    const queryClient = useQueryClient()
    const telaComandoApi = axios.create({
      baseURL: baseURL,
    })

    const [primeiroRender, setprimeiroRender] = useState(true);

    const [statusBoot, setStatusBoot] = useState('');
    const [idAtivar, setIdAtivar] = useState('')
    const [pausaTabela, setpausaTabela] = useState([]);
    const [pausaTabelaArapongas, setpausaTabelaArapongas] = useState([]);
    const [pausaTabelaMaringa, setpausaTabelaMaringa] = useState([]);
    const [chuvaTabela, setchuvaTabela] = useState([]);
    const [filaMa, setfilaMa] = useState([]);
    const [atualizaTabela, setAtualizaTabela] = useState(false);

    const [select, setSelect] = useState(null);
    const [selectUpload, setSelectUpload] = useState(null);
    const boxAtivaINativa = [{nome: "ativar", label:"Ativar", value:'A'},{nome: "inativar", label:"Inativar", value:'I'}, {nome: "VF", label:"Voltar para Fila", value:'I'}]
    const boxupload = [{nome: "upload", label:"\t\t\t\t\t\t", value:'UP'},{nome: "IDs", label:"IDS", value:'ID'}]

    const [selectSC, setSelectSC] = useState(null);
    const boxSC = [{nome: "c/v", label:"c/v", value:"c/v"},{nome: "s/v", label:"s/v", value:'s/v'}]

    const [buttonPopUp, setButtonPopUp] = useState(false);

    const [dataFile, setDataFile] = useState([])
    const [nameDataFile, setNameDataFile] = useState([])

    const [loader, setLoader] = useState(false);
    const [barraProgresso, setBarraProgresso] = useState(false);
    const [timer, setTimer] = useState(0);
    const [statusBotao, setStatusBotao] = useState("");
    const [statusFluxo, setStatusFluxo] = useState("");

    const [filaRequest, setFilaRequest] = useState([]);
    const [emAndamento, setEmAndamento] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');

    const cabecalho = [
        { id: 'vazio', name: '' },
        { id: 'londrina', name: 'Londrina' }
    ];
    const cabecalhoArapongas = [
        { id: 'vazio', name: '' },
        { id: 'arapongas', name: 'Arapongas' }
    ];
    const cabecalhoMaringa = [
        { id: 'vazio', name: '' },
        { id: 'maringa', name: 'Maringá' }
    ];
    const cabecalhoChuva = [
        { id: 'vazio', name: '' },
        { id: 'londrina', name: 'Londrina' },
        { id: 'arapongas', name: 'Arapongas' },
        { id: 'maringa', name: 'Maringá' }
    ];

    const opcoesLocalizacao = {
        timeZone: 'America/Sao_Paulo',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    }

    const estrelas = {
        C: 1,
        P: 2,
        PP:3,
        PPP:4
    }

    const boot = useQuery(['boot'], getStatusBoot, {
        refetchInterval: 30000,
        refetchIntervalInBackground: true,
    })

    const handleChange = (event) => {
      setSelectedOption(event.target.value);
    };

    const handleAtivacao = () => {

        if(selectedOption === 'option1'){

            if(select=== null || dataFile.length === 0){
                alert("É necessário carregar um arquivo e preencher todos os campos.")
                return
            }

            setLoader(true)

            var dados
            
            var data = new Date()
            const dataHoraBrasileira = data.toLocaleString('pt-BR', opcoesLocalizacao)
            dados = {
                "adicionais":{
                    "status_taxista": select,
                    "possui_vinculo": true,
                    "vinculo": "T",
                    "pagamentos": ["F","R"],
                    "observacao_interna_3": "Ativado Sistema Automático: " + dataHoraBrasileira
                },
                "file": dataFile,
            }
    
            atualizaTaxistas.mutate(dados)

        }else if(selectedOption === 'option2'){
            if(select=== null || idAtivar===''){
                alert("É necessário preencher todos os campos.")
                return
            }

            setLoader(true)


            var dados
            
            var data = new Date()
            const dataHoraBrasileira = data.toLocaleString('pt-BR', opcoesLocalizacao)
            dados = {
                "status_taxista": select,
                "possui_vinculo": true,
                "vinculo": "T",
                "pagamentos": ["F","R"],
                "observacao_interna_3": "Ativado Sistema Automático: " + dataHoraBrasileira,
                "id": Number(idAtivar),
            }
            atualizaTaxistaUnitario.mutate(dados)
        }
        /*if(selectSC === "s/v"){
            dados = {
                "adicionais":{
                    "status_taxista": select,
                    "possui_vinculo": false,
                },
                "file": dataFile,
            }
        }else{}*/
    }

    
  const hangleRobo = (status) =>{

    if(status === "ativa"){
      setRoboGrafico.mutate({"data":"ativa"})
    }else if(status === "desativa"){
      setRoboGrafico.mutate({"data":"desativada"})
    }
  }

  const setRoboGrafico =  useMutation(setRobo, {
    onSuccess: (e) => {
      if(e["status"] == '201'){
        alert(e.data.data.data)
        return true
      }

      queryClient.invalidateQueries()
    },
    onError: (e) => {
        alert(e)
      return true
    }
  });

  const atualizaTaxistaUnitario =  useMutation(postAtualizaTaxistaUnitario, {
    onSuccess: (e) => {
      queryClient.invalidateQueries()
      if(e["status"] === 201){
        if(e.data.data.length !== 0){
            alert("Erro ao atualizar o id")
            
        }else{
            alert("Atualizações realizadas com sucesso!")
        }
        setLoader(false)
        setIdAtivar('')
      }
    },
    onError: (e) => {
      setLoader(false)
      setIdAtivar('')
      alert(e.response.data.data)
    }
});

    const atualizaTaxistas =  useMutation(postAtualizaTaxistas, {
        onSuccess: (e) => {
          queryClient.invalidateQueries()
          if(e["status"] === 201){
            if(e.data.data.length !== 0){
                alert("Erro ao atualizar os seguintes ids: " + e.data.data)
            }else{
                alert("Atualizações realizadas com sucesso!")
            }
            setLoader(false)
            setDataFile([])
            setNameDataFile([])
          }
        },
        onError: (e) => {
          setLoader(false)
          setDataFile([])
          setNameDataFile([])
          alert(e.response.data.data)
        }
    });

    const acionaTimer = async () => {

        var setNumero  = 0
        for(var i=0; i<6; i++ ){

            await delay(5000);
            setNumero = setNumero+16
           // setBarraProgresso(setNumero)

        }
        //setBarraProgresso(false)
        setTimer(0)
    }

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    )
    
    const lidarCancelarFila = async (indice) => {
        if(indice === 0){
            return
        }else{
            var novaFila = filaRequest.splice(indice, 1);
            setFilaRequest(filaRequest)
        }
    }
    const acionaPromisse = async () => {
        setBarraProgresso(1)
        var tamanhoAntigo = filaRequest.length
        var fila = 0
        while(filaRequest.length !== 0){

            if(filaRequest[0]["tipo"] === "infinito"){
                try {
                    const res = await telaComandoApi.get('/atualizadesabilitaLimite',  {params: {"cidade":filaRequest[0]["cidade"]}})
                    //console.log(res)

                } catch (err) {
                    //setError(err);
                    alert(err)
                }

            }else if(filaRequest[0]["tipo"] === "bloqueio"){
                try {
                    const res = await telaComandoApi.post('/atualizaLimite', filaRequest[0]["numero"])
                    //console.log(res)

                } catch (err) {
                    alert(err)
                   // setError(err);
                }
            }else if(filaRequest[0]["tipo"] === "bloqueioUnitario"){
                try {
                    const res = await telaComandoApi.post('/atualizaLimiteUnitario', {valor: filaRequest[0]["numero"], categoria:filaRequest[0]["categoria"]})
                    //console.log(res)

                } catch (err) {
                    alert(err)
                   // setError(err);
                }
            }
            await acionaTimer()
            var novaFila = filaRequest.shift()
            setFilaRequest(filaRequest)

            if(tamanhoAntigo < filaRequest.length){
                tamanhoAntigo = filaRequest.length
            }
            fila++
            var porcentagem = (100*fila)/tamanhoAntigo
            setBarraProgresso(porcentagem)
        }
        setBarraProgresso(false)
        setEmAndamento(false)
        
    }

    const fetchData = async () => {
 
        try {

            const dadosPausa =  await telaComandoApi.get('/procuraTodosDados')

            var pausa = dadosPausa.data[0]

            setpausaTabela([
                {f: "Premium", fluxo:"fluxo"},
                {C: "Comum", pausa_comum_londrina: pausa.londrina.comum},
                {P: "Premium", pausa_premium_londrina: pausa.londrina.premium},
                {PP: "Premium_plus", pausa_premium_plus_londrina: pausa.londrina.premium_plus},
                {PPP: "Premium_plus_plus", pausa_premium_plus_plus_londrina: pausa.londrina.premium_plus_plus},    
                {b: "Premium", botoes:"botoes"},
            ])
            
            setpausaTabelaArapongas([
                {f: "Premium", fluxo:"fluxo"},
                {C: "Comum", pausa_comum_arapongas: pausa.arapongas.comum},
                {P: "Premium", pausa_premium_arapongas:  pausa.arapongas.premium}, 
                {PP: "Premium_plus", pausa_premium_plus_arapongas: pausa.arapongas.premium_plus},  
                {PPP: "Premium_plus_plus", pausa_premium_plus_plus_arapongas: pausa.arapongas.premium_plus_plus},
                {b: "Premium", botoes:"botoes"},
            ])

            setpausaTabelaMaringa([
                {f: "Premium", fluxo:"fluxo"},
                {C: "Comum", pausa_comum_maringa: pausa.maringa.comum},  
                {P: "Premium", pausa_premium_maringa:  pausa.maringa.premium}, 
                {PP: "Premium_plus", pausa_premium_plus_maringa: pausa.maringa.premium_plus},
                {PPP: "Premium_plus_plus", pausa_premium_plus_plus_maringa: pausa.maringa.premium_plus_plus},
                {b: "Premium", botoes:"botoes"},
            ])

            setStatusBotao({"londrina": pausa.londrina.botao, "maringa":pausa.maringa.botao, "arapongas":pausa.arapongas.botao })
            setStatusFluxo({"londrina": pausa.londrina.fluxo, "maringa":pausa.maringa.fluxo, "arapongas":pausa.arapongas.fluxo })

            setchuvaTabela([{dinamica_chuva:"Dinâmica Chuva", dinamica_chuva_londrina: pausa.londrina.dinamica_chuva, dinamica_chuva_arapongas: pausa.arapongas.dinamica_chuva, dinamica_chuva_maringa: pausa.maringa.dinamica_chuva}])
            setfilaMa([{fila_ma:"Fila MA", fila_ma_londrina: pausa.londrina.fila_ma, fila_ma_arapongas: pausa.arapongas.fila_ma, fila_ma_maringa: pausa.maringa.fila_ma}])

        } catch (error) {
          
        } 
    
        setprimeiroRender(false)
    }


    useEffect(() => {
        
        if(emAndamento !== true){
            setEmAndamento(true)
            //console.log(filaRequest)
            setAtualizaTabela(false)
            acionaPromisse()
        }

    }, [ atualizaTabela])

    useEffect(() => {

        fetchData();
        setAtualizaTabela(false)
    
    }, [atualizaTabela, setAtualizaTabela])

    useEffect(() => {

        if(timer === 1){
            setTimer(1)
            setBarraProgresso(1)
            acionaTimer();
        }

    
    }, [setTimer, timer])

    useEffect(() => {
        if(boot.isSuccess){
            setStatusBoot(boot.data.data)

        }
    }, [boot.isSuccess])

    
    return (
        <div className='tabelas-comando'>
            {loader === true &&
              <div className='loader'>
                  <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
              </div>
            }
            <div className='painel-controle'>
                <Icon  className="icon-update" style={{ marginRight: "20px", marginBottom:"2px", marginTop: "2px", color:"rgb(107, 67, 128)", fontSize:"30px" }}>{"arrow_forward"}</Icon>
                <div style={{display:"flex", alignItems: "center"}}> Painel de Controle Suporte</div>
            </div>
            <div className='telas-acoes'>
                <div className='tabelas-conteudo'>
                    <div className='tabela-limite'>
                        <TabeleLimite setFilaRequest={setFilaRequest} filaRequest={filaRequest} cabecalho={cabecalho} data={pausaTabela} atualiza={setAtualizaTabela} setTimer={setTimer} timer={timer} statusBotao={statusBotao.londrina} statusFluxo={statusFluxo.londrina} className="tabela"/>
                        <TabeleLimite setFilaRequest={setFilaRequest} filaRequest={filaRequest} cabecalho={cabecalhoArapongas} data={pausaTabelaArapongas} atualiza={setAtualizaTabela} setTimer={setTimer} timer={timer} statusBotao={statusBotao.arapongas} statusFluxo={statusFluxo.arapongas} className="tabela"/>
                        <TabeleLimite setFilaRequest={setFilaRequest} filaRequest={filaRequest} cabecalho={cabecalhoMaringa} data={pausaTabelaMaringa} atualiza={setAtualizaTabela} setTimer={setTimer} timer={timer} statusBotao={statusBotao.maringa} statusFluxo={statusFluxo.maringa} className="tabela"/>
                    </div>
                    <div style={{display:"flex"}}>

                        <div className='tabelas-conteudo'>
                            <div className='ativacao-motoristas'>
                                <div className='conteudo'>
                                    <div className='titulo'>
                                        Ativação de Motoristas
                                    </div>
                                    <div className='opcoes'>
                                        <div className="radio-buttons" style={{display:'flex', alignItems:'center'}}>
                                            <div className="radio-container" style={{display:'flex', alignItems:'center'}}>
                                                <input
                                                type="radio"
                                                id="option1"
                                                name="options"
                                                value="option1"
                                                checked={selectedOption === 'option1'}
                                                onChange={handleChange}
                                                />
                                                <label htmlFor="option1" style={{marginLeft:'2px'}}>
                                                    { dataFile.length === 0 ? <Icon type="file" onClick={()=>{setButtonPopUp(true)}} className="file-selection-input" style={{ marginRight: "20px", marginBottom:"2px", marginTop: "2px", color:"#8f8f9d", fontSize:"35px", cursor:"pointer" }}>{"save_alt"}</Icon> :  <div style={{display:"flex", flexDirection:"column", alignItems:"center", color:"#8f8f9d"}}> <Icon type="file" onClick={()=>{setButtonPopUp(true)}} className="file-selection-input" style={{ marginRight: "20px", marginBottom:"2px", marginTop: "2px", color:"#8f8f9d", fontSize:"35px", cursor:"pointer" }}>{"file_present"}</Icon> {nameDataFile}</div>
                                                    }
                                                </label>
                                            </div>
                                            <div style={{marginRight:'20px'}}>
                                                ou
                                            </div >
                                            <div className="radio-container" style={{display:'flex', alignItems:'center'}}>
                                                <input
                                                type="radio"
                                                id="option2"
                                                name="options"
                                                value="option2"
                                                checked={selectedOption === 'option2'}
                                                onChange={handleChange}
                                                />
                                                <label htmlFor="option2" style={{marginLeft:'2px'}}>
                                                ID
                                                </label>
                                                <Input tipo={"texto"} width={"40px"} height={'15px'} name={'id'} value={idAtivar} onChange={(e)=>{setIdAtivar(e)}} placeholder="" />
                                            </div>
                                        </div>

                                    </div>
                                    <div style={{borderBottom:'1px solid #BCB0B0', margin:'19px'}}>
                                    </div>
                                    <div className='botao'>
                                    <   div className='coteudo-boxs'>
                                            <RadioButton setSelect={setSelect} boxes={boxAtivaINativa}/>
                                            {/*<RadioButton setSelect={setSelectSC} boxes={boxSC}/>*/}
                                        </div>
                                        <Button onClick={() => {handleAtivacao()}} width={"60px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Enviar"} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{display:"flex", flexDirection: 'column'}}>
                            <TabelaComando cabecalho={cabecalhoChuva} data={chuvaTabela} atualiza={setAtualizaTabela} className="tabela"/>
                           {/* <TabelaComando cabecalho={cabecalho} data={filaMa} atualiza={setAtualizaTabela} className="tabela"/>*/}
                           {statusBoot !== '' &&
                            <div  style={{ border:'1px solid #BCB0B0', borderRadius: '8px', padding:'10px' }}>
                                    <div >
                                        <div style={{ color:'rgb(78, 78, 78)', fontSize:'17px', fontWeight:'bold', display:'flex', justifyContent:'center' }}> Botões Boot Gráfico</div>
                                        <div style={{display:'flex'}}>
                                            <div className={statusBoot === 'Ativado'? ' Desativado':'Ativado'}>
                                                <Button  onClick={() => {hangleRobo("ativa")}} width={"150px"} color={"#ffffff"} background={"rgb(66, 175, 60)"} border={"rgb(66, 175, 60)"} text={"Ativar Boot Reserva"} /> 
                                            </div>
                                            <div className={statusBoot === 'Ativado'? 'Ativado':'Desativado'}>
                                                <Button  onClick={() => {hangleRobo("desativa")}} width={"170px"} color={"#ffffff"} background={"#c84333"} border={"#c84333"} text={"Desativar Boot Reserva"} />
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
                    <div>
                        <div className='tabela-limite'>
                            <div style={{width:"199px", height:"317px", border:"1px solid #BCB0B0", borderRadius:"8px", marginBottom:"15px", padding:"5px", fontFamily:"'Gabarito', sans-serif"}}>
                                { barraProgresso !== false &&
                                    <div style={{marginBottom:"5px"}}>
                                        <ProgressBar bgColor={Cores.laranja} width={'200px'} completed={barraProgresso} />                
                                    </div>
                                }

                                <div style={{ display:"flex", justifyContent:"center", color:"rgb(78, 78, 78)"}}>
                                    Fila de Comandos
                                </div>

                                <div>
                                    {filaRequest.length !== 0 &&
                                        filaRequest.map((obj, indice)=>{
                                            if(obj["tipo"] === "infinito"){
                                                return(
                                                    <div key={indice} style={{ border:"1px solid #BCB0B0", borderRadius:"8px", marginBottom:"5px", padding:"5px"}}>
                                                        <div>
                                                            <div style={{justifyContent:"flex-end", display:"flex"}}>
                                                                <Icon className='icone-star' onClick={()=>{lidarCancelarFila(indice)}} style={{ fontSize:"15px", color:"red", cursor:"pointer"}}>cancel </Icon>
                                                            </div>
                                                            {
                                                                obj["cidade"] + " - Desabilita Limite"
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }else if(obj["tipo"] === "bloqueio"){
                                                return(
                                                    <div key={indice} style={{ border:"1px solid #BCB0B0", borderRadius:"8px", marginBottom:"5px"}}>
                                                            <div style={{justifyContent:"flex-end", display:"flex"}}>
                                                                <Icon className='icone-star' onClick={()=>{lidarCancelarFila(indice)}} style={{ fontSize:"15px", color:"red", cursor:"pointer"}}>cancel </Icon>
                                                            </div>
                                                        <div style={{ display:"flex", justifyContent:"center"}}>
                                                            {
                                                                Object.keys(obj["numero"])[0].split("_")[2]
                                                            }
                                                        </div>
                                                        <div style={{ display:"flex", justifyContent:" space-around"}}>
                                                            <div style={{display:"flex", marginRight:"5px"}}>
                                                                <Icon className='icone-star' style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                {
                                                                    Object.values(obj["numero"])[0]
                                                                }
                                                            </div>
                                                            <div style={{display:"flex", marginRight:"5px"}} >
                                                                <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                {
                                                                    Object.values(obj["numero"])[1]
                                                                }
                                                            </div>
                                                            <div style={{display:"flex", marginRight:"5px"}}>
                                                                <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                {
                                                                    Object.values(obj["numero"])[2]
                                                                }
                                                            </div>
                                                            <div style={{display:"flex", marginRight:"5px"}}>
                                                                <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                {
                                                                    Object.values(obj["numero"])[3]
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }else if(obj["tipo"] === "bloqueioUnitario"){
                                                var num = obj["estrelas"]
                                                var cidade = obj["categoria"].split('_')
                                                switch(num){
                                                    case "C":
                                                        return(    
                                                            <div key={indice} style={{display:'flex', flexDirection:'column',border:"1px solid #BCB0B0", borderRadius:"8px", marginBottom:"5px"}}>
                                                                <div style={{justifyContent:"flex-end", display:"flex"}}>
                                                                    <Icon className='icone-star' onClick={()=>{lidarCancelarFila(indice)}} style={{ fontSize:"15px", color:"red", cursor:"pointer"}}>cancel </Icon>
                                                                </div>
                                                                <div style={{justifyContent:"center", display:"flex"}}>
                                                                    {cidade.at(-1)}
                                                                </div> 
                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                                    <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                    {obj['numero']}
                                                                </div> 
                                                            </div>  
                                                        )
                                                    case "P":
                                                        return(    
                                                            <div key={indice} style={{display:'flex', flexDirection:'column',border:"1px solid #BCB0B0", borderRadius:"8px", marginBottom:"5px"}}>
                                                                <div style={{justifyContent:"flex-end", display:"flex"}}>
                                                                    <Icon className='icone-star' onClick={()=>{lidarCancelarFila(indice)}} style={{ fontSize:"15px", color:"red", cursor:"pointer"}}>cancel </Icon>
                                                                </div>
                                                                <div style={{justifyContent:"center", display:"flex"}}>
                                                                    {cidade.at(-1)}
                                                                </div> 
                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                                    <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                    <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                    {obj['numero']}
                                                                </div> 
                                                            </div>  
                                                        )
                                                    case "PP":
                                                        return(    
                                                            <div key={indice} style={{display:'flex', flexDirection:'column',border:"1px solid #BCB0B0", borderRadius:"8px", marginBottom:"5px"}}>
                                                                <div style={{justifyContent:"flex-end", display:"flex"}}>
                                                                    <Icon className='icone-star' onClick={()=>{lidarCancelarFila(indice)}} style={{ fontSize:"15px", color:"red", cursor:"pointer"}}>cancel </Icon>
                                                                </div>
                                                                <div style={{justifyContent:"center", display:"flex"}}>
                                                                    {cidade.at(-1)}
                                                                </div> 
                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                                    <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                    <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                    <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                    {obj['numero']}
                                                                </div> 
                                                            </div>   
                                                        )
                                                    case "PPP":
                                                        return(    
                                                            <div key={indice} style={{display:'flex', flexDirection:'column',border:"1px solid #BCB0B0", borderRadius:"8px", marginBottom:"5px"}}>
                                                                <div style={{justifyContent:"flex-end", display:"flex"}}>
                                                                    <Icon className='icone-star' onClick={()=>{lidarCancelarFila(indice)}} style={{ fontSize:"15px", color:"red", cursor:"pointer"}}>cancel </Icon>
                                                                </div>
                                                                <div style={{justifyContent:"center", display:"flex"}}>
                                                                    {cidade.at(-1)}
                                                                </div> 
                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                                    <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                    <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                    <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                    <Icon className='icone-star'  style={{ fontSize:"15px", color:"#255290"}}>star </Icon>
                                                                    {obj['numero']}
                                                                </div> 
                                                            </div>  
                                                        )
                                                }
                                            }
                                        })

                                    }
                                </div>

                            </div>
                        </div>
                        <div className='imagem'>
                            <img src={settings} alt="cel" width={170} height={170} />
                        </div>
                    </div>
                </div>
            <PopUp trigger={buttonPopUp} setTrigger={setButtonPopUp}>
                <InputFile setNameDataFile={setNameDataFile} setDataFile={setDataFile} setTrigger={setButtonPopUp} accept={'.csv'}/>
            </PopUp>
        </div>
    );
};

export default TelaComando
