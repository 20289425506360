import React, { useState } from 'react'
import { useOutletContext } from "react-router-dom";

import './graficoTodos.scss'

import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'

import UplotChartTodos from '../../components/chartTodos/UplotChart'

const Home = () => {
  const permissao = useOutletContext();

  const [widthChart, setWidthChart] = useState(530);
  const [heightChart, setHeightChart] = useState(290);
  const [heightChartW, setHeightChartW] = useState(200);

  return (
    <div className="home">
      <div className="homeCont">
        <Navbar acesso={permissao} invisivel={true} ChangeHW={setHeightChartW} valueHW={heightChartW} ChangeH={setHeightChart} valueH={heightChart} ChangeW={setWidthChart} valueW={widthChart}
           select={"todos"} selectList={"default"} selectOP={"default"} />
        <Sidebar/>
        <div className="content">
          <div className="charts-page">
            <UplotChartTodos valueH={heightChart}  valueW={widthChart}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
