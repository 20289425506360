import React, { useState, useEffect } from 'react'
import { useOutletContext } from "react-router-dom";
import Board from 'react-trello'

import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'

import axios from 'axios'

import {
  Sketch,
} from '@uiw/react-color'

import { useQuery, useMutation, useQueryClient } from 'react-query'
import { getGestaoDiariaData, setGestaoDiariaData } from '../../api/gestaoDiariaData'

import './gestaoDiaria.scss'
const NoNewLane = () => <></>
const components = {
  NewLaneSection: NoNewLane,
}
let eventBus


const GestaoDiaria = () => {
  const permissao = useOutletContext();

  const baseURL = '/gestaoDiaria'
  const gestaoDiariaApi = axios.create({
    baseURL: baseURL,
  })

  const fetchData = async () => {
    var boardName = "123"

    const response =  await gestaoDiariaApi.post('/find', {boardName:boardName})
    const { _id, ...newDataa } = response.data
    setData(newDataa)
    setprimeiroRender(false)
    setisSuccess(true)
  }
  
  const [isSuccess, setisSuccess] = useState(false);
  const [data, setData] = useState(null);

  const [newData, setnewData] = useState(null);
  const [isSaved, setisSaved] = useState(true);
  const [primeiroRender, setprimeiroRender] = useState(true);

  const [colorOpts, setColorOpts] = useState({
    colorWheel: false,
    currColor: "rgba(49,121,186, 100)",
    isPainting: false,
    isPicking: false,
    icon: "palette",
  });

  const [newLane, setnewLane] = useState(false);
  const queryClient = useQueryClient();
  const [newLaneData, setnewLaneData] = useState({
    id: "",
    title: "",
    label: "",
    cards: [],
  });
  
  const setBoardMutation = useMutation(setGestaoDiariaData, {
    onSuccess: () => {
      setisSaved(true);
      setColorOpts((prev) => {
        return {
          ...prev,
          isPainting: false,
          isPicking: false,
        };
      });
      queryClient.invalidateQueries("board");
    },
  });
  const setEventBus = (handle) => {
    eventBus = handle;
  };
  const addNewLane = () => {
    let newLane;
    if (!newData) {
      newLane = [...data.lanes, newLaneData];
    } else {
      newLane = [...newData.lanes, newLaneData];
    }
    eventBus.publish({ type: "UPDATE_LANES", lanes: newLane });
  };
  function handleCardClick(cardId, metadata, laneId) {
    if (colorOpts.isPainting) {
      eventBus.publish({
        type: "UPDATE_CARD",
        laneId: laneId,
        card: {
          id: cardId,
          style: { backgroundColor: colorOpts.currColor, fontSize: "25px" },
        },
      });
    }
    if (colorOpts.isPicking) {
      data?.lanes.forEach((e) => {
        if (e.id === laneId) {
          e.cards.forEach((card) => {
            if (card.id === cardId) {
              setColorOpts((prev) => {
                return {
                  ...prev,
                  currColor: card.style?.backgroundColor,
                  isPicking: false,
                };
              });
            }
          });
        }
      });
    }
  }
  function handleDataChange(e) {
    if (JSON.stringify(data) === JSON.stringify(e)) {
      setisSaved(true);
    } else if (JSON.stringify(newData) === JSON.stringify(e)) {
      console.log("No changes");
    } else {
      setnewData(e);
      setisSaved(false);
      console.log("Smth changed");
    }
  }
  function handleCardUpdate(e, y, x) {
    console.log("this e: " + e);
    console.log("this y: " + JSON.stringify(y));
  }

  useEffect(() => {

    if(primeiroRender){
      fetchData();
    }

  }, [data])


  return (
    <div className="ocorrencia">
      <div className="ocorrenciaCont">
        <Navbar acesso={permissao} select={"default"} selectList={"gestaoDiaria"} selectOP={"default"}/>
        <Sidebar />
        <div className="content">
          <div className="boardCont">
            <div className="menu">
              <div className="left">
                <div className="toolItem">
                  <i
                    className="material-icons-outlined"
                    onClick={() => setnewLane((prev) => !prev)}
                  >
                    add
                  </i>
                </div>
                <div
                  className="toolItem"
                  style={{
                    color:
                      colorOpts?.currColor === "#ffffff"
                        ? "#000 "
                        : colorOpts?.currColor,
                    backgroundColor:
                      colorOpts.isPainting || colorOpts.isPicking
                        ? "#d4d4d4"
                        : "",
                  }}
                >
                  <i
                    className="material-icons-two-tone"
                    onClick={() =>
                      setColorOpts((prev) => {
                        return {
                          ...prev,
                          colorWheel: !prev.colorWheel,
                          isPainting: false,
                          isPicking: false,
                        };
                      })
                    }
                  >
                    {colorOpts.isPainting
                      ? "format_color_fill"
                      : colorOpts.isPicking
                      ? "colorize"
                      : "palette"}
                  </i>
                </div>

                {colorOpts.colorWheel && (
                  <div
                    className="colorWheel"
                    onMouseLeave={() =>
                      setColorOpts((prev) => {
                        return {
                          ...prev,
                          colorWheel: !prev.colorWheel,
                        };
                      })
                    }
                  >
                    <div className="colorOpts">
                      <div className="toolItem">
                        <i
                          className="material-icons"
                          style={{ color: colorOpts?.currColor }}
                          onClick={() =>
                            setColorOpts((prev) => {
                              return {
                                ...prev,
                                isPainting: !prev.isPainting,
                                isPicking: false,
                                //icon: !prev.isPainting && 'format_color_fill',
                              };
                            })
                          }
                        >
                          format_color_fill
                        </i>
                      </div>
                      <div className="toolItem">
                        <i
                          className="material-icons"
                          onClick={() => {
                            setColorOpts((prev) => {
                              return {
                                ...prev,
                                isPicking: !prev.isPicking,
                                isPainting: false,
                                // icon: !prev.isPicking && 'colorize',
                              };
                            });
                          }}
                          style={{ color: colorOpts?.currColor }}
                        >
                          colorize
                        </i>
                      </div>
                    </div>
                    <Sketch
                      style={{
                        boxShadow: null,
                        border: null,
                        borderRadius: "2px",
                      }}
                      color={colorOpts?.currColor}
                      onChange={(color) => {
                        setColorOpts((prev) => {
                          return { ...prev, currColor: color.hex };
                        });
                      }}
                    />
                  </div>
                )}
                {newLane && (
                  <div
                    className="newLaneForm"
                    //onMouseLeave={() => setnewLane((prev) => !prev)}
                  >
                    <div className="inputs">
                      <div className="input">
                        <label htmlFor="titulo">Título: </label>
                        <input
                          name="titulo"
                          placeholder="Título"
                          onChange={(e) =>
                            setnewLaneData((prev) => {
                              return {
                                ...prev,
                                id: e.target.value.toLocaleLowerCase(),
                                title: e.target.value,
                              };
                            })
                          }
                        ></input>
                      </div>
                      <div className="input">
                        <label htmlFor="detalhe">Detalhe: </label>

                        <input
                          name="detalhe"
                          placeholder="Detalhe"
                          onChange={(e) =>
                            setnewLaneData((prev) => {
                              return {
                                ...prev,

                                label: e.target.value,
                              };
                            })
                          }
                        ></input>
                      </div>
                    </div>
                    <div className="buttons">
                      <div className="toolItem">
                        <i
                          className="material-icons-outlined"
                          onClick={addNewLane}
                        >
                          check
                        </i>
                      </div>
                      <div className="toolItem">
                        <i
                          className="material-icons-outlined"
                          onClick={() => setnewLane((prev) => !prev)}
                        >
                          close
                        </i>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="right">
                <div className="toolItem">
                  <i
                    className="material-icons-outlined"
                    style={{ color: isSaved ? "green" : "rgb(218,0,0)" }}
                    onClick={() =>
                      setBoardMutation.mutate({
                        boardName: "123",
                        value: newData,
                      })
                    }
                  >
                    save
                  </i>
                </div>
              </div>
            </div>

            {isSuccess && (
              <Board
                id="board"
                data={data}
                draggable
                editable
                canAddLanes
                eventBusHandle={setEventBus}
                onDataChange={handleDataChange}
                onCardClick={handleCardClick}
                onCardUpdate={handleCardUpdate}
                laneStyle={{
                  boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.137)",
                  fontFamily: "Roboto !important",
                }}
                components={components}
                //onMouseOver={getColor}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GestaoDiaria
