import { useOutletContext } from "react-router-dom";

import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import ListMenu from "../../components/kbs copy/ListMenu";

import "./regOcorrencia.scss";

const RegOcorrencia = () => {
  const permissao = useOutletContext();

  return (
    <div className="conhecimento">
      <div className="conhecimentoCont">
        <Navbar acesso={permissao} select={"default"} selectList={"regOcorrencia"} selectOP={"default"} />
        <Sidebar />
        <div className="">
          <ListMenu type={"regOcor"} />
        </div>
      </div>
    </div>
  );
};

export default RegOcorrencia
