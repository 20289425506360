import React from "react";
import "./kbitem.scss";
import NewItem from "./NewItem";

const ListItem = ({
  setState,
  state,
  type,
  patchNewItemMutation,
  deleteItemMutation,
}) => {
  const CheckType = () => {
    const TypeOpt = () => {
      switch (state?.selectedItem?.typeOpt) {
        case "moto":
          return <p>Tipo: Motorista</p>;
        case "estab":
          return <p>Tipo: Estabelecimento</p>;
        case "motoEstab":
          return (
            <p>Tipo: Descontar do Motorista e passar para o Estabelecimento</p>
          );
        case "estabMoto":
          return (
            <p>Tipo: Descontar do Estabelecimento e passar para o Motorista</p>
          );

        default:
          return <p>Tipo: Sem tipo selecionado</p>;
      }
    };

    switch (type) {
      case "VA":
        return (
          <>
            <div className="itemHeader">
              <h2>{state?.selectedItem?.refOs}</h2>
              <p id="itemStatus">{state?.selectedItem?.status}</p>
            </div>
            <div className="itemDetails">
              <h3>{state?.selectedItem?.motorista}</h3>
              <h3>{state?.selectedItem?.estabelecimento}</h3>
              <TypeOpt />
              <p>R$: {state?.selectedItem?.valor}</p>
            </div>
            <div className="kbContent">
              <textarea
                value={state?.selectedItem?.infoComp}
                readOnly
              ></textarea>
            </div>
            <div className="kbFooter">
              <p>{new Date(state?.selectedItem?.createdAt).toLocaleString()}</p>
            </div>
          </>
        );
      case "kbs":
        return (
          <>
            <div className="kbTitle">
              <h2>{state?.selectedItem?.title}</h2>
              <p>{state?.selectedItem?.descrip}</p>
            </div>
            <div className="kbContent">
              <textarea
                value={state?.selectedItem?.content}
                readOnly
              ></textarea>
            </div>
            <div className="kbFooter">
              <p>{new Date(state?.selectedItem?.createdAt).toLocaleString()}</p>
            </div>
          </>
        );
      case "regOcor":
        return (
          <>
            <div className="kbTitle">
              <h2>{state?.selectedItem?.os}</h2>
              <p>{state?.selectedItem?.motorista}</p>
              <p>{state?.selectedItem?.estabelecimento}</p>
            </div>
            <div className="kbContent">
              <textarea
                value={state?.selectedItem?.ocorrencia}
                readOnly
              ></textarea>
            </div>
            <div className="kbFooter">
              <p>{new Date(state?.selectedItem?.createdAt).toLocaleString()}</p>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="kbTitle">
              <h2>Ta faltando algo ai doido</h2>
            </div>
          </>
        );
    }
  };

  return (
    <div className="selectedItem">
      <div className="opts">
        <div
          className="toolItem"
          onClick={() =>
            setState((prev) => {
              return {
                ...prev,
                selectedItem: false,
                newItemData: {},
                isDeleting: false,
                isEditing: false,
              };
            })
          }
        >
          <i className="material-icons-outlined">arrow_back</i>
        </div>

        <div className="kbOpts">
          {state.isEditing ? (
            <>
              <div
                className="toolItem"
                onClick={() =>
                  setState((prev) => {
                    return { ...prev, isEditing: false };
                  })
                }
              >
                <i className="material-icons-outlined">close</i>
              </div>
              <div
                className="toolItem"
                onClick={() => {
                  patchNewItemMutation.mutate({
                    listURL: type,
                    value: state.newItemData,
                  });
                  setState((prev) => {
                    return {
                      ...prev,
                      selectedItem: false,
                      isEditing: false,
                      isDeleting: false,
                      newItemData: {},
                    };
                  });
                }}
              >
                <i className="material-icons-outlined">check</i>
              </div>
            </>
          ) : (
            <div
              className="toolItem"
              onClick={() => {
                setState((prev) => {
                  return {
                    ...prev,
                    newItemData: prev.selectedItem,
                    isEditing: true,
                  };
                });
              }}
            >
              <i className="material-icons-outlined">edit</i>
            </div>
          )}

          {state.isDeleting ? (
            <>
              <div
                className="toolItem"
                onClick={() =>
                  setState((prev) => {
                    return { ...prev, isDeleting: false };
                  })
                }
              >
                <i className="material-icons-outlined">close</i>
              </div>
              <div
                className="toolItem"
                onClick={() => {
                  deleteItemMutation.mutate({
                    listURL: type,
                    value: state.selectedItem._id,
                  });
                  setState((prev) => {
                    return { ...prev, selectedItem: false, isDeleting: false };
                  });
                }}
              >
                <i className="material-icons-outlined">check</i>
              </div>
            </>
          ) : (
            <div
              className="toolItem"
              onClick={() =>
                setState((prev) => {
                  return { ...prev, isDeleting: true };
                })
              }
            >
              <i className="material-icons-outlined">delete_forever</i>
            </div>
          )}
        </div>
      </div>
      <div className="data">
        <div>
          {!state.isEditing ? (
            <CheckType />
          ) : (
            <>
              <NewItem state={state} setState={setState} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListItem;
