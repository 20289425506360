import React, { useState, useEffect } from 'react'
import { useOutletContext } from "react-router-dom";
import { useQuery } from 'react-query'
import './graficoLondrina.scss'

import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import List from '../../components/list/List'

import UplotChartLondrina from '../../components/chart/UplotChart'

import { procurarEmAndamento, procurarAgendadas } from '../../api/entregasData'



const Home = () => {
  const permissao = useOutletContext();

  const [widthChart, setWidthChart] = useState(900);
  const [heightChart, setHeightChart] = useState(400);
  const [heightChartW, setHeightChartW] = useState(200);
  const [tabelaAgendadas, setTabelaAgendadas] = useState([]);
  const [tabelaEmAndamento, setTabelaEmAndamento] = useState([]);

  const colunasEntregas =[{chave: "restaurante", valor: "Restaurantes"}, {chave: "corrida", valor: "Corridas"}]
  const colunasEmAndamento =[{chave: "restaurante", valor: "Restaurantes"}, {chave: "p", valor: "P"}, {chave: "ct", valor: "CT"}]

  const agendadas= useQuery(['tabela', 'agendadas'],procurarAgendadas, {
    refetchInterval: 10000,
    refetchIntervalInBackground: true
  })

  const entregasEmAndamento = useQuery(['tabela', 'emAndamento'],procurarEmAndamento, {
    refetchInterval: 10000,
    refetchIntervalInBackground: true
  })

  
  useEffect(() => {
    //console.log(agendadas)
    if(agendadas.isSuccess){
      setTabelaAgendadas(agendadas.data)
    }

    if(entregasEmAndamento.isSuccess){
      setTabelaEmAndamento(entregasEmAndamento.data)
    }
  }, [agendadas, entregasEmAndamento, tabelaAgendadas, tabelaEmAndamento ])

  return (
    <div className="home">
      
      <div className="homeCont">
        <Navbar acesso={permissao} invisivel={true} ChangeHW={setHeightChartW} valueHW={heightChartW} ChangeH={setHeightChart} valueH={heightChart} ChangeW={setWidthChart} valueW={widthChart} 
          select={"gl"} selectList={"default"} selectOP={"default"}/>
        <Sidebar/>
        <div className="content">
          <div className="charts-page-londrina">
            <UplotChartLondrina valueHW={heightChartW} valueH={heightChart}  valueW={widthChart} />
          </div>
          { tabelaAgendadas.length !== 0 && tabelaEmAndamento.length !== 0 &&
            <div className="list">
              <List largura={widthChart} altura={1000} colunas={colunasEntregas} itens={tabelaAgendadas["gl"]} titulo={"Agendadas"} limite={20} />
              <List largura={widthChart} altura={1000} colunas={colunasEmAndamento} itens={tabelaEmAndamento["gl"]} titulo={"Em Andamento"} limite={10}/>
            </div>   
          }
        </div>
      </div>
    </div>
  )
}

export default Home
