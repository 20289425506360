import './list.scss'
import React, { useState} from 'react'
import Icon from '@material-ui/core/Icon'

import Cores from '../../cores/Cores'
import PopUp from '../../popUp/PopUp';

const Lista = (props) =>{ 
    const formatos = ["data", "destaque", "id", "icone"]

    const [popUpIcone, setPopUpIcone] = useState(false);
    const [popUpConteudo, setPopUpConteudo] = useState("");
    const [popUpConteudoHistorico, setPopUpConteudoHistorico] = useState("");

    const opcoesLocalizacaoHora = {
        timeZone: 'America/Sao_Paulo',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',      
        minute: '2-digit',
    };

    const lidarIcone = (valor) => {

        if(valor.length > 0 && valor[0]?.obs !== undefined){
            setPopUpConteudo("")
            setPopUpIcone(true)
            setPopUpConteudoHistorico(valor)

        }else{
            setPopUpConteudo(valor)
            setPopUpConteudoHistorico("")
            setPopUpIcone(true)
        }
    };

    return ( 
        <div className='lista-itens'> 
            {props.data.length !== 0 ? 
                props.chave.map((obj, indice)=>{

                    var valor = "props.data[obj.value]"
                    var color = 'rgb(78, 78, 78)'
                    var border = "none"

                    const chave = Object.keys(obj["tipo"])
                    if(formatos.includes(chave[0])){
                        switch (chave[0]) {
                            case "data":
                                const opcoesLocalizacao = {
                                    timeZone: 'America/Sao_Paulo',
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit'
                                };

                                valor = props.data[obj.value]
                                var date = new Date(valor.split("Z")[0]);
                                valor = date.toLocaleString('pt-BR', opcoesLocalizacao);
                                break;
                            case "destaque":
                                valor = props.data[obj.value]
                                
                                var tipo = obj["tipo"]["destaque"]
                                var cor = tipo[valor]
                                color = Cores[cor]
                                border = "1px solid "+ color

                                break;
                            case "id":
                                valor = props.data[obj.value].substring(props.data[obj.value].length - 4)
                                color = Cores.azul
                                border = "1px solid "+ color
                                break;
                            
                            case "icone":
                                const icone = Object.values(obj["tipo"])[0]
                                valor = <Icon onClick={()=>{lidarIcone(props.data[obj.value])}} className="iconTitulo" style={{ cursor:"pointer", padding:"2px", border: "1px solid #6B4380", borderRadius:"10px", color:"#6B4380"}}>{icone}</Icon>

                                break;
                
                            default:
                        }

                    }else{

                        valor = props.data[obj.value]
                    }

                    var valorData = obj.value
                    const matches = valorData.split('[')
                    if(matches.length - 1 === 1){

                        const extrai1 =  valorData.split('[')
                        const extrai2 =  extrai1[1].split(']')
                        const extrai3 =  extrai2[0].split('.')
                        
                        if(extrai3.length > 2){
                            return (
                                <div className='item' key={valorData}>
                                    <div className='titulo'> {obj.label} </div>
                                    <div className='valor'> {props.data[extrai3[0]][extrai3[2]] + ". " +props.data[extrai3[0]][extrai3[1]]} </div>
                                </div>
                            )
                        }else{
                            return (
                                <div className='item' key={valorData}>
                                    <div className='titulo'> {obj.label} </div>
                                    <div className='valor'> {props.data[extrai3[0]][extrai3[1]] } </div>
                                </div>
                            )
                        }
                    }else{
                        return (
                            <div className='item' key={indice}>
                                <div className='titulo' style={{padding:"3px"}}> {obj.label} </div>
                                <div className='valor'  style={{color:color, border: border, borderRadius:"10px", padding:"3px", display:"flex", justifyContent:"center"}}> {valor} </div>
                            </div>
                        )
                    }
                })
                :
                {}
            }

            <PopUp trigger={popUpIcone} setTrigger={setPopUpIcone}>
                <div className='popUp-icone'>
                { popUpConteudo !== "" && 
                    popUpConteudo.map((img_src, index) => (
                    
                    <img key={index} className='capitura' style={{transform: `scale(${0.6})`, transformOrigin: 'center'}} src={img_src} />))
                }
                { popUpConteudoHistorico !== "" &&
                    <div className='borda-historico'>
                        <div className='cabeca-historico'>
                            <div>
                              Observação
                            </div>
                            <div>
                              Data
                            </div>
                        </div>
                        {popUpConteudoHistorico.map((obj, indice) =>{
                          return(
                            <div className='corpo-historico' key={indice}> 
                                <div>
                                  {obj.obs}
                                </div>
                                <div>
                                  {
                                    new Date(obj.data).toLocaleString('pt-BR', opcoesLocalizacaoHora)
                                  }
                                </div>
                            </div>
                            )
                          })
                        }
                    </div>
                }
                </div>
            </PopUp>
        </div>
    );
}

export default Lista