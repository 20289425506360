import React, { useMemo} from 'react'
import './table.scss'
import Pagination from '../pagination/Pagination'
const Table = props => {
    const {
      onPageChange,
      totalCount,
      currentPage,
      pageSize,
      head,
      data
    } = props;

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return data.slice(firstPageIndex, lastPageIndex);
    }, [currentPage]);

    return (
        <div>
            <Pagination  onPageChange={onPageChange} totalCount={totalCount} siblingCount={1} currentPage={currentPage} pageSize={pageSize}/>
            <table className="taable">
            <thead className="head">
                <tr className="th">
                    {Object.keys(head[0]).map((key) => (
                        <th key={key}>{key}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
            {
                currentTableData.map((row, index) => 
                (
                    <tr key={index}>
                        {Object.values(row).map(function(value, index, arr){

                            return(
                            <td className="td" key={index}>{value}</td>)
                            
                        })}
                    </tr>
                ))
            }
            </tbody>
        </table>
        </div>
    )
}

export default Table

