import axios from 'axios'

const baseURL = '/gestaoDiaria'

const gestaoDiariaApi = axios.create({
  baseURL: baseURL,
})

export const getGestaoDiariaData = async ({queryKey }) => {
  const [_, boardName] = queryKey
  const res = await gestaoDiariaApi.post('/find', {boardName:boardName})
  const { _id, ...newData } = res.data
  return newData
}

export const setGestaoDiariaData = async ({boardName, value}) => {
  const res = await gestaoDiariaApi.post('/update', {value: value, boardName:boardName})
  return res.data
}
