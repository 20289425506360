import React from 'react'
import './popUpVazio.scss'

const PopUp = (props) => {
    return (props.trigger) ? (
        <div className={`popup ${props.light ? 'popup-light' : ''}`}>
            <div className='popup-inner'>
                <i className="material-icons" onClick={() => props.setTrigger(false)} style={{ position: "absolute", top: "15px", right: "20px"}}>
                    close</i>
                <div className="conteudo-popUp">
                    {props.children}                
                </div>
            </div>
        </div>
    ): "";

}

export default PopUp