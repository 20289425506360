import React, {useState, useEffect} from 'react'
import {useMutation, useQueryClient } from 'react-query'
import ProgressBar from "@ramonak/react-progress-bar";
import Icon from '@material-ui/core/Icon'
import { Oval } from  'react-loader-spinner'
import axios from 'axios'

import {postAlteraTarifa, postAlteraIconeVinculo, postAlteraDinamicaChuva} from '../../api/telaComandoData'
import PopUp from '../popUpAlerta/PopUpAlerta'

import './tabelaComando.scss'

const TabelaComando = (props) => {

    const queryClient = useQueryClient()
    const baseURL = '/telaComando'
    const telaComandoApi = axios.create({
      baseURL: baseURL,
    })

    const [loader, setLoader] = useState(false);
    const [barraProgresso, setBarraProgresso] = useState(false);
    const [popUp, setPopUp] = useState(false);
    const [conteudoPopUp, setConteudoPopUp] = useState(false);

    useEffect(() => {
    
    }, [props.data])

    const handleTaricas = async (linha, indice) =>{
        // Altera tarifas: pausa ou libera
        alert( "Seu comando está sendo processado. Pode demorar alguns minutos. Aguarde.")
        setLoader(true)

        var arrayValores = Object.values(linha)
        var arrayChaves = Object.keys(linha)

        var data = {
            "item":arrayChaves[0],
            "cidade":arrayChaves[indice].split(arrayChaves[0]+"_")[1],
            "statusAtual": arrayValores[indice]
        }

        if(data.item === "pausa_comum" ||data.item === "pausa_premium"){
            alteraTarifa.mutate(data)
        }else if (data.item === "fila_ma"){
            setLoader(false)

            setBarraProgresso(1)
            var lista =  await telaComandoApi.post('/procuraTaxistas', data).catch(error => {

                setConteudoPopUp(error.response.data.data)
                setPopUp(true)
                setBarraProgresso(false)
                return
            });

            const batchSize = 20;
            var resultAlteraFila
            for(var i=0; i < lista.data.data.length; i += batchSize){
                const batchOfIDs = lista.data.data.slice(i, i + batchSize);

                resultAlteraFila = await telaComandoApi.post('/atualizaTaxistas', {"file": batchOfIDs, "adicionais":{}}).catch(error => {
                    // Tratar o erro
                    setConteudoPopUp(error.response.data.data)
                    setPopUp(true)
                    return
                });

                var valorBarra
                if( i === 0){
                    valorBarra = parseInt((100*1)/lista.data.data.length)
                }else{
                    valorBarra = parseInt((100*i)/lista.data.data.length)
                }
                setBarraProgresso(valorBarra)
            }

            if(resultAlteraFila["status"] === 201){

                data["vinculo"] = (data.statusAtual === "desligada" ? '1' : '0')

                alteraIconeVinculo.mutate(data)
            }

            setBarraProgresso(false)
        }else if(data.item === "dinamica_chuva"){
            alteraDinamicaChuva.mutate(data)
        }
    }

    const alteraIconeVinculo =  useMutation(postAlteraIconeVinculo, {
        onSuccess: (e) => {
          queryClient.invalidateQueries()

          props.atualiza(true)
          setConteudoPopUp(e.data.data)
          setPopUp(true)
        },
        onError: (e) => {
          setConteudoPopUp(e.response.data.data)
          setPopUp(true)
        }
        
    });

    const alteraDinamicaChuva =  useMutation(postAlteraDinamicaChuva, {
        onSuccess: (e) => {
          queryClient.invalidateQueries()
          setConteudoPopUp(e.data)
          setPopUp(true)
          setLoader(false)
          props.atualiza(true)
        },
        onError: (e) => {
          setLoader(false)
          setConteudoPopUp(e.data)
          setPopUp(true)
        }
        
    });

    const alteraTarifa =  useMutation(postAlteraTarifa, {
        onSuccess: (e) => {
          queryClient.invalidateQueries()
          setConteudoPopUp(e.data)
          setPopUp(true)
          setLoader(false)
          props.atualiza(true)
        },
        onError: (e) => {
          setLoader(false)
          setConteudoPopUp(e.data)
          setPopUp(true)
        }
        
    });
    
    return (
        <div className='div-tabela-comando'>
            {loader === true &&
              <div className='loader-comando'>
                  <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
              </div>
            }
            { barraProgresso != false &&
                <div className='loader'>
                <ProgressBar width={'200px'} completed={barraProgresso} />                
                </div>
            }
            <table className="table-tela-comando">
                <thead className="cabecalho-comando">         
                    <tr className="tr">
                        {props.cabecalho.length > 0 &&
                            props.cabecalho.map((linha, indice_linha)=>(
                            Object.values(linha).map(function(valor, indice, array){
                                if(indice === 0){ 
                                    return 
                                }else{
                                    return(    
                                        <th  key={indice} className="th">
                                            {valor}
                                        </th>  
                                    )   
                                }
                            })
                        ))}
                    </tr>
                </thead>
                <tbody className='tbody-tela-comando'>
                    {props.data.length > 0 &&
                        props.data.map((linha, indice_linha)=>(
                            <tr  key={indice_linha} className="tr">
                                {
                                    Object.values(linha).map(function(valor, indice, array){
                                        if(valor === "ligada"){
                                            return(    
                                                <th  key={indice} className="th">
                                                    <Icon onClick={() => {handleTaricas(linha, indice)}} className="icon-update">{"play_circle_outline"}</Icon>
                                                </th>  
                                            )   
                                        }else if(valor === "desligada"){
                                            return(    
                                                <th  key={indice} className="th">
                                                    <Icon onClick={() => {handleTaricas(linha, indice)}} className="icon-update">{"pause_circle"}</Icon>
                                                </th>  
                                            )   
                                        }else{
                                            return(    
                                                <th  key={indice} className="th">
                                                    {valor}
                                                </th>  
                                            )   
                                        }
                                    })
                                }
                            </tr> 
                        ))
                    }
                </tbody>
            </table>

            <PopUp trigger={popUp} setTrigger={setPopUp}>
                {conteudoPopUp}
            </PopUp>
        </div>
    );
};

export default TabelaComando
