import React, { useState,useRef } from 'react'
import DatePicker from 'react-datepicker'
import { endOfWeek, setHours, getWeek, setWeek } from 'date-fns'
import './date.scss'

const DateModel = (props) => {

    const dateFromRef = useRef()
    const dateToRef = useRef()
    const [week, setWeeks] = useState({
    startDate: setWeek(setHours(new Date(), 7), getWeek(new Date())),
    endDate: endOfWeek(new Date(), getWeek(new Date())),
    currWeek: getWeek(new Date())
    })

    const handleFiltroMais = (event, tipo) =>{

        if(tipo == "start"){
            props.onChangeDate({startDate:event, endDate:week.endDate})
            setWeeks((prev) => {
                return {
                ...prev,
                startDate: event,
                }
            })
        }

        if(tipo == "end"){
            props.onChangeDate({startDate:week.startDate, endDate: event})
            setWeeks((prev) => {
                return {
                ...prev,
                endDate: event
                }
            })
        }
      }

    return (
        <div className="datePicker">
            <div className="newDates">
                <div className="clickable">
                <div className="label">
                    <p>De: </p>
                </div>
                <DatePicker
                    className="date-clicavel"
                    ref={dateFromRef}
                    selected={week.startDate}
                    onChange={(e) => {handleFiltroMais(e, "start")}}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                />
                </div>
                <div className="clickable">
                <div className="label">
                    <p>Até: </p>
                </div>
                <DatePicker
                     className="date-clicavel"
                    ref={dateToRef}
                    selected={week.endDate}
                    onChange={(e) =>{handleFiltroMais(e, "end")}
                    }
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                />
                </div>
            </div>
      </div>
    )
}

export default DateModel

