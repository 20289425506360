import React from 'react'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'

//import * as L from 'leaflet'
//import '/node_modules/leaflet/dist/leaflet.css'

import './map.scss'

const Map = ({ isHome }) => {
  return (
    <>
      {isHome ? (
        <div className="map">
          <div className="mapCont">
            <button>Show filas</button>

            <div id="mapId"></div>
          </div>
        </div>
      ) : (
        <div className="map">
          <Sidebar />
          <div className="mapCont">
            <Navbar />
            <button >Mostrar filas</button>
            <button >Reset Zoom</button>
            <div id="mapId"></div>
          </div>
        </div>
      )}
    </>
  )
}

export default Map
