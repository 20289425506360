import React, { useState, useEffect } from 'react'
import {useMutation, useQueryClient } from 'react-query'
import { useOutletContext } from "react-router-dom";
import { Oval } from  'react-loader-spinner'

import 'react-circular-progressbar/dist/styles.css';

import axios from 'axios'
import './financeira.scss'

import NavBar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Input from '../../components/input/Input'
import Select from '../../components/select/Select'
import Button from '../../components/button/Button'
import Table from './componentes/table/TableFinanMotocas'
import PopUpAlerta from '../../components/popUpAlerta/PopUpAlerta'
import PopUpConfirmacao from '../../components/popUpVazio/PopUpVazio';

import EditPopup from './componentes/PopUpEditar';
import NewEntryPopup from './componentes/PopUpNovoRegistro';
import { procuraFiltragemSimples, cancelaRepasses } from '../../api/financeiraMotoristasData'

const FinanceiraMotoristas = () => {

  const baseURL = '/financeiraMotoristas'
  const queryClient = useQueryClient()
  const financeiraApiMotoristas = axios.create({
    baseURL: baseURL,
  })

  const permissao = useOutletContext();

  let PageSize = 20;

  const opcoesSeletor = [
    { value: 'nome', label: 'Motorista' },
    { value: 'status_check', label: 'Status' },
    { value: 'empresa', label: 'Empresa' },
  ]

  const cabecaTabela = [
    { value: 'nome', label: 'Motorista' },
    { value: 'datavencimento', label: 'Data' },
    { value: 'empresa', label: 'Empresa' },
    { value: 'status_check', label: 'Status' },
    { value: 'operador', label: 'Operador' },
    { value: 'atualizadaEm', label: 'Atualizada em' },
    { value: 'metodo', label: 'Método' },
    { value: 'valorCobranca', label: 'Valor da Cobranca' },
  ]

  const [data, setData] = useState([]);
  const [listCheckBox, setListCheckBox] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [buttonPopUpAlerta, setButtonPopUpAlerta] = useState(false);
  const [conteudoPopUp, setConteudoPopUp] = useState("");
  const [popUpConfirmacao, setPopUpConfirmacao] = useState(false);

  const [loader, setLoader] = useState(false);

  const [selectFilter, setSelectFilter] = useState('');

  const [inputFilter, setInputFilter] = useState('');

  const [primeiroRender, setprimeiroRender] = useState(true);

  const [atualizaCobranca, setAtualizaCobranca] = useState(false);

  const [dataConsulta, setDataConsulta] = useState('');

  
  const fetchData = async () => {
    setLoader(true)
    const response =  await financeiraApiMotoristas.get('/procuraDadosRecibos');
    const ultimaAtualizacao =  await financeiraApiMotoristas.get('/procuraConsulta')

    try {
      const agora = new Date(ultimaAtualizacao.data[0]["consultas"]["financeira"]);
      const opcoesLocalizacao = {
        timeZone: 'America/Sao_Paulo',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false 
    };

      const dataHoraBrasileira = agora.toLocaleString('pt-BR', opcoesLocalizacao);
      setDataConsulta(dataHoraBrasileira)
    } catch (error) {
      
    }
    setprimeiroRender(false)
    setData(response.data)
    setLoader(false)

  }

  const refreshPage = async () =>{
    //atualiza dados da tabela

    window.location.reload(false);
  }

  const handleSubmit = (event) =>{
    const filter = [selectFilter, inputFilter]

    if(selectFilter=='' ||  inputFilter==''){
      alert("Informe um texto ou selecione um filtro.")
      refreshPage();
      return
    }
    
    procuraDadosFinanceira.mutate(filter);
  }


  const handleCancelaRegistro = async ()=>{
    setLoader(true);
    setPopUpConfirmacao(false);

    const response = await cancelaRepasses(listCheckBox);
    
    if(response?.status !== 200){
      setConteudoPopUp(response.response.data.error)
      setButtonPopUpAlerta(true);
    }

    showPopUpRetorno(response.data);
    
    setLoader(false);
  }

  const Retorno = ({ id, status, message, error }) => {

    console.log("id, status, message, error", id, status, '\n', message, '\n', error);
  
    return (
      <div key={id} style={{ marginBottom: '10px' }}>
        {/* Check if message exists before attempting to access its properties */}
        {message ?
          (message.json ? message.json.message : message) 
          :
          'No message available'
        }
        {/* Check if error exists before attempting to access its properties */}
        {error ? 
          <div style={{ color: 'red' }}>
            {error.data?.message ? 
              error.data.message 
              : 
              error.toString()  // Fallback to a string representation of the error if no message is present
            }
          </div>
          : 
          null
        }
        <br />
      </div>
    );
  }
  

  const showPopUpRetorno = async (registros) => {

    const MensagemPopUp = registros.map((registro) => (
      <Retorno
        key={registro.id}
        id={registro.id}
        status={registro.status}
        message={registro.message}
        error={registro.error}
      />
    ));

    setConteudoPopUp(MensagemPopUp);
    setButtonPopUpAlerta(true);
  };

  const procuraDadosFinanceira =  useMutation(procuraFiltragemSimples, {
    onSuccess: (e) => {
      setData(e)
      queryClient.invalidateQueries()
    },
    onError: (e) => {
      setData([])
    }
    
  });

  function setTrigger(value){
    setButtonPopUpAlerta(value);
    refreshPage();
  }

  useEffect(() => {

    if(atualizaCobranca == true){
      fetchData();
      setAtualizaCobranca(false)
    }

  }, [atualizaCobranca, setAtualizaCobranca])

  useEffect(() => {

    if(primeiroRender){
      fetchData();
    }

  }, [data, setData])
  return (
    <div className="financeira">     
      <div className="financeiraCont">
        <NavBar select={"default"} selectList={"default"} selectOP={"financeira"} acesso={permissao} />
        <Sidebar />
        <div className="container-financeira">
          <div className="container">
            <div className="components">
              {loader == true &&
              <div className='loader'>
                  <Oval height={50} width={50} color="#6B4380" wrapperStyle={{}} wrapperClass="" visible={loader} ariaLabel='oval-loading' secondaryColor="#6B4380" strokeWidth={3} strokeWidthSecondary={3} />
              </div>
              }

              <Input tipo={"texto"} width={"240px"} name={"inputFilter"} value={inputFilter} onChange={setInputFilter} placeholder="Digite aqui..." />
              <Select name={"selectFilter"} value={selectFilter} onChange={setSelectFilter} options={opcoesSeletor} titulo={"Filtros"}/>
              <Button onClick={() => {handleSubmit()}} width={"100px"} color={"#ffffff"} background={'#6B4380'} border={'1px solid #6B4380'} text={"Filtrar"}/>
              <NewEntryPopup refreshPage={refreshPage} setConteudoPopUpAlerta={setConteudoPopUp} setTriggerPopUpAlerta={setButtonPopUpAlerta} />
            </div>
          </div>

          <div className="divTable">
            {
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontSize: "13px", marginLeft: "15px", color: "#aaa6a6" }}>
                  <div>
                    {dataConsulta !== '' && "Atualizada em: " + dataConsulta}
                  </div>
                  {listCheckBox.length !== 0 && (
                    <div className='buttons'>
                      { 
                        //<EditPopup data={data} setData={setData} toEdit={listCheckBox} refreshPage={refreshPage} />
                      }
                      <Button 
                        onClick={()=>setPopUpConfirmacao(true)}
                        width={"70px"}
                        color={"#ffffff"}
                        background={"#c84333"}
                        border={"#c84333"} 
                        text={"Cancelar"} />
                    </div>
                  )}
                </div>

                {
                  <Table 
                    name={"table"} 
                    value= {"value"} 
                    data={data} 
                    head={cabecaTabela} 
                    onChangeCheckBox={setListCheckBox} 
                    onPageChange={page => setCurrentPage(page)} 
                    totalCount={data.length}
                    countSelected={(listCheckBox.filter((item)=>item)).length} 
                    currentPage={currentPage} 
                    pageSize={PageSize}
                  />
                }
              </div>
            }
          </div>

          <PopUpAlerta trigger={buttonPopUpAlerta} setTrigger={setTrigger}>
            {
              <div>
                {conteudoPopUp}
              </div>
            }
          </PopUpAlerta>

          <PopUpConfirmacao trigger={popUpConfirmacao} setTrigger={setPopUpConfirmacao} light={true}>
            {
              <div style={{ padding: "15px" }}>
                <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                  <h2>
                    {"Confirmação de Cancelamento"}
                  </h2>
                </div>
                <div style={{ color: "#5a595c", marginBottom: "10px" }}>
                  {
                    
                    listCheckBox.filter(id => id).map((item, index) => {
                      // Find the corresponding data item by matching the _id
                      const correspondingData = data.find(d => d._id === item);

                      if (correspondingData) {
                        return (
                          <div key={item} style={{ marginBottom: "10px" }}>
                            <p>{correspondingData._id}</p>
                            <p>{correspondingData.nome} R${correspondingData.valor}</p>
                            <p>Status: {correspondingData.status_check}</p>
                            <p>Metodo: {correspondingData.metodo}</p>
                          </div>
                        );
                      } else {
                        return (
                          <div key={item} style={{ marginBottom: "10px" }}>
                            <p>Recibo de ID: {item}</p>
                            <p>Status: Não encontrado</p>
                          </div>
                        );
                      }
                    })
                  }
                </div>


                <div>
                  < Button
                    text='Sim'
                    width='120px'
                    borderRadius='8px'
                    border='black'
                    background='rgb(66, 175, 60)'
                    color='white'
                    onClick={handleCancelaRegistro}
                  />
                  < Button
                    text='Não'
                    width='120px'
                    borderRadius='8px'
                    border='black'
                    background='#c84333'
                    color='white'
                    onClick={() => { setPopUpConfirmacao(false) }}
                  />
                </div>

              </div>
            }
          </PopUpConfirmacao>
          
        </div>
      </div>
    </div>
  )
}

export default FinanceiraMotoristas;