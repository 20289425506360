/* SUMÁRIO
  getSolicitacaoByID: Procura solicitação por número de OS da entrega.
  atualizaRetornoMachine: Atualiza o retorno de entrega com dados do sistema externo.
  registraRetorno: Registra um novo retorno de entrega.
  getRetornosDaEmpresa: Obtém todos os retornos de entrega de uma determinada empresa.
  getRetornos: Obtém todos os retornos de entrega de todas as empresas.
  atualizaStatusRetorno: Atualiza o status de um retorno de entrega.
  enviaMsgMotoristas: Envia mensagens para os motoristas sobre retornos atrasados.
*/

import axios from 'axios'

const baseURL = '/retornos'

const retornosApi = axios.create({
  baseURL: baseURL,
})

/**
 * Procura solicitação por número de OS da entrega.
 * @param {string} id - Número de OS da entrega.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const getSolicitacaoByID = async (id) => { 
  const res = await retornosApi.get('/getSolicitacaoByID', {params: {dados: id}})
  return res
}

/**
 * Registra um novo retorno de entrega.
 * @param {Object} dados - Dados do retorno a ser registrado.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const registraRetorno = async (dados) => {
  const res = await retornosApi.post('/registraRetorno', {params: {dados: dados}})
  return res
}

/**
 * Obtém todos os retornos de entrega de uma determinada empresa.
 * @param {string} dados - ID da empresa.
 * @returns {Promise} - Promise que contém os dados dos retornos de entrega.
 */
export const getRetornosDaEmpresa = async (dados) => {
  const res = await retornosApi.get('/getRetornosDaEmpresa', {params: {dados: dados}})
  return res.data
}

export const getRetornos = async () => {
  const res = await retornosApi.get('/getRetornos')
  return res.data
}

/**
 * Atualiza o status de um retorno de entrega.
 * @param {string} id - Id do retorno a ser atualizado.
 * @param {string} atualizadoPor - papel da pessoa que realizou a ultima atualização.
 * @param {string} status - default é Retornou.
* @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const atualizaStatusRetorno = async (id, atualizadoPor, status) => {
  try {
    const res = await retornosApi.post('/atualizaStatusRetorno', {params: {id: id, atualizadoPor: atualizadoPor, status: status}})
    return res.data
  } catch (error) {
    console.error("Erro ao atualizar status de retorno:", error)
    throw error
  }
}

export const enviaMsgMotoristas = async (dados) => {
  const res = await retornosApi.get('/enviaMsgMotoristas', {params: {dados: dados}})
  return res.data
}

/**
 * Procura solicitação por número de OS da entrega.
 * @param {string} id - Número de OS da entrega.
 * @returns {Promise} - Promise que contém a resposta da solicitação.
 */
export const atualizaRetornoMachine = async (id, os) => { 
  const res = await retornosApi.get('/atualizaRetornoMachine', {params: {id: id, os: os}})
  return res
}
